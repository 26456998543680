export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: []
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Painel Principal',
					root: true,
					icon: 'flaticon-dashboard',
					page: '/dashboard-main',
					translate: 'Painel Principal',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? that.user.getUser().permissoes['Dashboard'] == '1' : true;
					}
				},
				{
					title: 'Gerenciar',
					root: true,
					icon: 'flaticon-folder-1',
					// page: '/departments',
					translate: 'Gerenciar',
					bullet: 'dot',
					submenu:[
						{
							title: 'Atendimentos',
							page: '/attendances',
							validate: (that) => {
								return that.user.getUser().enable_operators == '1';
							}
						},
						{
							title: 'Tabulações',
							page: '/tabulations',
							validate: (that) => {
								return that.user.getUser().enable_operators == '1';
							}
						},
						{
							title: 'Equipes',
							page: '/departments',
							validate: (that) => {
								return that.user.getUser().enable_operators == '1';
							}
						},
						{
							title: 'Operadores',
							page: '/operators',
							validate: (that) => {
								return that.user.getUser().enable_operators == '1';
							}
						},
						{
							title: 'Gestores',
							page: '/users',
						},
					],
					validate: (that) => {
						return that.user.getUser().enable_operators=='1' || that.user.getUser().admin=='1';
					}
				},
				{
					title: 'Canal',
					root: true,
					icon: 'flaticon-profile-1',
					page: '/channel',
					translate: 'Canal',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? that.user.getUser().permissoes['Canal'] == '1' : true;
					}
				},
				{
					title: 'Contatos',
					root: true,
					icon: 'flaticon2-user',
					page: '/contacts',
					translate: 'Contatos',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? that.user.getUser().permissoes['Canal'] == '1' : true;
					}
				},
				{
					title: 'ChatBot',
					root: true,
					icon: 'flaticon-chat',
					page: '/chatbot',
					translate: 'ChatBot',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? that.user.getUser().permissoes['ChatBot'] == '1' &&  that.user.getUser().enable_operators == 1 : that.user.getUser().enable_chatbot == 1 && that.user.getUser().enable_operators == 0;
					}
				},
				{
					title: 'ChatBot',
					root: true,
					icon: 'flaticon-chat',
					page: '/chatbot-att',
					translate: 'ChatBot',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().enable_operators == 1 && that.user.getUser().operationalChannelEnabled == 0;
					}
				},
				{
					title: 'APIs',
					root: true,
					icon: 'flaticon-more-v4',
					page: '/apis',
					translate: 'APIs',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? that.user.getUser().permissoes['APIs'] == '1' : that.user.getUser().enable_api == 1;
					}
				},
				{
					title: 'Painel Principal',
					root: true,
					icon: 'flaticon-browser',
					page: '/main',
					translate: 'Painel Principal',
					bullet: 'dot',
					validate: () => false
				},
				{
					title: 'Notificações',
					root: true,
					icon: 'flaticon-alert-1',
					page: '/notifications',
					translate: 'Notificações',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? that.user.getUser().permissoes['Notificações'] == '1' : that.user.getUser().enable_batch_notifications == '1';
					}
				},
				{
					title: 'Pesquisa de Satisfação',
					root: true,
					icon: 'flaticon2-website',
					page: '/surveys',
					translate: 'Pesquisa de Satisfação',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? that.user.getUser().permissoes['Pesquisa de Satisfação'] == '1' : that.user.getUser().enable_surveys == 1;
						// return that.user.getUser().modulos.find(usuarioModulo => usuarioModulo.modulo.nome === 'Tray');
					}
				},
				{
					title: 'Relatórios',
					root: true,
					icon: 'flaticon2-chart',
					page: '/survey-reports',
					translate: 'Relatórios',
					bullet: 'dot',
					submenu:[
						{
							title: 'API',
							page: '/api_relatorios'
						},
						{
							title: 'Notificações',
							page: '/survey-reports'
						},
						{
							title: 'Estatísticas',
							page: '/relatorios'
							// validate: (that) => {
							// 	return that.user.getUser().enable_operators == '1';
							// }
						},
						{
							title: 'Tabulações',
							page: '/tab_relatorios',
							validate: (that) => {
								return that.user.getUser().enable_operators == '1';
							}
						},
					],
					validate: (that) => {
						return that.user.getUser().permissoes ? (that.user.getUser().permissoes['Relatórios'] == '1' || that.user.getUser().permissoes['Notificações'] == '1') : that.user.getUser().enable_batch_notifications == '1';
					}
					// validate: (that) => {
					// 	return that.user.getUser().permissoes ? (that.user.getUser().permissoes['Relatórios'] == '1') : (that.user.getUser().modulos.length > 0 && that.user.getUser().modulos.find(usuarioModulo => usuarioModulo.modulo && usuarioModulo.modulo.nome === 'Pesquisa de Satisfação'));
					// }
				},
				{
					title: 'Grupo de Contatos',
					root: true,
					icon: 'flaticon-users',
					page: '/groups',
					translate: 'Grupo de Contatos',
					bullet: 'dot',
					validate: (that) => {
						return that.user.getUser().permissoes ? true : that.user.getUser().enable_groups == '1';
					}
				}
				// {
				// 	title: 'Sair',
				// 	root: true,
				// 	icon: 'flaticon2-expand',
				// 	page: '/logout'
				// }
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
