import {Component, Input, OnInit} from '@angular/core';
import * as constants from '../../../constants'

@Component({
	selector: 'kt-contact-widget',
	templateUrl: './contact-widget.component.html',
	styleUrls: ['./contact-widget.component.scss']
})
export class ContactWidgetComponent implements OnInit {
	@Input() notification: any;
	@Input() actionButtonTitle: any;
	@Input() actionButtonCallback: any;

	constants = constants

	constructor() {
	}

	ngOnInit() {
	}

}
