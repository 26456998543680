// Angular
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../../core/_base/layout';
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service';

import { MissionService } from '../../../../../services/mission.service';

@Component({
	selector: 'kt-subheader3',
	templateUrl: './subheader3.component.html',
	styleUrls: ['./subheader3.component.scss']
})
export class Subheader3Component implements OnInit, OnDestroy, AfterViewInit {
	// Public properties
	@Input() fluid: boolean;
	@Input() clear: boolean;

	today: number;
	title: string = '';
	desc: string = '';
	breadcrumbs: Breadcrumb[] = [];

	@Input() astronaut = '';
	mission = '<no mission announced>';
	confirmed = false;
	announced = false;
	subscription: Subscription;

	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	 constructor(public subheaderService: SubheaderService,
		private missionService: MissionService,
		public ref: ChangeDetectorRef,
		) {
		this.subscription = missionService.missionAnnounced$.subscribe(
			mission => {
				this.mission = mission;
				this.announced = true;
				this.confirmed = false;
		});
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
	}

	url = ''

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		setInterval(()=>{
			if(this.missionService.reload){
				this.reloadClock()
			}
		},500)
		setInterval(()=>{
			this.today = Date.now();
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		},1000)
	}

	reloadClock(){
		this.today = Date.now()
	}

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
		this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
			// breadcrumbs title sometimes can be undefined
			if (bt) {
				Promise.resolve(null).then(() => {
					this.title = bt.title;
					this.desc = bt.desc;
				});
			}
		}));

		this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
			Promise.resolve(null).then(() => {
				this.breadcrumbs = bc;
			});
		}));
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	isTarifando(){
		if(this.url=='tarifando'){
			return true
		}
	}

	isStandard(){
		if(this.url=='painel' || window.location.href.includes('localhost')){
			return true
		}
	}
}
