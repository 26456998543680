import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {ApiJsService} from '../../../services/apiJS.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';

@Component({
  selector: 'kt-notifReports',
  templateUrl: './notifReports.component.html',
  styleUrls: ['./notifReports.component.scss']
})
export class NotifReportsComponent implements OnInit {

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
    public apiJS: ApiJsService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    private socketService: SocketService,
    public subHeader: SubheaderService,
    public ref: ChangeDetectorRef,
  ) { 
    this.subHeader.setTitle('Relatórios - Notificações');
  }

  @ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;

  pagination: boolean = true

  notifications:  any = []

  notifOptions: any = [
    {id:1,text:'Em Andamento'},
    {id:2,text:'Finalizadas'},
    {id:3,text:'Data'}
  ]

  dateOptions: any = [
    {id:1,text:'Hoje'},
    {id:2,text:'Esta Semana'},
    {id:3,text:'Este Mês'},
    {id:4,text:'Período'}
  ]

  statusOptions: any = [
    {id:1,text:'Em Andamento'},
    {id:2,text:'Finalizado'}
  ]

  filter: any = {dateOption:3}

  displayedColumns: string[] = ['nome', 'carregado_em', 'loaded', 'sent', 'answer','efectivity','tma'];

  notifData: any = [
    // {nome: 'Teste', carregado_em: '22/03/2022', loaded: 1000, sent: 800, answer: 300, efectivity:'85%', tma: '10'},
    // {nome: 'Teste 2', carregado_em: '22/03/2022', loaded: 2000, sent: 800, answer: 300, efectivity:'85%', tma: '10'},
  ];

  totalNotifications: any = 0
  
  notificationsChart: any = []
  contactsChart: any = []

  loadingTable: any = true;
  loadingCharts: any = true;

  currentPage: any = 1;

  ngOnInit() {
    this.getDepartments()
    this.getNotifData(1,this.filter)
    this.getChartData(this.filter)
    this.socketService.onTMA().subscribe((data:any) => {
      let sheet = this.notifData.find(el=>el.sheetId==data.sheetId)
      if(sheet){
        if(data.averageTime){
          let time = new Date(data.averageTime)
          sheet.tma = time.getUTCHours() + ' hs ' + time.getUTCMinutes() + ' mn'
        }else{
          sheet.tma = '0 hs 0 mn'
        }
      }
      
      if (!this.ref['destroyed']){
        // console.log('sheet',sheet)
        this.ref.detectChanges();
      }
		});
  }

  openModalFilter(){
    this.modalService.open(this.modalFilter, {size: 'sm'});
  }

  getDepartments(){
    this.socketService.getDepartments(this.user.getUser().instance_id);
  }

  getTMA(sheetId){
    this.socketService.getTMA(sheetId)
  }

  async getNotifData(page,filters={}){
    this.loadingTable = true
    this.notifData = []

    if(!Object.keys(filters).length){
      filters = this.filter
    }
    
    // let res = await this.api.get_notifications_data(page,filters)
    let res = await this.apiJS.get_notifications_data(page,filters)
    console.log('res',res)
    // if(res['sheets']){
    //   this.notifData = res['sheets']
    // }
    if(res['data']){
      this.notifData = res['data']
    }
    if(res['total']){
      this.totalNotifications = res['total']
    }else{
      this.totalNotifications = 0
    }
    // console.log('get',res)
    this.notifData.forEach(el=>{
      this.getTMA(el.sheetId)
    })
    this.loadingTable = false
    if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
    if(!this.pagination) this.pagination = !this.pagination
    // console.log(this.notifData)
  }

  async getChartData(filters={}){
    this.loadingCharts = true

    // let res = await this.api.get_notifications_chart(filters)
    let res = await this.apiJS.get_notifications_chart(filters)
    // console.log(res)
    this.notificationsChart = res.charts.notifications
    this.contactsChart = res.charts.contacts
    // console.log('notificationsChart',this.notificationsChart)
    this.loadingCharts = false
    if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
  }

  changeNotifPage(page){
    if(page==this.currentPage){
      return
    }
    this.currentPage = page
    if(!this.loadingCharts){
      this.getNotifData(page)
    }
  }

  doFilter(page=this.currentPage){
    this.pagination = false // control pagination
    this.getNotifData(page,this.filter)
    this.getChartData(this.filter)
  }

  filterTxt(filter){
    var ret;
    if(filter.dateOption){
      if(filter.dateOption==4){
        ret = this.filter.startDate ?  `${this.filter.startDate.split("-").reverse().join("/")} até ` : `---- até `
        ret += this.filter.endDate ? this.filter.endDate.split("-").reverse().join("/") : '----'
      }else{
        ret = this.dateOptions.find(el=>el.id==filter.dateOption).text
      }
    }
    else if(filter.status){
      ret = this.statusOptions.find(el=>el.id==filter.status).text
    }
    return ret
  }

  // doFilter(){
  //   this.getNotifData(this.currentPage,this.filter)
  //   this.getChartData(this.filter)
  // }

  dismissModal(){
		this.modalService.dismissAll();
	}

  ngOnDestroy() {
    this.socketService.off()
  }
}
