import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {ToolsService} from '../../services/tools.service';

@Component({
	selector: 'kt-whatsapp',
	templateUrl: './whatsapp.component.html',
	styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {
	@Input() message: string = '';
	@Input() messages = [];
	@Input() image: string = '';
	@Input() hasImage = 0;

	constructor(public user: UserService,
				public tools: ToolsService) {
	}

	ngOnInit() {
	}

}
