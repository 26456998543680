import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {ToolsService} from '../../../services/tools.service';
import { SocketService } from '../../../services/socket.service';
import axios from 'axios'

@Component({
	selector: 'kt-editar-notificacao',
	templateUrl: './editar-notificacao.component.html',
	styleUrls: ['./editar-notificacao.component.scss'],
	host: {
		'(document:mousedown)': 'onClick($event)'
	}
})

export class EditarNotificacaoComponent implements OnInit {
	private showEmojiPicker = false;
	test: any = {number: null};
	testingNumber: any = false;
	identifiers: any = [];
	tags: any = [];
	departments: any = []

	@ViewChild('modalTags', {static: false}) modalTags: ElementRef;
	@ViewChild('modalIdentifiers', {static: false}) modalIdentifiers: ElementRef;
	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;

	constructor(public user: UserService,
				public ref: ChangeDetectorRef,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public router: Router,
				public subHeader: SubheaderService,
				private modalService: NgbModal,
				public toolsService: ToolsService,
				private _location: Location,
				private socketService: SocketService,
				) {
	}

	ngOnInit() {
		// console.log(this.user.getUser())
		this.getNotificationsIds();
		this.getNotificationsTags();
		if(this.user.getUser().enable_operators){
			this.getDepartments()
		}
		this.socketService.onSetDepartments().subscribe((data:any) => {
			this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
			if(!this.departments.find(el => el.name == 'Nenhuma')) {
				this.departments.unshift({ _id: '0', name: 'Nenhuma' })
			}
		});
		this.socketService.onSendMessage().subscribe(data => {
			this.notify.show(data['message'], data['success'] ? 'success' : 'danger');
			this.testingNumber = false;
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
		this.socketService.onSendFile().subscribe(data => {
			this.notify.show(data['message'], data['success'] ? 'success' : 'danger');
			this.testingNumber = false;
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
	}

	async getNotificationsIds(){
		this.identifiers = await this.api.get_notification_identifiers(this.user.getUser().instance_id)
		// console.log(this.identifiers)
	}
	
	async getNotificationsTags(){
		this.tags = await this.api.get_notification_tags()
		// console.log(this.tags)
	}

	/**
	 * Volta a página de notificações.
	 *
	 * @author Daniel Silva Marcelino
	 */
	public back(): void {
		this.router.navigateByUrl('/notifications');
	}

	onTest() {
		this.testingNumber = true;

		if(this.user.notificacaoTemplate.hasImage && this.user.notificacaoTemplate.image) {
			this.user.notificacaoTemplate.body = this.user.notificacaoTemplate.image
			this.user.notificacaoTemplate.message = this.user.notificacaoTemplate.conteudo
			this.socketService.sendFile({ phone: this.test.number, ...this.user.notificacaoTemplate, filename: 'null', tn: true })
		} else {
			this.user.notificacaoTemplate.body = this.user.notificacaoTemplate.conteudo
			this.socketService.sendMessage({ phone: this.test.number, ...this.user.notificacaoTemplate, tn: true })
		}

		// this.api.test_notification_template(this.test.number, this.user.notificacaoTemplate).then(value => {
		// 	if (!value.success) {
		// 		this.notify.show(value.message, 'danger');
		// 	} else {
		// 		this.notify.show(value.message, 'success');
		// 	}
		// }).catch(reason => {
		// 	console.log(reason);
		// 	this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		// }).finally(() => {
		// 	this.testingNumber = false;
		// 	if (!this.ref['destroyed']){
		// 		this.ref.detectChanges();
		// 	}
		// });
	}

	async onSave() {
		this.ktDialogService.show();
		if(this.user.notificacaoTemplate.buttons) 
			this.user.notificacaoTemplate.buttons = JSON.stringify(this.user.notificacaoTemplate.buttons)

		if(this.user.notificacaoTemplate.hasButtons)
			this.user.notificacaoTemplate.hasButtons = '1'
		else
			this.user.notificacaoTemplate.hasButtons = '0'

		if(this.user.notificacaoTemplate.hasImage)
			this.user.notificacaoTemplate.hasImage = '1'
		else
			this.user.notificacaoTemplate.hasImage = '0'
			
		await this.api.notification_template_save(JSON.parse(JSON.stringify(this.user.notificacaoTemplate))).then(value => {
			if (value.success) {
				this.notify.show(value.message || "Notificação criada com sucesso!", 'success');
				this.back();
			}
		});
		this.ktDialogService.hide();
	}

	onCancel() {
		this._location.back();
	}

	toggleEmojiPicker(event = null) {
		if (!this.showEmojiPicker) {
			const that = this;
			setTimeout(() => {
				that.showEmojiPicker = !that.showEmojiPicker;
				that.ref.markForCheck();
			}, 50);

		}

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		return false;
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	onEmoji($event: any) {
		this.user.notificacaoTemplate.conteudo += $event.emoji.native;
		this.toggleEmojiPicker();
	}

	onTags() {
		this.modalService.open(this.modalTags);
	}

	onClick($event: any) {
		const that = this;
		if (that.showEmojiPicker) {
			setTimeout(() => {
				if (that.showEmojiPicker && !that.emojiMart.nativeElement.contains($event.target)) {
					that.showEmojiPicker = false;
				}
			}, 50);
		}
	}

	onIdentifier() {
		this.modalService.open(this.modalIdentifiers);
	}

	hasTray(){
		return this.user.getUser().modulos.find(usuarioModulo => usuarioModulo.modulo.nome === 'Tray')
	}

	keypress(){
		return this.toolsService.keypress();
	}
	
	goToTags(){
		this.modalService.dismissAll();
		this.router.navigateByUrl('/edit-tags');
	}

	getDepartments(){
		this.socketService.getDepartments(this.user.getUser().instance_id);
	}

	onFileSelected() {
		return new Promise(resolve => {
			try {
				const inputNode: any = document.querySelector('#imgupload');
				if (typeof (FileReader) !== 'undefined') {
					const reader = new FileReader();

					const fileName = inputNode.files[0].name;

					reader.onload = async (e: any) => {
						await this.saveFile(inputNode.files[0]['type'].split('/')[0], e.target.result, fileName,inputNode.files[0]);
						resolve(e.target.result);
					};

					reader.onerror = ev => {
						resolve(null);
					};

					reader.readAsDataURL(inputNode.files[0]);
				} else {
					resolve(null);
				}
			} catch (e) {
				resolve(null);
			}
		});
	}

	private async saveFile(type, base64, filename, data) {
		let env = {baseUrl:'https://gobot.digital', port:'5969'}
		var formData = new FormData();
		let fileType = data['type'].split('/')[0]
		formData.append(fileType, data);
		let token = this.user.getToken()
		axios.post(env.baseUrl+':'+env.port+'/upload', formData, {headers: {'Content-Type': 'multipart/form-data', 'authorization':token} }).then(resp=>{
			// console.log('uploadResp',resp)
			let url = `${env.baseUrl}:5969/${resp.data.files[fileType].filepath.split('upload-files/')[1]}`
			// let ext = this.reverseString(this.reverseString(filename).split('.')[0])
			// filename = filename.replace(`.${ext}`,'')
			// let media = {url,type:fileType,filename,ext}
			this.user.notificacaoTemplate.image = url
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		}) 	
	}

	reverseString(str) {
		return str.split("").reverse().join("");
	}

	addButton() {
		if(!this.user.notificacaoTemplate.buttons)
			this.user.notificacaoTemplate.buttons = []
		else if(this.user.notificacaoTemplate.buttons.length >= 3)
			return this.notify.show("O númeor máximo de botões foi atingido (3)", 'danger');
		
		this.user.notificacaoTemplate.buttons.push({ action: "", text: "" })
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	deleteButton(button) {
		let index = this.user.notificacaoTemplate.buttons.findIndex(el => el == button)
		if(index > -1) {
			this.user.notificacaoTemplate.buttons.splice(index,1)
		}
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}
}
