import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';

@Component({
  selector: 'kt-tabulationReports',
  templateUrl: './tabulationReports.component.html',
  styleUrls: ['./tabulationReports.component.scss']
})
export class TabulationReportsComponent implements OnInit {
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    private socketService: SocketService,
    public subHeader: SubheaderService,
    public ref: ChangeDetectorRef,
  ) { 
    this.subHeader.setTitle('Relatórios - Tabulações');
  }

  @ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;
  @ViewChild('modalManageMessages', {static: false}) modalManageMessages: ElementRef;

  chartOptions: any = {
    series: [
      {
        name: "Quantidade",
        data: []
      }
    ],
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    colors: [],
    plotOptions: {
      bar: {
        columnWidth: "20%",
        distributed: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    grid: {
      show: true
    },
    xaxis: {
      categories: [
      ],
      labels: {
        style: {
          colors: [],
          fontSize: "12px"
        }
      }
    }
  }

  dataSource: any = [];
  totalContacts: number = 0
  loadingInfo: any = false;
  currentPage: number = 1;

  filter: any = {date:3,reason:0}

  notifications:  any = []

  displayedColumns: string[] = ['contact', 'phone', 'sendDate', 'finishDate', 'tabulation', 'messagesBtn'];

  chosenFilter: any = []

  operators: any = []
  departments: any = []
  tabulations: any = []
  attMsgs: any = {}
  clientSelected: any = {}

  async ngOnInit() {
    this.socketService.getDepartments(this.user.getUser().instance_id);
    this.socketService.onSetDepartments().subscribe((data:any) => {
			this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		});

    this.socketService.getOperators(this.user.getUser().instance_id);
    this.socketService.onSetOperators().subscribe((data:any) => {
      this.operators = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    });

    this.socketService.getTabulations(this.user.getUser().instance_id);
    this.socketService.onSetTabulations().subscribe(data => {
			this.tabulations = data
		});
    
    this.socketService.onTabQnty().subscribe(async (data:any) => {
      // console.log('tabqty',data)
      this.chartOptions.series[0].data = []
      this.chartOptions.xaxis.categories = []
      this.chartOptions.colors = []
      this.chartOptions.xaxis.labels.style.colors = []
      data[0] = await this.sortChart(data[0])
      for(let obj of data[0]){
        this.chartOptions.series[0].data.push(obj.amount)
        this.chartOptions.xaxis.categories.push(obj.type)
        let color = this.generateColor()
        this.chartOptions.colors.push(color)
        this.chartOptions.xaxis.labels.style.colors.push('#fff')
      }
      this.dataSource = []
      for(let obj of data[1]){
        this.dataSource = [...this.dataSource, ...obj]
      }
      this.dataSource = this.dataSource.sort((a,b) => (a.finishDate < b.finishDate) ? 1 : ((b.finishDate < a.finishDate) ? -1 : 0))
      // this.dataSource = this.dataSource.sort((a,b) => (b.shippingDate - a.shippingDate))
      this.totalContacts = data[2]
      // console.log('contacts',this.dataSource)
      this.loadingInfo = false
      if (!this.ref['destroyed']){
        this.ref.detectChanges();
      }
		});

    this.socketService.onContactData().subscribe((data:any) => {
      console.log('contactData',data)
		});

    this.socketService.onManageMessages().subscribe((data:any) => {
      console.log('messages',data)
      this.attMsgs.msgs = []
      this.attMsgs.attendanceId = data['attendanceId']
      data['messages'].forEach(el=>{
        el.tipo = el.type
        el.conteudo = el.body
        el.remetente = !el.fromMe
        el.legenda = el.caption
        el.midia_url = el.mediaURL
        el.dados_extras = {}
        el.timestamp = el.timestamp*1000
        this.attMsgs.msgs.push(el)
      })
      // console.log('attMsgs',this.attMsgs.msgs)
		});
    await this.loadData()
  }

  loadData(page=1){
    if(page==1) this.currentPage = 1
    this.loadingInfo = true
    this.socketService.getTabQnty(this.user.getUser().instance_id,this.filter,this.filter.startDate,this.filter.endDate,page)
  }

  chooseType(){
    if(this.filter.type){
      switch(this.filter.type){
        case 1:
        case '1':
          this.chosenFilter = this.departments
          break
        case 2:
        case '2':
          this.chosenFilter = this.operators
      }
      if (!this.ref['destroyed']){
        this.ref.detectChanges();
      }
    }
  }

  manageMessages(attendanceId){
    this.socketService.manageMessages(attendanceId)
    this.modalService.open(this.modalManageMessages, {size: 'sm'});
  }

  changeContactsPage(page){
    if(page==this.currentPage){
      return
    }
    this.currentPage = page
    if(!this.loadingInfo){
      this.loadData(page)
    }
  }

  sortChart(data){
    return data.sort((a,b) => (b.amount - a.amount))
  }

  generateColor(){
    return `#${Math.floor(Math.random()*16777215).toString(16)}`
  }

  dismissModal(){
		this.modalService.dismissAll();
	}

  ngOnDestroy() {
    this.socketService.off()
  }
}
