import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ContactListStore, ContactListState } from './contact-list.store';

@Injectable({ providedIn: 'root' })
export class ContactListQuery extends QueryEntity<ContactListState> {

  constructor(protected store: ContactListStore) {
    super(store);
  }

}
