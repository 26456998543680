import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';

@Component({
  selector: 'kt-attConfig',
  templateUrl: './attConfig.component.html',
  styleUrls: ['./attConfig.component.scss']
})
export class AttConfigComponent implements OnInit {

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    private socketService: SocketService,
  ) { }

  @ViewChild('modalNewDepartment', {static: false}) modalNewDepartment: ElementRef;
  @ViewChild('modalEditDepartment', {static: false}) modalEditDepartment: ElementRef;
  @ViewChild('modalDeleteDepartment', {static: false}) modalDeleteDepartment: ElementRef;
  @ViewChild('modalDeleteDepartmentArray', {static: false}) modalDeleteDepartmentArray: ElementRef;

  depArray: any = []
  selectedDep: any = {}
  newDep: any = {}

  ngOnInit() {
  }

  selectDep(item){
    this.selectedDep = item;
  }

  openModalNew(){
    this.modalService.open(this.modalNewDepartment, {size: 'sm'});
  }
  openModalEdit(){
    this.modalService.open(this.modalEditDepartment, {size: 'sm'});
  }
  openModalDelete(){
    this.modalService.open(this.modalDeleteDepartment, {size: 'sm'});
  }
  saveDepartment(item){
    this.socketService.saveDepartment(item);
  }


  dismissModal(){
		this.modalService.dismissAll();
    this.selectedDep = {}
	}


}
