import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
	currentPage = null;
	pages = [];
	visiblePages = [];
	countStart = 1;
	countEnd = 10;
	hasPrevious: any = true;
	hasNext: any = true;

	@Input() totalItems: any;
	@Input() itensPerPage: any;
	@Input() term: any = 'Notificações';

	@Output() onUpdate: EventEmitter<any> = new EventEmitter();

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges) {
		// console.log(changes);
		// console.log(this.totalItems);
		// this.doSomething(changes.categoryId.currentValue);
		// You can also use categoryId.previousValue and
		// categoryId.firstChange for comparing old and new values
		// this.setup();

	}

	ngOnInit(): void {
		this.setup();
	}

	goPrevious() {
		const index = this.pages.indexOf(this.currentPage);
		if (index > 1) {
			this.currentPage = this.pages[index - 1];
			this.render();
		}
	}

	goNext() {
		const index = this.pages.indexOf(this.currentPage);
		if (index < (this.pages.length - 1)) {
			this.currentPage = this.pages[index + 1];
			this.render();
		}
	}

	goFirst() {
		const index = this.pages.indexOf(this.currentPage);
		if (index > 1) {
			this.currentPage = this.pages[0];
			this.render();
		}
	}

	goLast() {
		const index = this.pages.indexOf(this.currentPage);
		if (index < (this.pages.length - 1)) {
			this.currentPage = this.pages[this.pages.length - 1];
			this.render();
		}
	}

	render() {
		this.visiblePages = [];

		const index = this.pages.indexOf(this.currentPage);
		for (let i = index - 3; i < index + 3; i++) {
			if (i >= 0 && i <= (this.pages.length - 1)) {
				this.visiblePages.push(this.pages[i]);
			}
		}

		this.countStart = ((this.currentPage.number - 1) * this.itensPerPage) + 1;
		this.countEnd = this.currentPage.number * this.itensPerPage;

		this.hasNext = (index < (this.pages.length - 1));
		this.hasPrevious = (index > 1);

		if (this.onUpdate) {
			this.onUpdate.emit(this.currentPage.number);
		}
	}

	private setup() {
		this.pages = [];

		var pagesCount = Math.ceil(this.totalItems / this.itensPerPage);
		if(!pagesCount) pagesCount = 1
		for (let i = 1; i <= pagesCount; i++) {
			this.pages.push({number: i});
		}

		this.currentPage = this.pages[0];
		this.render();
	}

	activePage(page: any) {
		this.currentPage = page;
		this.render();
	}
}
