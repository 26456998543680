import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';

@Component({
  selector: 'kt-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    private socketService: SocketService,
    public subHeader: SubheaderService,
    public ref: ChangeDetectorRef,
  ) { 
    this.subHeader.setTitle('Gerenciar - Equipes');
  }

  @ViewChild('modalNewDepartment', {static: false}) modalNewDepartment: ElementRef;
  @ViewChild('modalEditDepartment', {static: false}) modalEditDepartment: ElementRef;
  @ViewChild('modalFilterDepartment', {static: false}) modalFilterDepartment: ElementRef;
  @ViewChild('modalDeleteDepartment', {static: false}) modalDeleteDepartment: ElementRef;
  @ViewChild('modalDeleteDepartmentArray', {static: false}) modalDeleteDepartmentArray: ElementRef;

  departments:  any = []
  depArray: any = []
  dep: any = {}
  newDep: any = {}
  selectBox: any = ''
  search: any = {name:''}

  ngOnInit() {
    this.getDepartments()
    this.socketService.onSetDepartments().subscribe((data:any) => {
			this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		});
    this.socketService.onNewDepartment().subscribe((data:any) => {
      if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
  }

  selectDep(item){
    this.dep = item;
  }

  openModalNew(item = null){
    if(item){
      this.dep = item
    }
    this.modalService.open(this.modalNewDepartment, {size: 'sm'});
  }
  openModalFilter(){
    this.modalService.open(this.modalFilterDepartment, {size: 'sm'});
  }
  openModalEdit(item = null){
    if(item){
      this.dep = item
    }
    this.modalService.open(this.modalEditDepartment, {size: 'sm'});
  }
  openModalDelete(item = null){
    if(item){
      this.dep = item
    }
    this.modalService.open(this.modalDeleteDepartment, {size: 'sm'});
  }
  openModalDeleteArray(){
    if (this.departments.some(val => val.checked == true)){
      this.modalService.open(this.modalDeleteDepartmentArray, {size: 'sm'});
    }else{
      this.notify.show(`Nenhuma Equipe selecionada!`, 'danger');
    }
  }
  getDepartments(){
    this.socketService.getDepartments(this.user.getUser().instance_id);
  }
  saveDepartment(){
    let newDep = false
    if(!this.dep.name){
      return this.notify.show(`Campo Nome Vazio!`, 'danger');
    }
    if(!this.dep.channelId){
      newDep = true
      this.dep.channelId = this.user.getUser().instance_id
    }
    this.socketService.saveDepartment(this.dep);
    this.dismissModal()
    if(newDep){
      this.notify.show(`Equipe criada com sucesso!`, 'success');
    }else{
      this.notify.show(`Equipe editada com sucesso!`, 'success');
    }
  }

  deleteDepartment(dep = null){
    if(dep){
      this.socketService.deleteDepartment(dep._id);
      return this.departments = this.departments.filter(el=>el!=dep)
    }
    this.socketService.deleteDepartment(this.dep._id);
    this.departments = this.departments.filter(el=>el!=this.dep)
    this.dismissModal()
    this.notify.show(`Equipe removida com sucesso!`, 'success');
  }

  deleteSelected(){
    let deps = this.departments.filter(el => el.checked == true)
    deps.forEach(el=> {
      this.deleteDepartment(el)
    });
    this.notify.show(`${deps.length} equipes removidas com sucesso!`, 'success');
  }

  dismissModal(){
		this.modalService.dismissAll();
    this.dep = {}
	}

  checkAll() {
		if(this.departments.length){
			if (this.departments.every(val => val.checked == true))
				this.departments.forEach(val => { val.checked = false });
			else
				this.departments.forEach(val => { val.checked = true });
		}
	}

  boxSelected(){
    switch(this.selectBox){
      case 'select':
        this.checkAll()
        break
      case 'delete':
        this.deleteSelected()
        break
    }
    this.selectBox = ''
  }

  searchDep(array){
    if(this.search.name){
      return array.filter(el=>el.name.toLowerCase().includes(this.search.name.toLowerCase()))
    }
    return array
  }

  ngOnDestroy() {
    this.socketService.off()
  }

}
