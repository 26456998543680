import { Injectable } from '@angular/core';
import { ContactList } from './contact-list.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ContactListState extends EntityState<ContactList> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'contact-list' })
export class ContactListStore extends EntityStore<ContactListState> {

  constructor() {
    super();
  }

}

