import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	public permission: Permission = null;

	constructor() {
		this.permission = this.isSupported() ? 'default' : 'denied';
	}

	public isSupported(): boolean {
		return 'Notification' in window;
	}

	requestPermission(): void {
		const self = this;
		if ('Notification' in window) {
			Notification.requestPermission(status => self.permission = status);
		}
	}

	create(title: string, options ?: PushNotification): any {
		const self = this;
		return new Observable(obs => {
			if (!('Notification' in window)) {
				console.log('Notifications are not available in this environment');
				obs.complete();
			}
			if (self.permission !== 'granted') {
				console.log('The user hasn\'t granted you permission to send push notifications');
				this.requestPermission();
				obs.complete();
			}
			const _notify = new Notification(title, options);
			_notify.onshow = e => obs.next({
				notification: _notify,
				event: e
			});
			_notify.onclick = e => obs.next({
				notification: _notify,
				event: e
			});
			_notify.onerror = e => obs.error({
				notification: _notify,
				event: e
			});
			_notify.onclose = () => obs.complete();
		});
	}

	generateNotification(source: Array<any>): void {
		const self = this;
		source.forEach((item) => {
			const options = {
				body: item.alertContent,
				icon: item.icon || '../assets/media/icons/svg/General/Notifications1.svg'
			};
			self.create(item.title, options).subscribe();
		});
	}
}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
	body?: string;
	icon?: string;
	tag?: string;
	data?: any;
	renotify?: boolean;
	silent?: boolean;
	sound?: string;
	noscreen?: boolean;
	sticky?: boolean;
	dir?: 'auto' | 'ltr' | 'rtl';
	lang?: string;
	vibrate?: number[];
}
