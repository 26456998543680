import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageUploadModule} from 'ng2-imageupload';
import {ImageCropperModule} from 'ngx-image-cropper';
import {TextMaskModule} from 'angular2-text-mask';
import {AgmCoreModule} from '@agm/core';
import {TruncatePipe} from '../pipes/truncate.pipe';
import {WhatsappComponent} from '../components/whatsapp/whatsapp.component';
import {SplitPipe} from '../pipes/split.pipe';
import {ReplacePipe} from '../pipes/replace.pipe';
import {PhonePipe} from '../pipes/phone.pipe';
import {ContactWidgetComponent} from '../gobot/components/contact-widget/contact-widget.component';
import {ChatMessagesContainerComponent} from '../gobot/components/chat-messages-container/chat-messages-container.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ChatMessageComponent} from '../gobot/components/chat-message/chat-message.component';
import {AutosizeModule} from 'ngx-autosize';
import {TimedescriptionPipe} from '../pipes/timedescription.pipe';
import {ProtocolGroupByPipe} from '../pipes/protocol-group-by.pipe';

@NgModule({
	declarations: [TruncatePipe, WhatsappComponent, SplitPipe, ReplacePipe, PhonePipe, ContactWidgetComponent, ChatMessagesContainerComponent, ChatMessageComponent, TimedescriptionPipe, ProtocolGroupByPipe],
	imports: [CommonModule, ImageUploadModule, ImageCropperModule, TextMaskModule,
		AgmCoreModule.forRoot({
			// apiKey: 'AIzaSyDtBKi2skMvW3fFYxar6hsZJKUGzDXbAz0',
			apiKey: 'AIzaSyBF5KlYrAY8ep3Ev5sSH5rBnaKwZqr6eiM',
			libraries: ['places']
		}), NgbDropdownModule, PerfectScrollbarModule, LazyLoadImageModule, AutosizeModule],
	exports: [ImageUploadModule, ImageCropperModule, TextMaskModule, AgmCoreModule, TruncatePipe, WhatsappComponent, SplitPipe, ReplacePipe, PhonePipe, ContactWidgetComponent, ChatMessagesContainerComponent, LazyLoadImageModule, ChatMessageComponent, AutosizeModule, TimedescriptionPipe, ProtocolGroupByPipe ]
})
export class SharedModule {
}
