const { io } = require("socket.io-client");
import {HttpClientModule,HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorService} from './error.service';
import {UserService} from './user.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {Logout} from '../core/auth';
import { Store } from '@ngrx/store';
import { AppState } from '../core/reducers';
import {NotifyService} from './notify.service';

import * as constants from '../constants';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    socket;
    socketSql;
    url = ''

    constructor(public http: HttpClient,
        public httpClient: HttpClientModule, 
        public error: ErrorService,
        public user: UserService,
        private router: Router,
        private store: Store<AppState>,
        public notify: NotifyService,
        ) {
        this.router.events.subscribe((event: Event) => {
            // if (event instanceof NavigationStart) {
            //     console.log('navigationStart',this.router.url)
            // }

            if (event instanceof NavigationEnd) {
                // console.log('navigationEnd',this.router.url)
                if(!this.router.url.includes('/auth/login')) {
                    if(!this.socket) {
                        this.socket = this.connect(this.socket)
                    }
                    this.socket.on('disconnect',()=>{
                        console.log('disconnect')
                        if(user) {
                            // this.notify.show(`Ocorreu um problema em sua internet e seu navegador fechou a conexão!\nConectando novamente em `, 'warning',this.interval);
                            setTimeout(()=>{
                                this.socket = this.connect(this.socket)
                                if(this.socket.active) {
                                    // this.notify.show(`Conectado`, 'success');
                                }else{
                                    // this.notify.show(`Falha ao conectar`, 'danger');
                                }
                            },this.interval*1000)
                        }
                    })
                    this.socket.on('connect',()=>{
                        console.log('connect')
                    })
                    this.socket.on("connect_error", (err) => {
                        console.log(`connect_error due to ${err.message}`);
                        if(user) {
                            console.log('user',user)
                            // this.notify.show(`Ocorreu um problema em sua internet e seu navegador fechou a conexão!\nConectando novamente em `, 'warning',this.interval);
                            setTimeout(()=>{
                                this.reconnectAttempts++
                                this.socket = this.connect(this.socket)
                            },this.interval*1000)
                        }
                        if(this.reconnectAttempts>4) {
                            this.logout()
                        }
                    });
                }
            }

            if (event instanceof NavigationError) {

                // Present error to user
                console.log(event.error);
            }
        });
        let url = window.location.href
        if(!url.includes('notifiqueai') && !url.includes('gobot')) {
            url = url.replace('https://','').split('.')[1]
        }else{
            url = url.replace('https://','').split('.')[0]
        }
        this.url = url
    }

    reconnectAttempts = 0
    interval = 10

    connect(socket) {
        let token = this.user.getToken()
        if(!token) {
            return this.logout()
        }
        let addr = 'https://gobot.digital:5969'
        // let addr = 'https://dev.notifiqueai.com.br:5969'
        if(this.url=='dev' || this.url=='http://dev') {
            addr = 'https://dev.notifiqueai.com.br:5969'
        }

        if(this.router.url.includes('/auth/login')) return
        socket = io.connect(addr, {query: {token} } );
        console.log('try connection')
        return socket
    }

    connectSql(socket) {
        let token = this.user.getToken()
        if(!token) {
            return this.logout()
        }

        let addr = `${constants.BASE}:5980`
        if(this.url=='dev' || this.url=='http://dev') {
            addr = 'https://dev.notifiqueai.com.br:5980'
        }

        socket = io.connect(addr, {query: {token} } );
        console.log('try connection 2')
        return socket
    }

    connectionErrorSckt2() {
        this.socketSql.on("connect_error", (err) => {
            console.log(`Sckt2 connect_error due to ${err.message}`);
            if(this.user) {
                if(this.reconnectAttempts==0)
                    // this.notify.show(`Ocorreu um problema em sua internet e seu navegador fechou a conexão!\nConectando novamente em `, 'warning',this.interval);
                setTimeout(()=>{
                    this.reconnectAttempts++
                    this.socketSql = this.connectSql(this.socketSql)
                },this.interval*1000)
            }
            if(this.reconnectAttempts>4) {
                this.logout()
            }
        });
    }

    logout() {
        this.store.dispatch(new Logout());
    }

    off() {
        this.socket.off()
        if(this.socketSql && this.socketSql.connected)
            this.socketSql.off()
    }

    getEndpoints() {
        this.socket.emit('getEndpoints')
    }

    onSetEndpoints() {
        return new Observable(observer => {
            this.socket.on('setEndpoints', (data) => {
                observer.next(data);
            });
        });
    }

    getOperators(id) {
        this.socket.emit('get_operators',id)
    }

    onSetOperators() {
        return new Observable(observer => {
            this.socket.on('get_operators', (data) => {
                observer.next(data);
            });
        });
    }

    getOnlineOperators(channelId) {
        this.socket.emit('online_operators',{channelId})
    }

    onSetOnlineOperators() {
        return new Observable(observer => {
            this.socket.on('online_operators', (data) => {
                observer.next(data);
            });
        });
    }

    onNewOperator() {
        return new Observable(observer => {
            this.socket.on('new_operator', (data) => {
                observer.next(data);
            });
        });
    }

    saveOperator(operator) {
        this.socket.emit('new_operator',operator)
    }

    deleteOperator(id) {
        this.socket.emit('delete_operator',id)
    }

    getDepartments(channelId) {
        this.socket.emit('get_departments',{channelId})
    }

    onSetDepartments() {
        return new Observable(observer => {
            this.socket.on('get_departments', (data) => {
                observer.next(data);
            });
        });
    }

    onNewDepartment() {
        return new Observable(observer => {
            this.socket.on('new_department', (data) => {
                observer.next(data);
            });
        });
    }

    saveDepartment(department) {
        this.socket.emit('new_department',department)
    }

    deleteDepartment(id) {
        this.socket.emit('delete_department',id)
    }

    getTabulations(channelId) {
        this.socket.emit('get_finish_reasons',channelId)
    }

    onSetTabulations() {
        return new Observable(observer => {
            this.socket.on('get_finish_reasons', (data) => {
                if(data.reason) observer.next(data.reason);
                else observer.next(data);
            });
        });
    }

    onNewTabulation() {
        return new Observable(observer => {
            this.socket.on('update_finish_reasons', (data) => {
                observer.next(data);
            });
        });
    }

    updateTabulation(reason,channelId) {
        this.socket.emit('update_finish_reasons',{channelId,reason})
    }

    deleteTabulation(reason,channelId) {
        this.socket.emit('delete_finish_reason',{channelId,reason})
    }

    getMenus(channelId) {
        this.socket.emit('get_menus',channelId)
    }

    onSetMenus() {
        return new Observable(observer => {
            this.socket.on('get_menus', (data) => {
                observer.next(data);
            });
        });
    }

    onNewMenu() {
        return new Observable(observer => {
            this.socket.on('create_menu', (data) => {
                observer.next(data);
            });
        });
    }

    saveMenu(menu) {
        this.socket.emit('create_menu',menu)
    }

    updateMenu(menu) {
        this.socket.emit('update_menu',menu)
    }

    deleteMenu(id) {
        this.socket.emit('delete_menu',id)
    }

    getChannelConfig(channelId) {
        this.socket.emit('get_channel_config',channelId)
    }

    updateChannelConfig(config) {
        this.socket.emit('update_channel_config',config)
    }

    onSetChannelConfig() {
        return new Observable(observer => {
            this.socket.on('get_channel_config', (data) => {
                observer.next(data);
            });
        });
    }

    disableOperational(channelId) {
        this.socket.emit('disable_operational',channelId)
    }

    getOpened(channelId) {
        this.socket.emit('get_opened',channelId)
    }

    onSetOpened() {
        return new Observable(observer => {
            this.socket.on('get_opened', (data) => {
                observer.next(data);
            });
        });
    }

    getWaiting(channelId) {
        this.socket.emit('get_waiting',channelId)
    }

    onSetWaiting() {
        return new Observable(observer => {
            this.socket.on('get_waiting', (data) => {
                observer.next(data);
            });
        });
    }

    getInAtt(channelId) {
        this.socket.emit('get_in_att',channelId)
    }

    onSetInAtt() {
        return new Observable(observer => {
            this.socket.on('get_in_att', (data) => {
                observer.next(data);
            });
        });
    }

    finishAtt(attendance,reason) {
        this.socket.emit('finish_attendance',{attendance,reason})
    }

    getReasons(channelId) {
        this.socket.emit('get_finish_reasons',channelId)
    }

    onSetReasons() {
        return new Observable(observer => {
            this.socket.on('get_finish_reasons', (data) => {
                observer.next(data.reason);
            });
        });
    }

    manageMessages(attendanceId) {
        this.socket.emit('manage_messages',attendanceId)
    }

    onManageMessages() {
        return new Observable(observer => {
            this.socket.on('manage_messages', (data) => {
                observer.next(data);
            });
        });
    }

    forwardToOperator(attendance,operator) {
        this.socket.emit('forward_to_operator',{attendance,operator, isChannel:true})
    }

    forwardToDepartment(attendance,departmentId) {
        this.socket.emit('forward_to_department',{attendance,department: {_id:departmentId}, isChannel:true})
    }

    onForwardToDepartment() {
        return new Observable(observer => {
            this.socket.on('forward_to_department', (data) => {
                observer.next(data.status);
            });
        });
    }

    onNewAttendance() {
        return new Observable(observer => {
            this.socket.on('new_attendance_create', (data) => {
                observer.next(data);
            });
        });
    }

    onUpdatedAttendance() {
        return new Observable(observer => {
            this.socket.on('updated_attendance_status', (data) => {
                observer.next(data);
            });
        });
    }

    onSentMessage() {
        return new Observable(observer => {
            this.socket.on('sent_message', (data) => {
                observer.next(data);
            });
        });
    }

    onNewMessage() {
        return new Observable(observer => {
            this.socket.on('received_message', (data) => {
                observer.next(data);
            });
        });
    }

    getSecConfig(channelId) {
        this.socket.emit('secondary_channels_config',channelId)
    }

    onSecConfig() {
        return new Observable(observer => {
            this.socket.on('secondary_channels_config', (data) => {
                observer.next(data);
            });
        });
    }

    sendTemplate(phone,notificationId) {
        if(!this.socketSql) {
            this.socketSql = this.connectSql(this.socketSql)
        }
        // {token:headers['authorization-token'], notificationId:body.notificationId, phone:body.number,fromAttendance:body.fromAttendance}
        this.socketSql.emit('send_template',{token:this.user.getToken(),phone,notificationId})
    }

    onSendTemplate() {
        return new Observable(observer => {
            if(!this.socketSql) {
                this.socketSql = this.connectSql(this.socketSql)
            }
            this.socketSql.on('send_template', (data) => {
                observer.next(data);
            })
        })
    }

    sendMessage(data) {
        if(!this.socketSql) {
            this.socketSql = this.connectSql(this.socketSql)
        }
        this.socketSql.emit('send_message',{ token:this.user.getToken(), ...data })
    }

    onSendMessage() {
        return new Observable(observer => {
            if(!this.socketSql) {
                this.socketSql = this.connectSql(this.socketSql)
            }
            this.socketSql.on('send_message', (data) => {
                observer.next(data);
            })
        })
    }

    sendFile(data) {
        if(!this.socketSql) {
            this.socketSql = this.connectSql(this.socketSql)
        }
        this.socketSql.emit('send_file',{ token:this.user.getToken(), ...data })
    }

    onSendFile() {
        return new Observable(observer => {
            if(!this.socketSql) {
                this.socketSql = this.connectSql(this.socketSql)
            }
            this.socketSql.on('send_file', (data) => {
                observer.next(data);
            })
        })
    }

    getTMA(sheetId) {
        this.socket.emit('average_attendance_time',sheetId)
    }

    onTMA() {
        return new Observable(observer => {
            this.socket.on('average_attendance_time', (data) => {
                observer.next(data);
            })
        })
    }

    getTabQnty(channelId,filter,firstPeriod=null,finalPeriod=null,page=1) {
        this.socket.emit('tabulations_and_quantities',{channelId,filter,firstPeriod,finalPeriod,page})
    }

    onTabQnty() {
        return new Observable(observer => {
            this.socket.on('tabulations_and_quantities', (data) => {
                observer.next(data);
            })
        })
    }

    getContactData(channelId,filter,firstPeriod=null,finalPeriod=null) {
        this.socket.emit('filter_contact_data_tab',{channelId,filter,firstPeriod,finalPeriod})
    }

    onContactData() {
        return new Observable(observer => {
            this.socket.on('filter_contact_data_tab', (data) => {
                observer.next(data);
            })
        })
    }

    updateDataChannel(channel) {
        this.socket.emit('update_data_channel',channel)
    }

    updateNameChannel(obj) {
        this.socket.emit('update_name_channel',obj)
    }

    updateStatusChannel(obj) {
        this.socket.emit('update_status_channel',obj)
    }
}