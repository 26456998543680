import {Injectable} from '@angular/core';
import {ChangeDetectorRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable, fromEvent, Subject} from 'rxjs';
import {take, tap, pluck} from 'rxjs/operators';
import {SocketOldService} from './socket-old.service';
import {ProtocolListQuery} from '../state/protocol-list.query';
import * as MicRecorder from 'mic-recorder-to-mp3';
import moment from 'moment';
import {ApiService} from './api.service';
import {GlobalService} from './global.service';

const recorder = new MicRecorder({
	bitRate: 128
});

declare var MediaRecorder: any;

export enum RecordingState {
	STOPPED = 'stopped',
	RECORDING = 'recording',
	FORBIDDEN = 'forbidden',
}

@Injectable({
	providedIn: 'root'
})
export class AudioRecorderService {
	seconds: number;
	state: RecordingState = RecordingState.STOPPED;
	audioURLs = [];
	private mediaRecorder;
	public recordings$: Observable<any>;

	private interval;
	private startTime;

	private _recordingTime = new Subject<string>();
	public recordedTime: Observable<string>;

	getRecordedTime(): Observable<string> {
		return this._recordingTime.asObservable();
	}

	constructor(private sanitizer: DomSanitizer,
				// private changeDetector: ChangeDetectorRef,
				public socket: SocketOldService,
				public protocolListQuery: ProtocolListQuery,
				public api: ApiService,
				public global: GlobalService
	) {
		this.recordedTime = this.getRecordedTime();
		// try {
		//     navigator.mediaDevices.getUserMedia({audio: true})
		//         .then(stream => {
		//             this.mediaRecorder = new MediaRecorder(stream);
		//             this.recordings$ = fromEvent(this.mediaRecorder, 'dataavailable');
		//         })
		//         .catch(error => {
		//             console.log('CANNOT RECORD: ', error);
		//             this.state = RecordingState.FORBIDDEN;
		//         });
		// } catch (e) {
		//     console.log(e);
		// }
	}


	onHold(time) {
		this.state = RecordingState.RECORDING;
		this.seconds = Math.round(time / 1000);
	}

	onStart() {
		// const that = this;
		//
		// this.state = RecordingState.RECORDING;
		// this.mediaRecorder.start();
		// this.recordings$.pipe(
		//     take(1),
		//     pluck('data'),
		//     tap((data: BlobPart) => {
		//         const blob = new Blob([data], {type: 'audio/mpeg'});
		//         // const blob = new Blob([data], {type: 'audio/x-mpeg-3'});
		//         // const blob = new Blob([data], {type: 'audio/ogg; codecs=opus'});
		//         this.audioURLs.push(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)));
		//
		//         const reader = new FileReader();
		//         reader.onload = (readerEvent) => { // called once readAsDataURL is completed
		//             // console.log('(readerEvent as any).target.result', (readerEvent as any).target.result);
		//             that.socket.sendAudio((readerEvent as any).target.result, this.protocolListQuery.getActiveId());
		//         };
		//
		//         reader.readAsDataURL(blob);
		//         // this.changeDetector.detectChanges();
		//     })
		// ).subscribe();

		this.state = RecordingState.RECORDING;
		this._recordingTime.next('00:00');

		const that = this;

		recorder.start().then(() => {
			this.startTime = moment();
			this.interval = setInterval(
				() => {
					const currentTime = moment();
					const diffTime = moment.duration(currentTime.diff(this.startTime));
					const time = that.toString(diffTime.minutes()) + ':' + that.toString(diffTime.seconds());
					this._recordingTime.next(time);
				},
				1000
			);
			// something else
		}).catch((e) => {
			console.error(e);
		});
	}

	onStop() {
		// this.state = RecordingState.STOPPED;
		// this.mediaRecorder.stop();
		// return this.audioURLs.pop();

		this.state = RecordingState.STOPPED;
		const that = this;

		recorder
			.stop()
			.getMp3().then(([buffer, blob]) => {
			// do what ever you want with buffer and blob
			// Example: Create a mp3 file and play
			// const file = new File(buffer, 'me-at-thevoice.mp3', {
			//     type: blob.type,
			//     lastModified: Date.now()
			// });
			//
			// const player = new Audio(URL.createObjectURL(file));
			// player.play();

			this.audioURLs.push(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)));

			const reader = new FileReader();
			reader.onload = async (readerEvent) => { // called once readAsDataURL is completed
				// console.log('(readerEvent as any).target.result', (readerEvent as any).target.result);
				that.socket.sendAudio((readerEvent as any).target.result, this.protocolListQuery.getActiveId());
				await that.api.send_file(that.global.activeGroup.api_id, (readerEvent as any).target.result, 'Mensagem de Voz.mp3', 'Mensagem de Voz');
			};

			reader.readAsDataURL(blob);

		}).catch((e) => {
			alert('We could not retrieve your message');
			console.log(e);
		});
	}

	private toString(value) {
		let val = value;
		if (!value) {
			val = '00';
		}
		if (value < 10) {
			val = '0' + value;
		}
		return val;
	}
}
