import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {ApiJsService} from '../../../services/apiJS.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from '../../../services/socket.service';

@Component({
  selector: 'kt-apiReports',
  templateUrl: './apiReports.component.html'
})
export class ApiReportsComponent implements OnInit {
  constructor(
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
    public ktDialogService: KtDialogService,
		public api: ApiService,
    public apiJS: ApiJsService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    public subHeader: SubheaderService,
    public ref: ChangeDetectorRef,
  ) { 
    this.subHeader.setTitle('Relatórios - API');
  }

  data: any = null
  filter: any = { endpoint: 1, dateOption: 1 }
  loadingReport: boolean = false
  pagination: boolean = true
  currentPage: number = 1

  displayedColumns: string[] = [ 'requisition', 'createdAt', 'success', 'failed', 'total' ];
  
  ngOnInit(): void {
    
  }

  async generateReport(page = 1) {
    this.loadingReport = true     
    let res = await this.apiJS.get_api_report(page,this.filter)
    // console.log('res',res)
    if(res.success) {
      this.data = res.data
    }
    else
    this.notify.show(`Ocorreu um erro!`, 'danger');
    this.loadingReport = false
    if (!this.ref['destroyed'])
      this.ref.detectChanges()
    if(!this.pagination) this.pagination = !this.pagination
  }

  changePage(page) {
    // console.log('page',page,this.currentPage)
    if(page == this.currentPage){
      return
    }
    this.currentPage = page
    if(!this.loadingReport){
      this.generateReport(page)
    }
  }

  formatEndpoint(endpoint) {
    switch(endpoint) {
      case 'phpSendSheet':
        return 'Pesquisa de Satisfação'
      case 'sendSheet':
        return 'Notificação em massa'
    }
    return '----'
  }

  dismissModal() {
		this.modalService.dismissAll();
	}
}
