import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';
import {
	KtDialogService,
	LayoutConfigService,
	SparklineChartDirective,
	SparklineChartOptions,
	SubheaderService
} from '../../../core/_base/layout';
import {Widget4Data} from '../../partials/content/widgets/widget4/widget4.component';
import {UserService} from '../../../services/user.service';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {ToolsService} from '../../../services/tools.service';
import { MissionService } from '../../../services/mission.service';
import { Subscription } from 'rxjs';
import * as constants from '../../../constants';

@Component({
	selector: 'kt-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss']
})

export class ContactsComponent implements OnInit{

	constructor(private layoutConfigService: LayoutConfigService,
		public subHeader: SubheaderService,
		public user: UserService,
		public ktDialogService: KtDialogService,
		public api: ApiService,
		public notify: NotifyService,
		public ref: ChangeDetectorRef,
		public router: Router,
		private modalService: NgbModal,
		private http: HttpClient,
		private tools: ToolsService,
		private missionService: MissionService,
		) {		
		this.subHeader.setTitle('Contatos');
		this.load(1);
	}

	ngOnInit(){

	}

	constants = constants

	contacts: any = [];
	contact: any = {
		'nome':'',
		'whatsapp':'',
		'email':'',
		'sexo':'',
	};

	selectedContact: any = [];
	search: any = [];

	@ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;
	@ViewChild('modalCreate', {static: false}) modalCreate: ElementRef;
	@ViewChild('modalEdit', {static: false}) modalEdit: ElementRef;
	@ViewChild('modalDelete', {static: false}) modalDelete: ElementRef;
	@ViewChild('modalHistoric', {static: false}) modalHistoric: ElementRef;
	@ViewChild('modalSelectOperator', {static: false}) modalSelectOperator: ElementRef;

	protocols: any = [{
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, {
		code: '#04111941BCC',
		openDay: 'Out, 13/2019',
		openHour: '13hs:30min',
		closeDay: 'Out, 13/2019',
		closeHour: '14hs:30min',
		expand: false
	}, ];

	filterCategory: any[] = [
		{value: '0', viewValue: 'Estado'},
		{value: '1', viewValue: 'Gênero'},
	];

	regionOptions: any[] = [
		{nome: "Acre", sigla: "AC"},
        {nome: "Alagoas", sigla: "AL"},
        {nome: "Amapá", sigla: "AP"},
        {nome: "Amazonas", sigla: "AM"},
        {nome: "Bahia", sigla: "BA"},
        {nome: "Ceará", sigla: "CE"},
        {nome: "Distrito Federal", sigla: "DF"},
        {nome: "Espírito Santo", sigla: "ES"},
        {nome: "Goiás", sigla: "GO"},
        {nome: "Maranhão", sigla: "MA"},
        {nome: "Mato Grosso", sigla: "MT"},
        {nome: "Mato Grosso do Sul", sigla: "MS"},
        {nome: "Minas Gerais", sigla: "MG"},
        {nome: "Pará", sigla: "PA"},
        {nome: "Paraíba", sigla: "PB"},
        {nome: "Paraná", sigla: "PR"},
        {nome: "Pernambuco", sigla: "PE"},
        {nome: "Piauí", sigla: "PI"},
        {nome: "Rio de Janeiro", sigla: "RJ"},
        {nome: "Rio Grande do Norte", sigla: "RN"},
        {nome: "Rio Grande do Sul", sigla: "RS"},
        {nome: "Rondônia", sigla: "RO"},
        {nome: "Roraima", sigla: "RR"},
        {nome: "Santa Catarina", sigla: "SC"},
        {nome: "São Paulo", sigla: "SP"},
        {nome: "Sergipe", sigla: "SE"},
        {nome: "Tocantins", sigla: "TO"}
	];

	genderOptions: any[] = [
		{value: '0', viewValue: 'Masculino'},
		{value: '1', viewValue: 'Feminino'},
		{value: '2', viewValue: 'Indefinido'},
	];

	onFilterClick() {
		this.modalService.open(this.modalFilter);
	}

	ondotsClick(i) {
		this.selectedContact = this.contacts[i];
		// this.modalService.open(this.modalHistoric);
		this.modalService.open(this.modalEdit);
	}

	deleteContactClick(i) {
		this.selectedContact = this.contacts[i];
		// this.modalService.open(this.modalHistoric);
		this.modalService.open(this.modalDelete);
	}

	onOperatorClick() {
		this.modalService.open(this.modalSelectOperator);
	}

	onExpandClick(i) {
		this.protocols[i].expand = !this.protocols[i].expand;
	}

	onContactClick(i) {
		this.router.navigate(['/contact']);
	}

	async load(page){
		this.ktDialogService.show();
		let items = await this.api.get_contacts(page,this.search)
		this.contacts = items.contacts
		this.contacts.total = items.total
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
		this.ktDialogService.hide();
	}

	nextPage($event) {
		this.load($event)
	}

	createModal(){
		this.modalService.open(this.modalCreate);
	}

	createContact(){
		if(!this.contact.whatsapp || this.contact.whatsapp.length < 10){
			this.notify.show('Campo Whatsapp inválido', 'danger');
		}else{
			if(this.contact.sexo=='Não Identificado'){
				this.contact.sexo = '';
			}
			this.api.create_contact(this.contact).then(value => {
				if (value.success) {
					this.notify.show(value.message, 'success');
					this.modalService.dismissAll();
					this.load(1);
				}else{
					return this.notify.show(value.message, 'danger')
				}
			});
		}
		return this.contact = {
			'nome':'',
			'whatsapp':'',
			'email':'',
			'sexo':'',
		}
	}

	onFilter(){
		if(this.search){
			this.load(1);
			this.modalService.dismissAll();
		}else{
			return this.notify.show('Nenhum filtro selecionado', 'danger')
		}
	}

	async editContact(){
		this.ktDialogService.show();
		if(!this.selectedContact.whatsapp || this.selectedContact.whatsapp.length < 10){
			this.ktDialogService.hide();
			this.notify.show('Campo Whatsapp inválido', 'danger');
		}else{
			if(this.selectedContact.sexo=='Indefinido'){
				this.selectedContact.sexo = '';
			}
			let value = await this.api.edit_contact(this.selectedContact)
			if (value.success) {
				this.ktDialogService.hide();
				this.notify.show(value.message, 'success');
				this.modalService.dismissAll();
				this.load(1);
				return this.selectedContact = [];
			}else{
				this.ktDialogService.hide();
				return this.notify.show(value.message, 'danger')
			}
		}
	}

	async deleteContact(){
		this.ktDialogService.show();
		try{
			let value = await this.api.delete_contact(this.selectedContact)
			if (value.success) {
				this.ktDialogService.hide();
				this.notify.show(value.message, 'success');
				this.modalService.dismissAll();
				this.load(1);
				return this.selectedContact = [];
			}else{
				this.ktDialogService.hide();
				return this.notify.show(value.message, 'danger')
			}
		}catch(error){
			this.ktDialogService.hide();
			return this.notify.show('Ocorreu um erro!', 'danger')
		}
		
	}

	fullRegion(region){
		let print = ''
		this.regionOptions.forEach(r=>{
			if(region==r.sigla){
				print =  r.nome
			}
		})
		return print
	}

	formatCpfCnpj(str){
		if(str){
			if (str.length>11) {
				return str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
			} else {
				return str.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
			}
		} 
	}
}
