import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
	name: 'phone'
})
export class PhonePipe implements PipeTransform {

	addStr(str, index, stringToAdd){
		return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
	}

	transform(phoneValue: number | string, country: string): any {
		try {
			const phoneNumber = parsePhoneNumber(phoneValue + '', country as CountryCode);
			if(phoneNumber.nationalNumber.length==10){
				phoneNumber.nationalNumber = this.addStr(phoneNumber.nationalNumber,2,'9')
			}
			return phoneNumber.formatNational();
		} catch (error) {
			return phoneValue;
		}
	}

}
