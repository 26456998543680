// Angular
import {Injectable} from '@angular/core';
// RxJS
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable()
export class KtDialogService {
	private ktDialog: any;
	private currentState: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private checkEvent: any = null;

	// Public properties
	constructor(public router: Router,
				private ngxService: NgxUiLoaderService) {
		this.router.events.subscribe(value => {
			this.hide();
		});

		this.ktDialog = new KTDialog({'type': 'loader', 'placement': 'top center', 'message': 'Carregando ...'});
	}

	show() {
		this.ngxService.start();

		// this.currentState.next(true);
		// this.ktDialog.show();

		// this.checkEvent = setTimeout((that) => {
		// 	// if (that.checkIsShown()) {
		// 	that.hide();
		// 	// }
		//
		// 	that.checkEvent = null;
		// }, (10 * 1000), this);
	}

	hide() {
		this.ngxService.stop();

		// this.currentState.next(false);
		// this.ktDialog.hide();
		//
		// if (this.checkEvent) {
		// 	clearTimeout(this.checkEvent);
		// 	this.checkEvent = null;
		// }
	}

	checkIsShown() {
		return this.currentState.value;
	}
}
