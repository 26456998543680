import {Injectable, NgZone} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	public model: any = {
		type: 'Imediato',
		name: '',
		content: '',
		speed: '2',
		at_date: (new Date()),
		at_time: {hour: (new Date().getHours()), minute: (new Date().getMinutes()), second: (new Date().getSeconds())},
		contacts: [],
		ask_answer: false,
		blacklist: false,
		blacklist_message: 'Envie *sair* para não fazer parte da lista de campanhas.',
		answers: []
	};
	activeGroup: any = null;

	constructor(public ngZone: NgZone) {
	}
}
