import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'protocolGroupBy'
})
export class ProtocolGroupByPipe implements PipeTransform {

	transform(value: any, ...args: any[]): any {
		// console.log(value);
		// value.sort((a, b) => {
		// 	return a.created_at < b.created_at ? -1 : 1;
		// });
		return value.filter((thing, i, arr) => {
			return arr.indexOf(arr.find(t => t.contato_id === thing.contato_id)) === i;
		});

		// const ret = value.reduce((agg, item) => {
		// 	agg[item.contato_id] = item;
		// 	return agg;
		// }, []).map(e => e);

		// groupBy(protocols => {
		// 	return value.reduce((agg, item) => {
		// 		const group = item.contato_id;
		// 		agg[group] = item; //[...(agg[group] || []), item];
		// 		return agg;
		// 	}, {});
		// }),
		// 	mergeMap(group => group)
	}

}
