import {Injectable} from '@angular/core';
import * as constants from '../constants';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorService} from './error.service';
import {UserService} from './user.service';

@Injectable({
	providedIn: 'root'
})
export class UzapService {

	constructor(public http: HttpClient,
				public error: ErrorService,
				public user: UserService) {
	}

	auth(login, password) {
		return this.http.post(constants.API_BASEURL + '/authenticate', {login, password}).toPromise().then().catch(this.error.handleError);
	}

	contactSearch(query) {
		return this.http.get(constants.API_BASEURL + '/contact/search', {
			params: new HttpParams().set('query', query),
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	updateUser(data) {
		data.id = this.user.getUser().id;
		return this.http.put(constants.API_BASEURL + '/user/' + this.user.getUser().id, data, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getContactProtocols(contactId) {
		return this.http.get(constants.API_BASEURL + '/contact/protocols/' + contactId, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	contactAdvancedSearch(contact_id, protocols, message) {
		return this.http.get(constants.API_BASEURL + '/contact/advancedSearch/', {
			params: new HttpParams().set('contact_id', contact_id).set('protocols', protocols).set('message', message),
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	channelQrCode(channel_id) {
		return this.http.get(constants.API_BASEURL + '/user/channel/qrCode/' + channel_id, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getAutomaticAnswers(channel_id) {
		return this.http.get(constants.API_BASEURL + '/automatic_answers/find/' + channel_id, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	addAutomaticAnswers(canal_id: any, title: string, body: any, usuario_id, privacidade) {
		return this.http.post(constants.API_BASEURL + '/automatic_answers/', {canal_id, titulo: title, conteudo: body, usuario_id, privacidade}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	editAutomaticAnswers(canal_id: any, id, title: string, body: any, usuario_id) {
		return this.http.put(constants.API_BASEURL + '/automatic_answers', {
			canal_id,
			titulo: title,
			conteudo: body,
			usuario_id
		}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken()),
			params: new HttpParams().set('id', id)
		}).toPromise().then().catch(this.error.handleError);
	}

	removeAutomaticAnswers(canal_id: any, id) {
		return this.http.delete(constants.API_BASEURL + '/automatic_answers/' + id, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	forwardProtocol(protocol_id, department, operator, observation) {
		return this.http.post(constants.API_BASEURL + '/user/forwardProtocol', {protocol_id, department, operator, observation}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getDepartments() {
		return this.http.get(constants.API_BASEURL + '/user/departments', {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getOperators() {
		return this.http.get(constants.API_BASEURL + '/user/operators', {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getLastCampaign() {
		return this.http.get(constants.API_BASEURL + '/sender/last', {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getAllCampaign() {
		return this.http.get(constants.API_BASEURL + '/sender/all', {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	sendCampaign(data) {
		return this.http.post(constants.API_BASEURL + '/sender', data, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getChannels() {
		return this.http.get(constants.API_BASEURL + '/user/channels', {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	channelReboot(channelId: any) {
		return this.http.post(constants.API_BASEURL + '/user/channel/reboot/' + channelId, {}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	syncChannel(channelId) {
		return this.http.post(constants.API_BASEURL + '/user/channel/sync2/' + channelId, {}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	createChannel() {
		return this.http.post(constants.API_BASEURL + '/user/channel/create/', {}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	removeChannel(id: any) {
		return this.http.delete(constants.API_BASEURL + '/user/channel/' + id, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	channelDisconnect(channelId: any) {
		return this.http.post(constants.API_BASEURL + '/user/channel/disconnect/' + channelId, {}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	updateChannelBot(channelId: any, bot_on: any) {
		return this.http.post(constants.API_BASEURL + '/user/channel/update_bot/' + channelId, {bot_on: (bot_on ? 1 : 0)}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	channelClean(channelId: any) {
		return this.http.post(constants.API_BASEURL + '/user/channel/clean/' + channelId, {}, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	updateCompany(data) {
		return this.http.put(constants.API_BASEURL + '/user/company/' + this.user.getUser().id, data, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	getCompanyInfo() {
		return this.http.get(constants.API_BASEURL + '/user/company_info', {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	removeCampaign(id: any) {
		return this.http.delete(constants.API_BASEURL + '/user/campaign/' + id, {
			headers: new HttpHeaders().set('x-access-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}
}
