import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {ProtocolListStore} from './protocol-list.store';
import {ProtocolList} from './protocol-list.model';
import {ProtocolListQuery} from './protocol-list.query';
import {take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProtocolListService {

	constructor(private protocolListStore: ProtocolListStore,
				private protocolListQuery: ProtocolListQuery) {
	}

	get(id) {
		return this.protocolListQuery.selectEntity(id).pipe(take(1)).toPromise();
	}

	add(protocolList: any) {
		if (!Array.isArray(protocolList)) {
			protocolList = [protocolList];
		}

		for (const protocol of protocolList) {
			if (protocol.mensagens_recebidas) {
				protocol.unread_messages_count = protocol.mensagens_recebidas.filter(msg => !msg.lida).length;

				if (protocol.mensagens_recebidas.length) {
					protocol.last_received_message = protocol.mensagens_recebidas[(protocol.mensagens_recebidas.length - 1)].conteudo;
				}

				protocol.mensagens = [...protocol.mensagens_enviadas, ...protocol.mensagens_recebidas];
				protocol.mensagens.sort((a, b) => {
					const res = (a.timestamp && b.timestamp) ? (a.timestamp < b.timestamp) : (a.created_at < b.created_at);
					return res ? -1 : 1;
				});
			}

			if (protocol.mensagens) {
				protocol.last_message = protocol.mensagens[protocol.mensagens.length - 1];
			}
		}

		for (const protocol of protocolList) {
			this.protocolListStore.upsert(protocol.id, protocol);
		}
	}

	update(id, protocolList: Partial<ProtocolList>) {
		if (protocolList.mensagens) {
			for (const mensagem of protocolList.mensagens) {
				if (mensagem.em_resposta_enviada_id) {
					mensagem.em_resposta = protocolList.mensagens.find(e => e.id === mensagem.em_resposta_enviada_id);
				} else if (mensagem.em_resposta_recebida_id) {
					mensagem.em_resposta = protocolList.mensagens.find(e => e.id === mensagem.em_resposta_recebida_id);
				}
			}
		}

		this.protocolListStore.update(id, protocolList);
	}

	remove(id: ID) {
		this.protocolListStore.remove(id);
	}

	select(id: ID) {
		this.protocolListStore.update(id, list => {
			return {
				...list,
				unread_messages_count: 0,
				chat_events: [...list.mensagens || [], ...list.protocolos_encaminhados || []].sort((a, b) => {
					let aTimestamp = a.timestamp || a.created_at;
					let bTimestamp = b.timestamp || b.created_at;

					if (typeof (aTimestamp) === 'string') {
						aTimestamp = Date.parse(aTimestamp);
					}

					if (typeof (bTimestamp) === 'string') {
						bTimestamp = Date.parse(bTimestamp);
					}
					return aTimestamp - bTimestamp;
				})
			};
		});
		this.protocolListStore.setActive(id);
	}

	addMessage(id: any, mensagem: any) {
		this.protocolListStore.update(id, list => {
			if (mensagem.em_resposta_enviada_id) {
				mensagem.em_resposta = list.mensagens.find(e => e.id === mensagem.em_resposta_enviada_id);
			} else if (mensagem.em_resposta_recebida_id) {
				mensagem.em_resposta = list.mensagens.find(e => e.id === mensagem.em_resposta_recebida_id);
			}

			const mensagens = [...(list.mensagens || []), mensagem];

			if (mensagem.remetente) {
				return {
					...list,
					mensagens,
					last_message: mensagem,
					unread_messages_count: (this.protocolListQuery.getActive() && (this.protocolListQuery.getActive() as any).id !== id) ? 0 : (list.unread_messages_count + 1),
					chat_events: [...mensagens, ...list.protocolos_encaminhados || []].sort((a, b) => {
						let aTimestamp = a.timestamp || a.created_at;
						let bTimestamp = b.timestamp || b.created_at;

						if (typeof (aTimestamp) === 'string') {
							aTimestamp = Date.parse(aTimestamp);
						}

						if (typeof (bTimestamp) === 'string') {
							bTimestamp = Date.parse(bTimestamp);
						}
						return aTimestamp - bTimestamp;
					})
				};
			}

			return {
				...list,
				mensagens,
				last_message: mensagem,
				unread_messages_count: 0,
				chat_events: [...mensagens, ...list.protocolos_encaminhados || []].sort((a, b) => {
					let aTimestamp = a.timestamp || a.created_at;
					let bTimestamp = b.timestamp || b.created_at;

					if (typeof (aTimestamp) === 'string') {
						aTimestamp = Date.parse(aTimestamp);
					}

					if (typeof (bTimestamp) === 'string') {
						bTimestamp = Date.parse(bTimestamp);
					}
					return aTimestamp - bTimestamp;
				})
			};
		});
	}

	updateMessage(id: any, mensagem: any) {
		this.protocolListStore.update(id, list => {
			if (mensagem.em_resposta_enviada_id) {
				mensagem.em_resposta = list.mensagens.find(e => e.id === mensagem.em_resposta_enviada_id);
			} else if (mensagem.em_resposta_recebida_id) {
				mensagem.em_resposta = list.mensagens.find(e => e.id === mensagem.em_resposta_recebida_id);
			}

			const mensagens = JSON.parse(JSON.stringify(list.mensagens));
			const tmpMensagem = mensagens.find(e => e.id == mensagem.id);
			if (tmpMensagem) {
				tmpMensagem.status = mensagem.status;
			} else {
				mensagens.push(mensagem);
			}

			return {
				...list,
				mensagens,
				chat_events: [...mensagens, ...list.protocolos_encaminhados || []].sort((a, b) => {
					let aTimestamp = a.timestamp || a.created_at;
					let bTimestamp = b.timestamp || b.created_at;

					if (typeof (aTimestamp) === 'string') {
						aTimestamp = Date.parse(aTimestamp);
					}

					if (typeof (bTimestamp) === 'string') {
						bTimestamp = Date.parse(bTimestamp);
					}
					return aTimestamp - bTimestamp;
				})
			};
		});
	}

	addMessages(id, messages) {
		this.protocolListStore.update(id, list => {
			for (const mensagem of messages) {
				if (mensagem.em_resposta_enviada_id) {
					mensagem.em_resposta = list.mensagens.find(e => e.id === mensagem.em_resposta_enviada_id);
				} else if (mensagem.em_resposta_recebida_id) {
					mensagem.em_resposta = list.mensagens.find(e => e.id === mensagem.em_resposta_recebida_id);
				}
			}

			const mensagens = [...messages, ...(list.mensagens || [])];

			return {
				...list,
				mensagens,
				unread_messages_count: 0,
				chat_events: [...mensagens, ...list.protocolos_encaminhados || []].sort((a, b) => {
					let aTimestamp = a.timestamp || a.created_at;
					let bTimestamp = b.timestamp || b.created_at;

					if (typeof (aTimestamp) === 'string') {
						aTimestamp = Date.parse(aTimestamp);
					}

					if (typeof (bTimestamp) === 'string') {
						bTimestamp = Date.parse(bTimestamp);
					}
					return aTimestamp - bTimestamp;
				})
			};
		});
	}

	setActives(data: any) {
		this.protocolListStore.update(null, list => {
			return {...list, ativo: false};
		});

		this.protocolListStore.update(data.map(item => item.id), list => {
			return {...list, ativo: true};
		});
	}

	clear() {
		this.protocolListStore.destroy();
	}
}
