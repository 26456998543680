import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../services/user.service';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {ToolsService} from '../../../services/tools.service';
import { SocketService } from '../../../services/socket.service';
import axios from 'axios'

@Component({
	selector: 'kt-chatbot-att',
	templateUrl: './chatbot-att.component.html',
	styleUrls: ['./chatbot-att.component.scss'],
	host: {
		'(document:mousedown)': 'onClick($event)'
	}
})
export class ChatbotAttComponent implements OnInit {
	menu: any = [];
	userData: any = {};
	showEmojiPicker = false;
	selectedMenu: any = {};
	preOptions: any = [
		{option: '*',action: 'Finaliza Atendimento'},
		{option: '#',action: 'Volta para o Menu Anterior'},
	]

	@ViewChild('modalTags', {static: false}) modalTags: ElementRef;
	@ViewChild('modalOptions', {static: false}) modalOptions: ElementRef;
	@ViewChild('modalRemove', {static: false}) modalRemove: ElementRef;
	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;

	constructor(private layoutConfigService: LayoutConfigService,
				private modalService: NgbModal,
				public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public toolsService: ToolsService,
				public subHeader: SubheaderService,
				private socketService: SocketService,
				) {
		this.subHeader.setTitle('Chatbot - Atendimento');
		this.userData = JSON.parse(JSON.stringify(user.getUser()));

		this.load();
	}

	ngOnInit() {
	}

	private async load() {
		// this.ktDialogService.show();
		// this.menus = (await this.api.get_menus()).sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
		// this.ktDialogService.hide();
		this.getMenus()
		this.socketService.onSetMenus().subscribe(async data => {
			await this.hasMenu(data)
			this.menu = data
			this.setMainMenu()
		});
		this.socketService.onNewMenu().subscribe(data => {
			this.menu.menus.push(data)
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});

		if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
	}

	onToggleMenuVisibility(item: any) {
		item.active = !item.active
	}

	async onAddNewMenu() {
		let newOp = {
			textOption: 'Novo Menu',
			keywords: [],
		}
		let finish = this.menu.menus.find(el=>isNaN(el.option))

		if(finish){
			finish = this.menu.menus.indexOf(finish)
			this.menu.menus.splice(finish, 0, newOp);
		}else{
			this.menu.menus.push(newOp)
		}
		if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
	}

	async onAdvance() {
		if(this.menu){
			if(this.menu.imageEnabled && !this.menu.media.length){
				this.menu.imageEnabled = false
			}
			if(this.menu.menus.filter(el=>!el.option||el.option=="").length){
				return this.notify.show('Opção não pode ser vazia!','danger');
			}
			await this.save()
			this.router.navigateByUrl('/customMenu-att');
		}
	}

	async onRemoveMenu() {
		this.menu.menus = this.menu.menus.filter(e => e != this.selectedMenu);
		this.notify.show('Menu removido com Sucesso!','success');
		this.dismissModal()
	}

	onTags() {
		this.modalService.open(this.modalTags);
	}

	openModalRemove(item) {
		this.selectedMenu = item
		this.modalService.open(this.modalRemove);
	}

	onFileSelected() {
		return new Promise(resolve => {
			try {
				const inputNode: any = document.querySelector('#file');
				if (typeof (FileReader) !== 'undefined') {
					const reader = new FileReader();

					const fileName = inputNode.files[0].name;

					reader.onload = async (e: any) => {
						await this.saveFile('image', e.target.result, fileName,inputNode.files[0]);
						resolve(e.target.result);
					};

					reader.onerror = ev => {
						resolve(null);
					};

					reader.readAsDataURL(inputNode.files[0]);
				} else {
					resolve(null);
				}
			} catch (e) {
				resolve(null);
			}
		});
	}

	private async saveFile(type, base64, filename, data) {
		let env = {baseUrl:'https://gobot.digital', port:'5969'}
		var formData = new FormData();
		let fileType = data['type'].split('/')[0]
		formData.append(fileType, data);
		let token = this.user.getToken()
		axios.post(env.baseUrl+':'+env.port+'/upload', formData, {headers: {'Content-Type': 'multipart/form-data', 'authorization':token} }).then(resp=>{
			// console.log('uploadResp',resp)
			let url = `${env.baseUrl}:5969/${resp.data.files[fileType].filepath.split('upload-files/')[1]}`
			let ext = this.reverseString(this.reverseString(filename).split('.')[0])
			filename = filename.replace(`.${ext}`,'')
			let media = {url,type:fileType,filename,ext}
			this.menu.media = []
			this.menu.media.push(media)
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		}) 	
	}

	reverseString(str) {
		return str.split("").reverse().join("");
	}

	onAddEmoji($event: any, inputId?) {
		if (!this.userData.greeting_text) {
			this.userData.greeting_text = '';
		}

		if (inputId) {
			this.toolsService.insertAtCaret(inputId, $event.emoji.native);
			this.userData.greeting_text = (document.getElementById(inputId) as any).value;
		} else {
			this.userData.greeting_text += $event.emoji.native;
		}
	}

	toggleEmojiPicker(event) {
		if (!this.showEmojiPicker) {
			const that = this;
			setTimeout(() => {
				that.showEmojiPicker = !that.showEmojiPicker;
				that.ref.markForCheck();
			}, 50);

		}

		event.stopPropagation();
		event.preventDefault();
		return false;
	}

	async save() {
		let menus = []
		menus.push(this.menu)
		await this.socketService.updateMenu(menus)
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	onClick($event: any) {
		const that = this;
		if (that.showEmojiPicker) {
			setTimeout(() => {
				if (that.showEmojiPicker && !that.emojiMart.nativeElement.contains($event.target)) {
					that.showEmojiPicker = false;
				}
			}, 50);
		}
	}

	onAddItemEmoji($event: any, item) {
		item.icone = $event.emoji.native;
	}

	onToggleItemEmojiPicker(item: any) {
		item.show_emoji_picker = !item.show_emoji_picker;
	}

	onDrop($event) {
		moveItemInArray(this.menu.menus, $event.previousIndex, $event.currentIndex);
	}

	getMenus(){
		this.socketService.getMenus(this.user.getUser().instance_id);
	}

	dismissModal(){
		this.modalService.dismissAll();
		this.selectedMenu = {}
	}

	getImage(menu){
		let image = menu.media.find(el=>el.type=='image')
		if(image) return image.url
	}

	setMainMenu(){
		this.menu = this.menu.find(el=>!el.topMenuId)
	}

	verifyOption(e){
		let substringsArray = ['*','#']
		if(substringsArray.some(substring=>e.option==substring)){
			// e.option = ''
			this.notify.show('Você digitou uma opção já reservada!','danger');
		}
	}

	preOptionsModal(){
		this.modalService.open(this.modalOptions);
	}

	hasMenu(menus){
		if(!menus || menus.length<=0){
			let menu = {
				name:`Menu Principal ${this.user.getUser().instance_id}`,
				active: true,
				// channelNumber: this.user.getUser().whatsapp_id,
				channelId: this.user.getUser().instance_id,
				text: 'Novo Menu',
				menus: [],
				action: '',
				medias: [],
				contentOrder: [],
				keywords:[],
				isMenu: true
			}
			this.socketService.saveMenu(menu)
			setTimeout(()=>{
				this.getMenus()
			},2000)
		}
	}

	ngOnDestroy() {
		this.socketService.off()
	}
}
