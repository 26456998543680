import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
	user: any = {
		name: 'Melanie Trump',
		photo: 'assets/media/users/300_21.jpg',
		number: '+ 55 1234567678',
		marriage: 'N/D',
		country: 'Chicago',
		email: 'melaietrump@gmail.com',
		whatsapp: '+ 55 1234567878',
		gender: 'Feminino'
	};
	tabs: any = ['Meus Dados', 'Midias', 'Atendido Por', 'Rastreamento'];
	selectedTab: any = 0;

	@ViewChild('modalHistoric', {static: false}) modalHistoric: ElementRef;
	imgs: any = ['assets/media/users/300_21.jpg', 'assets/media/users/300_21.jpg', 'assets/media/users/300_21.jpg', 'assets/media/users/300_21.jpg'];
	mediaTabs: any = ['Imagens', 'Videos', 'Audios', 'Arquivos'];
	selectedMediaTab: any = 0;
	isOnFocus: any = false;
	imageFocus: number;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

	onTabClick(i) {
		this.selectedTab = i;
	}

	onProtocolClick() {
		this.modalService.open(this.modalHistoric);
	}

	onMediaTabClick(i) {
		this.selectedMediaTab = i;
	}

	onFocus(i: any) {
		this.isOnFocus = !this.isOnFocus;
		this.imageFocus = i;
	}
}
