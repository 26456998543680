import { Component, OnInit,ChangeDetectorRef, ViewChild, HostListener, ElementRef } from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';
import * as cep from 'cep-promise';
import * as constants from '../../../constants';

@Component({
  selector: 'kt-attendances',
  templateUrl: './attendances.component.html',
  styleUrls: ['./attendances.component.scss']
})
export class AttendancesComponent implements OnInit {

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    private socketService: SocketService,
    public subHeader: SubheaderService,
    public ref: ChangeDetectorRef,
    public ktDialogService: KtDialogService,
  ) { 
    this.subHeader.setTitle('Gerenciar - Atendimentos');
  }

  @ViewChild('modalFinishReasons', {static: false}) modalFinishReasons: ElementRef;
  @ViewChild('modalManageMessages', {static: false}) modalManageMessages: ElementRef;
  @ViewChild('modalForward', {static: false}) modalForward: ElementRef;
  @ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;
  @ViewChild('modalSureFinish', {static: false}) modalSureFinish: ElementRef;

  attendances: any = [
    {name:'Auto Atendimento', status:'opened', class:'bgYellow text-dark d-flex align-items-center', cls:'bgYellow text-dark', col:'col pl-0', items:[]},
    {name:'Aguardando Atendimento', status:'waiting', class:'bgBlue text-white d-flex align-items-center', cls:'bgBlue text-white', col:'col', items:[]},
    {name:'Em Atendimento', status:'in_attendance', class:'bg-success text-white d-flex align-items-center', cls:'bg-success text-white', col:'col pr-0', items:[]},
  ]

  tabulations: any = []
  operators: any = []
  onlineOperators:  any = [] 
  departments: any = []
  objAux: any = {weekDays:['1','2','3','4','5'],department:[]}
  search: any = {type:''}
  attMsgs: any = {}
  clientSelected: any = {}
  forward: any = {}

  ngOnInit() {
    this.socketService.onSetDepartments().subscribe((data:any) => {
			this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		});
    this.socketService.onSetOperators().subscribe((data:any) => {
      this.operators = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		});
    this.socketService.onSetOnlineOperators().subscribe((data:any) => {
      this.onlineOperators = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		});
    this.getOpened()
    this.socketService.onSetOpened().subscribe((data:any) => {
      this.attendances[0].items = data.reverse()
		});
    this.getInAtt()
    this.socketService.onSetInAtt().subscribe((data:any) => {
      this.attendances[2].items = data.reverse()
		});
    this.getWaiting()
    this.socketService.onSetWaiting().subscribe((data:any) => {
      this.attendances[1].items = data.reverse()
		});
    this.socketService.onManageMessages().subscribe((data:any) => {
      this.attMsgs.msgs = []
      this.attMsgs.attendanceId = data['attendanceId']
      data['messages'].forEach(el=>{
        el.tipo = el.type
        el.conteudo = el.body
        el.remetente = !el.fromMe
        el.legenda = el.caption
        el.midia_url = el.mediaURL
        el.dados_extras = {}
        el.timestamp = el.timestamp*1000
        this.attMsgs.msgs.push(el)
      })
      // console.log('attMsgs',this.attMsgs.msgs)
		});

    this.socketService.onNewAttendance().subscribe(async data => {
      console.log('newAttendance',data)
      await this.attendances.forEach((el) => { 
        if(data['channel']==this.user.getUser().instance_id || (data['secondaryChannel'] && data['secondaryChannel'].mainChannelId==this.user.getUser().instance_id)){
          if(el.status==data['status']){
            el.items.unshift(data)
          }
        }
      });
      if (!this.ref['destroyed']){
        this.ref.detectChanges();
      }
		});

    this.socketService.onUpdatedAttendance().subscribe(async data => {
      console.log('updatedAttendance',data)
      await this.attendances.forEach((el) => { 
        if(data['channel']==this.user.getUser().instance_id || (data['secondaryChannel'] && data['secondaryChannel'].mainChannelId==this.user.getUser().instance_id)){
          el.items = el.items.filter(element => {
            return element._id != data['_id']
          }) 
        }
      });
      await this.attendances.forEach((el) => { 
        if(el.channel==this.user.getUser().instance_id){
          if(el.status==data['status']){
            el.items.unshift(data)
          }
        }
      });
      if (!this.ref['destroyed']){
        this.ref.detectChanges();
      }
		});

    this.socketService.onNewMessage().subscribe(async data => {
      if(this.attMsgs.attendanceId==data['attendance']._id){
        let msg = data['messages']
        msg.tipo = data['messages'].type
        msg.conteudo = data['messages'].body
        msg.remetente = !data['messages'].fromMe
        msg.midia_url = data['messages'].mediaURL
        msg.dados_extras = {}
        msg.legenda = data['messages'].caption
        msg.timestamp = msg.timestamp*1000
        this.attMsgs.msgs.push(msg)
      }
    })

    this.socketService.onSentMessage().subscribe(async data => {
      if(this.attMsgs.attendanceId==data['attendance']._id){
        let msg = {
          tipo:data['type'],
          conteudo:data['msg'],
          remetente:!data['fromMe'],
          midia_url:data['url'],
          dados_extras:{},
          legenda:data['caption'],
          timestamp:data['timestamp']
        }
        this.attMsgs.msgs.push(msg)
      }
    })

    setInterval(()=>{
      if(this.attendances){
        this.attendances.forEach(el=>{
          this.formatTimestamp(el.items)
          if (!this.ref['destroyed']){
            this.ref.detectChanges();
          }
        })
      }
    },1000)
  }

  openModalFilter(){
    this.getDepartments()
    this.getOperators()
    this.modalService.open(this.modalFilter, {size: 'sm'});
  }

  getDepartments(){
    this.socketService.getDepartments(this.user.getUser().instance_id);
  }
  getOperators(){
    this.socketService.getOperators(this.user.getUser().instance_id);
  }
  getOnlineOperators(){
    this.socketService.getOnlineOperators(this.user.getUser().instance_id);
  }
  getOpened(){
    this.socketService.getOpened(this.user.getUser().instance_id);
  }
  getWaiting(){
    this.socketService.getWaiting(this.user.getUser().instance_id);
  }
  getInAtt(){
    this.socketService.getInAtt(this.user.getUser().instance_id);
  }
  getReasons(){
    this.socketService.getReasons(this.user.getUser().instance_id);
  }
 
  dismissModal(){
		this.modalService.dismissAll();
    this.clientSelected = {}
    this.forward = {}
    this.attMsgs = []
	}

  findDepName(operatorDep){
    let dep = this.departments.find(el=>el._id==operatorDep)
    if(dep) return dep.name
  }

  doSearch(atts){
    if(this.search.type=='keyword' && this.search.keyword){
      return atts.filter(el=>
        el.clientName.toLowerCase().includes(this.search.keyword.toLowerCase()) ||
        el.clientNumber.toLowerCase().includes(this.search.keyword.toLowerCase()) ||
        el.protocol.toLowerCase().includes(this.search.keyword.toLowerCase())
      )
    }else if(this.search.type=='dep'  && this.search.dep){
      return atts.filter(el=>{
        return el.departmentName==this.search.dep
      })
    }else if(this.search.type=='op'  && this.search.op){
      return atts.filter(el=>{
        return el.operatorId==this.search.op
      })
    }else{
      return atts
    }
  }

  includes(array,element){
    return array.includes(element)
  }

  finishAttendance(att,fromModal=false){
    this.clientSelected = att
    if(!fromModal){
      this.getReasons()
      this.socketService.onSetReasons().subscribe(data => {
        if(this.router.url!='/attendances') return
        if(data){
          this.tabulations = data
        }
        if(this.tabulations.length>0){
          return this.modalService.open(this.modalFinishReasons, {size: 'sm'});
        }
        this.modalService.open(this.modalSureFinish, {size: 'sm'});
      });
    }else{
      if(!att.completionReason){
        return this.notify.show('Tabulação não selecionada!', 'danger');
      }
      this.modalService.open(this.modalSureFinish, {size: 'sm'});
    }
  }

  sureFinish(){
    let att = this.clientSelected
    this.attendances.forEach(function(el) { 
      // el.items = el.items.filter(element => {
      //   return element._id != att._id
      // }) 
      if(el.status==att.status){
        el.items.splice(el.items.indexOf(att),1);
      }
    });
    this.clientSelected = {}
    this.socketService.finishAtt(att,att.completionReason);
    if (!this.ref['destroyed']){
      this.ref.detectChanges();
    }
    this.dismissModal()
    this.notify.show('Atendimento finalizado com sucesso!', 'success');
  }

  formatData(data){
    let date = new Date(data)
    return date.toLocaleString()
  }

  manageMessages(att){
    this.clientSelected = att
    this.socketService.manageMessages(att._id)
    this.modalService.open(this.modalManageMessages, {size: 'sm'});
  }

  openModalForward(att){
    this.clientSelected = att
    this.getDepartments()
    this.getOnlineOperators()
    this.modalService.open(this.modalForward, {size: 'sm'});
  }

  forwardTo(){
    if(this.forward.operatorId || this.forward.departmentId){
      let client = this.clientSelected
      
      if(this.forward.type=='Operador'){
        let operator = this.onlineOperators.find(e=>e.operatorId==this.forward.operatorId)
        operator.id = operator.operatorId
        this.socketService.forwardToOperator(client,operator)
        this.attendances.forEach((el)=>{ 
          let find = el.items.find(e=>e._id==client._id)
          if(find){
            find.operatorName = operator.name
            find.operatorId = this.forward.operatorId
          } 
        });
        this.clientSelected = {}
      }
      else if(this.forward.type=='Departamento'){
        this.socketService.forwardToDepartment(client,this.forward.departmentId)
        this.attendances.forEach((el)=>{ 
          let find = el.items.find(e=>e._id==client._id)
          if(find) {
            find.departmentName = this.getDepName(this.forward.departmentId)
            find.operatorId = null
            find.operatorName = null
          }
        });
        this.clientSelected = {}
      }
      this.forward = {type:''}
      this.dismissModal()
      this.notify.show('Atendimento encaminhado com sucesso!', 'success');
    }
  }

  getDepName(id){
    let dep = this.departments.find(e=>e._id==id)
    return dep.name
  }

  formatTimestamp(atts){
    atts.map((el)=>{
        let attStart = Date.parse(el.attendanceStartTime)
        let date = new Date(Date.now() - attStart).toJSON()
        if(date){
          let time = date.split('T')[1].split('.')[0]
          el.timer = time
        }
        return el
    }) 
  }

  verifyPhotoURL(url){
    if(url && !url.includes('http')){
      return `${constants.storageURL}/avatar/${url}`
    }
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    this.socketService.off()
  }
}
