import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SocketService } from '../../../services/socket.service';
import {MatChipInputEvent} from '@angular/material/chips';
import axios from 'axios'

@Component({
	selector: 'kt-customMenu-att',
	templateUrl: './customMenu-att.component.html',
	styleUrls: ['./customMenu-att.component.scss']
})
export class CustomMenuAttComponent implements OnInit {
	// tabs: any = ['Texto', 'Imagens', 'Audio', 'Arquivos', 'Vídeos', 'Localização'];
	tabs: any = ['Texto', 'Imagens', 'Audio', 'Arquivos', 'Vídeos'];
	selectedTab: any = 0;

	menus: any = [];
	mainMenu: any = {};
	options: any = [];
	departments: any = [];
	operators: any = [];
	activeMenu: any = '';
	modalMenu: any = {};
	selectedOption: any = {};
	preOptions: any = [
		{option: '*',action: 'Finaliza Atendimento'},
		{option: '#',action: 'Volta para o Menu Anterior'},
	]

	@ViewChild('modalTags', {static: false}) modalTags: ElementRef;
	@ViewChild('modalOptions', {static: false}) modalOptions: ElementRef;
	@ViewChild('modalPreviewMenu', {static: false}) modalPreviewMenu: ElementRef;
	@ViewChild('modalEditOption', {static: false}) modalEditOption: ElementRef;

	constructor(public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public subHeader: SubheaderService,
				private modalService: NgbModal,
				private socketService: SocketService,
				) {
		this.subHeader.setTitle('ChatBot');
		this.load();
	}

	ngOnInit() {
	}

	onTabClick(i) {
		this.selectedTab = i;
	}

	private async load() {
		// this.ktDialogService.show();
		// const menus = (await this.api.get_menus()).sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
		// for (const menu of menus) {
		// 	menu.sub_menus = menu.sub_menus.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
		// }

		this.getMenus()
		this.socketService.onSetMenus().subscribe(data => {
			this.menus = data
			this.getMainMenu(data)
		});
		this.socketService.onNewMenu().subscribe(data => {
			this.menus.push(data)
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
		this.getDepartments()
		this.socketService.onSetDepartments().subscribe(data => {
			this.departments = data
		});
		this.getOperators()
		this.socketService.onSetOperators().subscribe(data => {
			this.operators = data
		});

		// this.ktDialogService.hide();

		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	getMainMenu(menus){
		this.mainMenu = menus.find(el=>!el.topMenuId)
	}

	onToggleMenuVisibility(item: any) {
		item.active = !item.active
	}

	async removeSubmenu(item: any) {
		if(!item.isMenu){
			return this.activeMenu.menus = await this.activeMenu.menus.filter(e => e != item);
		}
		this.socketService.deleteMenu(item._id)
		this.menus = await this.menus.filter(e => e._id != item._id);
		this.ref.detectChanges()
		this.notify.show('Submenu removido com Sucesso!','success');
	}

	async onAddSubMenu() {
		if(this.activeMenu.isMenu){
			return this.addOption()
		}
		let mainMenu = this.menus.find(el=>!el.topMenuId)
		let topMenuId = mainMenu._id
		let refObjectMenus = this.activeMenu._id
		
		let menu = {
			name:'Novo Submenu',
			active: true,
			// channelNumber: this.user.getUser().whatsapp_id,
			channelId: this.user.getUser().instance_id,
			text: 'Novo Submenu',
			menus: [],
			action: '',
			contentOrder: [],
			keywords:[],
			topMenuId,
			refObjectMenus,
			isMenu: true
		}

		this.socketService.saveMenu(menu)
	}

	async onAdvance() {
		await this.socketService.updateMenu(this.menus)
		this.notify.show('Menu editado com sucesso!', 'success');
		this.router.navigateByUrl('/chatbot-att');
	}

	onFileSelected(type, fieldId) {
		return new Promise(resolve => {
			try {
				const inputNode: any = document.querySelector(fieldId);
				if (typeof (FileReader) !== 'undefined') {
					const reader = new FileReader();

					const fileName = inputNode.files[0].name;

					reader.onload = async (e: any) => {
						if (e.total >= 15000000) {
							this.notify.show('O tamanho máximo permitido para envios é de 15 MB.');
							return;
						}

						await this.saveFile(type, e.target.result, fileName,inputNode.files[0]);
						resolve(e.target.result);
					};

					reader.onerror = ev => {
						resolve(null);
					};

					reader.readAsDataURL(inputNode.files[0]);
				} else {
					resolve(null);
				}
			} catch (e) {
				resolve(null);
			}
		});
	}

	private async saveFile(type, base64, filename,data) {
		let env = {baseUrl:'https://gobot.digital', port:'5969'}
		var formData = new FormData();
		let fileType = data['type'].split('/')[0]
		formData.append(fileType, data);
		let token = this.user.getToken()
		axios.post(env.baseUrl+':'+env.port+'/upload', formData, {headers: {'Content-Type': 'multipart/form-data', 'authorization':token} }).then(resp=>{
			// console.log('uploadResp',resp)
			let url = `${env.baseUrl}:5969/${resp.data.files[fileType].filepath.split('upload-files/')[1]}`
			let ext = this.reverseString(this.reverseString(filename).split('.')[0])
			filename = filename.replace(`.${ext}`,'')
			let media = {url,type:fileType,filename,ext}
			this.activeMenu.media.push(media)
			this.setMenuOrder(fileType)
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		}) 	
	}

	async onRemoveMedia(item: any) {
		// this.ktDialogService.show();
		// await this.api.menu_media_delete(item.id).then(value => {
		// 	this.updateSelectedMenuContentOrder();
		// 	this.ref.markForCheck();
		// });
		// this.ktDialogService.hide();
		this.activeMenu.media = this.activeMenu.media.filter(el=>el!=item)
		this.setMenuOrder(item.type,true)
		if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
	}

	onDrop($event) {
		moveItemInArray(this.activeMenu.contentOrder, $event.previousIndex, $event.currentIndex);
	}

	onAddItemEmoji($event: any, item) {
		item.icone = $event.emoji.native;
	}

	onToggleItemEmojiPicker(item: any) {
		item.show_emoji_picker = !item.show_emoji_picker;
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	onSubMenuDrop($event, item) {
		moveItemInArray(item.sub_menus, $event.previousIndex, $event.currentIndex);
	}

	parseIntt(str) {
		return parseInt(str);
	}

	onTags() {
		this.modalService.open(this.modalTags);
	}

	getMenus(){
		this.socketService.getMenus(this.user.getUser().instance_id);
	}
	getDepartments(){
		this.socketService.getDepartments(this.user.getUser().instance_id);
	}
	getOperators(){
		this.socketService.getOperators(this.user.getUser().instance_id);
	}
	getSubmenus(menu){
		function submenusOnly(el){
			if(el.refObjectMenus==menu._id || el.topMenuId==menu._id){
				return true
			}
		}
		return this.menus.filter(submenusOnly)
	}

	reverseString(str) {
		return str.split("").reverse().join("");
	}

	previewMenu(menu){
		let optionsArray = []
		this.options.forEach(element => {
			optionsArray.push(`${element.option} - ${element.textOption}`)
		});
		let options = optionsArray.join('\n')
		// menu.mediaURL = ''
		// menu.contentOrder.forEach({element,i => {
		// 	if(element=='image' || element=='video' && i<3){
		// 		console
		// 	}
		// });
		let text = menu.text
		
		if(!text){
			let currentMenu = this.menus.find(el=>el.topMenuId==menu._id)
			if(currentMenu){
				text = currentMenu.text
			}
		}
		menu.body = `
		${text}
		${options}
		`
		this.modalMenu = menu
		this.modalService.open(this.modalPreviewMenu);
	}

	setMenuOrder(item,remove=false){
		if(item=='text' && (!this.activeMenu.text || this.activeMenu.text=='')){
			return this.activeMenu.contentOrder = this.activeMenu.contentOrder.filter(el=>el!=item)
		}
		if(remove){
			return this.activeMenu.contentOrder = this.activeMenu.contentOrder.filter(el=>el!=item)
		}
		if(!this.activeMenu.contentOrder.includes(item)){
			this.activeMenu.contentOrder.push(item)
		}
		if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
	}

	hasMedia(){
		if(this.activeMenu.media && this.activeMenu.media.length){
			return true;
		}
		return false;
	}

	addOption(){
		// let option = this.activeMenu.menus.length
		// if(option==0) option++
		let newOp = {
			textOption: 'Nova Opção',
			keywords: [],
			media: [],
			contentOrder: [],
		}
		this.activeMenu.menus.push(newOp)
		if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
	}

	deleteOption(item){
		this.activeMenu.menus = this.activeMenu.menus.filter(el=>el!=item)
		if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		this.notify.show('Opção deletada com sucesso!', 'success');
	}

	editOption(item){
		this.selectedOption = item
		this.modalService.open(this.modalEditOption);
	}

	onDropArray($event,array) {
		moveItemInArray(array, $event.previousIndex, $event.currentIndex);
	}

	dismissModal(){
		this.modalService.dismissAll();
		this.selectedOption = {}
	}

	addKeyword(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		if (value) {
		  this.activeMenu.keywords.push(value);
		}
		event.input.value = ''
	}
	
	removeKeyword(kw): void {
		const index = this.activeMenu.keywords.indexOf(kw);

		if (index >= 0) {
			this.activeMenu.keywords.splice(index, 1);
		}
		// this.selectedOption.keywords = this.selectedOption.keywords.filter(el=>el!=kw)
	}

	getOptions(menu){
		function getSubs(m) {
			// console.log(m,menu)
			return m.refObjectMenus == menu._id || m.topMenuId == menu._id
		}
		// if(menu.menus){
		// 	menu.menus.forEach(element => {
		// 		this.options.push(element)
		// 	});
		// }else{
		// 	this.options.concat(this.menus.filter(getSubs))
		// 	menu.submenus = this.menus.filter(getSubs)
		// 	console.log(menu.submenus)
		// }
		return this.menus.filter(getSubs)
	}

	verifyOption(e){
		let substringsArray = ['*','#']
		if(substringsArray.some(substring=>e.option==substring)){
			// e.option = ''
			this.notify.show('Você digitou uma opção já reservada!','danger');
		}
	}

	preOptionsModal(){
		this.modalService.open(this.modalOptions);
	}

	ngOnDestroy() {
		this.socketService.off()
	}
}
