import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AudioService {

	constructor() {
	}

	async play(path) {
		return new Promise(async resolve => {
			const audio = new Audio();
			audio.src = path;
			audio.load();
			await audio.play();
			resolve(audio);
		});
	}

	playNotification() {
		this.play('assets/sounds/notification.mp3');
	}
}
