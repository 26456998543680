import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {UserService} from '../../../services/user.service';

@Component({
	selector: 'kt-survey-reports',
	templateUrl: './survey-reports.component.html',
	styleUrls: ['./survey-reports.component.scss']
})
export class SurveyReportsComponent implements OnInit {
	data: any = null;

	report_results = {
		page: 1,
		total: 0,
		items: []
	};

	search = {
		type: 'all'
	};

	hasSurvey = false

	constructor(public subHeader: SubheaderService,
				public router: Router,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public ref: ChangeDetectorRef,
				public user: UserService,
				) {
		this.subHeader.setTitle('Relatórios');
		this.load();
	}

	ngOnInit() {
	}

	doFilter() {
		this.loadReportResults();
	}

	onViewReportItem(item) {
		this.router.navigate(['/survey-report'], {queryParams: {notificationId: item.id, type: item.type}});
	}

	getReportItems($event: any) {
		if(this.data.report_results) this.data.report_results.page = $event;
	}

	private async load() {
		this.ktDialogService.show();
		if(
			this.user.getUser().permissoes && this.user.getUser().permissoes['Relatórios'] == '1' ||
			this.user.getUser().enable_surveys == 1
		)
		{
			this.hasSurvey = true
		}
		this.data = await this.api.get_survey_reports();
		// console.log('data',this.data)
		await this.loadReportResults(true);
		this.ktDialogService.hide();
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	private async loadReportResults(skipLoading = false) {
		if (!skipLoading) {
			this.ktDialogService.show();
		}

		const result = await this.api.get_survey_report_results(this.report_results.page, this.search);
		this.report_results.items = result.items;
		this.report_results.total = result.total;

		if (!skipLoading) {
			this.ktDialogService.hide();
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		}
	}
}
