import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
//import {HttpClient} from "@angular/common/http";
// import 'rxjs/add/operator/map';

//import * as constants from '../../providers/constants';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {
	private showConsole: boolean = true;

	constructor() {
	}

	private sendErrorStack(errorData: {}) {
		//this.http.post(constants.API_BASEURL + '', errorData)
	}

	public console(consoleType: any, message?: any): void {
		if (this.showConsole) {
			console.log(message);
		}
	}

	public handleError(error: HttpErrorResponse): Promise<any> {
		console.log(error);
		let message: any;
		let errors: any;

		switch (error.status) {
			case 401:
				errors = JSON.parse(error.error);
				if (Array.isArray(errors)) {
					if (errors[0].message) {
						message = errors[0];
					} else {
						message = {title: 'Ocorreu um erro', message: 'Desculpe, ocorreu um erro. Tente novamente'};
					}
				} else {
					if (errors.message) {
						message = errors;
					} else {
						message = {title: 'Ocorreu um erro', message: 'Desculpe, ocorreu um erro. Tente novamente'};
					}
				}
				//Array.isArray(errors) ? message = errors[0] : message = errors;
				//this.sendErrorStack();
				break;
			case 400:
				errors = error.error;  //JSON.parse(error.error);
				if (Array.isArray(errors)) {
					if (errors[0].message) {
						message = errors[0];
					} else {
						message = {title: 'Ocorreu um erro', message: 'Desculpe, ocorreu um erro. Tente novamente'};
					}
				} else {
					if (errors.message) {
						message = errors;
					} else {
						message = {title: 'Ocorreu um erro', message: 'Desculpe, ocorreu um erro. Tente novamente'};
					}
				}
				//Array.isArray(errors) ? message = errors[0] : message = errors;
				break;
			case 500:
				errors = error.error;
				try {
					errors = JSON.parse(error.error);
				} catch (e) {
				}
				if (Array.isArray(errors)) {
					if (errors[0].message) {
						message = errors[0];
					} else {
						message = {title: 'Ocorreu um erro', message: 'Desculpe, ocorreu um erro. Tente novamente'};
					}
				} else {
					if (errors.message) {
						message = errors;
					} else {
						message = {title: 'Ocorreu um erro', message: 'Desculpe, ocorreu um erro. Tente novamente'};
					}
				}
				//Array.isArray(errors) ? message = errors[0] : message = errors;
				break;
			default:
				//generic error message
				message = {title: 'Ocorreu um erro', message: 'Desculpe, ocorreu um erro. Tente novamente'};
				break;
		}
		return Promise.reject(message);
	}

}
