import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import {ToolsService} from '../../../services/tools.service';

@Component({
	selector: 'kt-surveys',
	templateUrl: './surveys.component.html',
	styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit {
	notificacoesTemplates = [];

	@ViewChild('modalDelete', {static: false}) modalDelete: ElementRef;
	@ViewChild('modalSend', {static: false}) modalSend: ElementRef;
	@ViewChild('modalUploadSheet', {static: false}) modalUploadSheet: ElementRef;
	private activeObject: any = null;

	showStatus: any = false;
	showToggle: any = true;
	showEdit: any = true;
	showUploadSheet: any = false;
	showSend: any = false;
	test_number: any = '';
	testingNumber = false;

	readingFile = false;
	arrayBuffer = null;
	sheetData = null;
	uploadingSheet = false;

	constructor(public subHeader: SubheaderService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public user: UserService,
				public toolsService: ToolsService,
				private modalService: NgbModal) {
		this.subHeader.setTitle('Pesquisa de Satisfação');

		this.load();

		this.showEdit = true;
		this.showToggle = true;
		this.showSend = true;
		this.showStatus = false;
		this.showUploadSheet = true;
	}

	ngOnInit() {
	}

	private async load() {
		this.ktDialogService.show();
		this.notificacoesTemplates = await this.api.get_surveys_templates();

		this.ktDialogService.hide();
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	onEdit(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.router.navigateByUrl('/survey');
	}

	onUploadSheet(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.modalService.open(this.modalUploadSheet);
	}

	onCreate() {
		this.user.notificacaoTemplate = {
			nome: '',
			icone: '',
			identificador: '',
			conteudo: '',
			modulo_id: this.user.getUser().modulos[0] && this.user.getUser().modulos[0].modulo_id || 0,
			// modulo_id: this.user.getUser().modulos.find(e => e.modulo.nome === 'Tray').modulo_id,
			tipo: 'Pesquisa',
			temporizador: 120
		};
		this.router.navigateByUrl('/survey');
	}

	async onToggleNotificationTemplate(notificacaoTemplate: any) {
		notificacaoTemplate.habilitada = notificacaoTemplate.habilitada == 1 ? 0 : 1;

		this.ktDialogService.show();
		// tslint:disable-next-line:indent
		await this.api.notification_template_save(JSON.parse(JSON.stringify(notificacaoTemplate)));
		this.ktDialogService.hide();

		if (notificacaoTemplate.habilitada) {
			this.notificacoesTemplates.filter(e => e.id !== notificacaoTemplate.id).map((notificacaoTemplate: any) => {
				notificacaoTemplate.habilitada = false;
				this.api.notification_template_save(JSON.parse(JSON.stringify(notificacaoTemplate)));
			});
		}
	}

	onDelete(notificacaoTemplate: any) {
		this.activeObject = notificacaoTemplate;
		this.modalService.open(this.modalDelete);
	}

	async doDelete() {
		this.modalService.dismissAll();

		this.ktDialogService.show();
		this.api.notification_template_delete(this.activeObject.id).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
				this.load();
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.activeObject = null;
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
		this.ktDialogService.hide();
	}

	doSend() {
		this.testingNumber = true;
		this.user.notificacaoTemplate.send = true
		this.api.test_notification_template(this.test_number, this.user.notificacaoTemplate).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.testingNumber = false;
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
		this.user.notificacaoTemplate.send = false
	}

	onSend(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.modalService.open(this.modalSend);
		console.log(this.user)
	}

	incomingfile($event) {
		this.readingFile = true;

		const fileReader = new FileReader();
		fileReader.onload = (e) => {
			this.arrayBuffer = fileReader.result;
			const data = new Uint8Array(this.arrayBuffer);
			const arr = [];
			for (let i = 0; i !== data.length; ++i) {
				arr[i] = String.fromCharCode(data[i]);
			}
			const bstr = arr.join('');
			const workbook = XLSX.read(bstr, {type: 'binary', cellText:false, cellDates:true});
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];

			var jsonData = XLSX.utils.sheet_to_json(worksheet, {raw: false, dateNF:'dd/mm/yyyy', rawNumbers: true});
			jsonData = jsonData.map((el)=>{
				el = (<any>Object).fromEntries(
					Object.entries(el).map(([key, value]) => 
						[key.trim(), value]
					)
				)
				return el
			})
			console.log('sheetData: ',jsonData);

			this.sheetData = jsonData;

			this.readingFile = false;
			this.ref.markForCheck();
		};
		fileReader.readAsArrayBuffer($event.target.files[0]);
	}

	async doSendSheet() {
		this.uploadingSheet = true;
		this.user.notificacaoTemplate.send = true;
		let count = {success: 0, failed: 0};
		this.notify.show(`Processo de envio iniciado!`, 'success');
		this.modalService.dismissAll();
		// for (const data of this.sheetData) {
		// 	try {
		// 		await this.api.test_notification_template((data as any).Telefone || data[Object.keys(data)[0]], this.user.notificacaoTemplate, data).then(value => {
		// 			count[(value.success ? 'success' : 'failed')]++;
		// 		}).catch(reason => {
		// 			console.log(reason);
		// 		});
		// 	} catch (e) {
		// 		console.log(e);
		// 	}
		// }
		let result = await this.api.send_sheet(this.user.notificacaoTemplate,this.sheetData)
		count = {success: result.success, failed: result.failed};

		this.uploadingSheet = false;
		this.user.notificacaoTemplate.send = false;
		this.sheetData = null;
		this.notify.show(`Processo concluído! Mensagens adicionadas na fila: ${count.success} / Mensagens com falha: ${count.failed}.`, 'success');
	}

	keypress(){
		return this.toolsService.keypress();
	}
}
