import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {UserService} from '../../../services/user.service';
import { SocketService } from '../../../services/socket.service';

@Component({
	selector: 'kt-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
	users: any = {items: [], total: 0};

	@ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;
	@ViewChild('modalHistoric', {static: false}) modalHistoric: ElementRef;
	@ViewChild('modalSelectOperator', {static: false}) modalSelectOperator: ElementRef;
	@ViewChild('modalUser', {static: false}) modalUser: ElementRef;

	selectedUser: any;
	selectBox: any = ''
	protocols: any = [];
	filterCategory: any = [{
		name: 'Região',
		subcategories: ['Região Norte', 'Região Nordeste', 'Região Centro-Oeste', 'Região Sudeste', 'Região Sul']
	}, {
		name: 'Gênero',
		subcategories: ['Masculino', 'Feminino', 'Outros']
	}, {
		name: 'Whatsapp',
		subcategories: ['Comercial', 'Pessoal']
	}, {
		name: 'Bloqueados',
		subcategories: []
	}, {
		name: 'Pop-ups',
		subcategories: []
	}, {
		name: 'Importados',
		subcategories: []
	},];
	private page: any = 1;
	permissions: any = [
		{name: 'Dashboard'},
		{name: 'Canal'},
		{name: 'ChatBot'},
		{name: 'APIs'},
		{name: 'Notificações'},
		{name: 'Pesquisa de Satisfação'},
		{name: 'Relatórios'},
	];
	groups: any = [];
	loading: any = false;

	constructor(public subHeader: SubheaderService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public user: UserService,
				private modalService: NgbModal,
				private socketService: SocketService,
				) {
		this.subHeader.setTitle('Gerenciador de Usuários');
	}

	ngOnInit() {
		this.load();
		this.checkPermission();
	}

	private async load() {
		this.ktDialogService.show();
		const data = await this.api.get_users(this.page);
		if(data.total){
			data.items.map(contact => {
				contact.permissoes_descricao = 'Nenhuma';
	
				try {
					contact.permissoes = JSON.parse(contact.permissoes);
				} catch (e) {
					console.log(e);
					contact.permissoes = [];
				}
	
				try {
					contact.permissoes_grupos = Object.values(JSON.parse(contact.permissoes_grupos));
				} catch (e) {
					contact.permissoes_grupos = [];
				}
	
				contact.permissoes_descricao = [];
				for (const permission of this.permissions) {
					if (!contact.permissoes[permission.name]) {
						contact.permissoes[permission.name] = '0';
					} else if (contact.permissoes[permission.name] == '1') {
						contact.permissoes_descricao.push(permission.name);
					}
				}
				contact.permissoes_descricao = contact.permissoes_descricao.join(', ');
			});
		}

		this.users = data;

		if (!this.groups.length) {
			this.groups = await this.api.get_groups();
		}

		this.ktDialogService.hide();
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	onFilterClick() {
		this.modalService.open(this.modalFilter);
	}

	ondotsClick(contact) {
		this.selectedUser = contact;
		try{
			this.selectedUser.permissoes = JSON.parse(this.selectedUser.permissoes)
		}
		catch{
			console.log('catch')
		}
		this.modalService.open(this.modalUser);
	}

	onOperatorClick() {
		this.modalService.open(this.modalSelectOperator);
	}

	onExpandClick(i) {
		this.protocols[i].expand = !this.protocols[i].expand;
	}

	onUserClick(i) {
		this.router.navigate(['/user']);
	}

	onNew() {
		this.selectedUser = {
			id: null,
			email: '',
			senha: '',
			confirmar_senha: '',
			permissoes: [],
			permissoes_grupos: [],
		};

		for (const permission of this.permissions) {
			this.selectedUser.permissoes[permission.name] = '0';
		}

		this.modalService.open(this.modalUser);
	}

	getUsers($event: any) {
		this.page = $event;
		this.load();
	}

	async onSave() {
		this.ktDialogService.show();
		try{
			JSON.parse(this.selectedUser.permissoes)
		}
		catch{
			this.selectedUser.permissoes = JSON.stringify(Object.assign({}, this.selectedUser.permissoes));
			this.selectedUser.permissoes_grupos = JSON.stringify(Object.assign({}, this.selectedUser.permissoes_grupos));
		}
		if(!this.selectedUser.email){
			this.ktDialogService.hide();
			return this.notify.show('Campo Email vazio!', 'danger');
		}
		if(this.selectedUser.password && (this.selectedUser.password != this.selectedUser.confirmar_senha)){
			this.ktDialogService.hide();
			return this.notify.show('Campo Senha e Confirmar Senha não batem!', 'danger');
		}

		if(this.selectedUser.password && this.selectedUser.password!=''){
			this.selectedUser.senha = this.selectedUser.password
		}

		this.selectedUser.isOperator = 0
		
		await this.api.user_save(this.selectedUser).then(value => {
			if (value.success) {
				value.user.senha = this.selectedUser.senha
				this.notify.show(value.message, 'success');
				this.modalService.dismissAll();
				this.load();
			}
		});
		this.ktDialogService.hide();
	}

	shouldShowPermission(permission) {
		switch (permission.name) {
			case 'ChatBot': {
				return this.user.getUser().enable_chatbot == 1 || (this.user.getUser().enable_operators == 1 && this.user.getUser().operationalChannelEnabled == 0);
			}
			case 'APIs': {
				return this.user.getUser().enable_api == 1;
			}
			case 'Notificações': {
				return this.user.getUser().enable_batch_notifications == 1;
			}
			case 'Grupo de Contatos': {
				return this.user.getUser().enable_groups == 1;
			}
			case 'Pesquisa de Satisfação': {
				return this.user.getUser().enable_surveys == 1;
			}
			case 'Relatórios': {
				return this.user.getUser().enable_surveys == 1;
			}
			default: {
				return true;
			}
		}
	}

	async onRemoveUser(contact) {
		this.ktDialogService.show();

		if (confirm('Você realmente quer remover este usuário?')) {
			await this.api.user_remove(contact.id).then(value => {
				if (value.success) {
					this.notify.show(value.message, 'success');
					this.modalService.dismissAll();
					this.load();
				}
			});
		}
		this.ktDialogService.hide();
	}

	checkPermission(){
		if(this.user.getUser().canal_id && !this.user.getUser().admin){
			this.router.navigate(['/dashboard-main']);
		}
	}

	checkAll() {
		if(this.users.items.length){
			if (this.users.items.every(val => val.checked == true))
				this.users.items.forEach(val => { val.checked = false });
			else
				this.users.items.forEach(val => { val.checked = true });
		}
	}

	boxSelected(){
		switch(this.selectBox){
		case 'select':
			this.checkAll()
			break
		case 'delete':
			this.deleteSelected()
			break
		}
		this.selectBox = ''
	}

	deleteSelected(){
		let ops = this.users.items.filter(el => el.checked == true)
		ops.forEach(el=> {
		  this.onRemoveUser(el)
		});
		this.notify.show(`${ops.length} usuários removidos com sucesso!`, 'success');
	  }
}
