import {Injectable} from '@angular/core';

import * as $ from 'jquery';
import 'bootstrap-notify';

@Injectable({
	providedIn: 'root'
})
export class NotifyService {

	constructor() {
	}

	public async show(message, type = 'success', interval=null) {	
		var notify = ($ as any).notify({
			message
		}, {
			type
		});
		if(interval){
			let si = setInterval(()=>{
				notify.update({ type: 'warning', message: message + `${interval} segundos!`, progress: 20});
				interval--
			},1000);
			setTimeout(()=>{
				clearInterval(si)
				notify.close()
			},interval*1000)
		}
	}

}
