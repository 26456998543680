import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';

@Component({
	selector: 'kt-editar-notificacao',
	templateUrl: './editNotificationsTags.component.html',
	styleUrls: ['./editNotificationsTags.component.scss'],
	host: {
		'(document:mousedown)': 'onClick($event)'
	}
})
export class EditNotificationsTagsComponent implements OnInit {
	private showEmojiPicker = false;
	test: any = {number: null};
	testingNumber: any = false;
	identifiers: any = [];
	tags: any = [];

	newtag: any = {};
	newidentifier: any = {};

	selectedItem: any = {};

	@ViewChild('modalAddTag', {static: false}) modalAddTag: ElementRef;
	@ViewChild('modalAddIdentifier', {static: false}) modalAddIdentifier: ElementRef;
	@ViewChild('modalDeleteTag', {static: false}) modalDeleteTag: ElementRef;
	@ViewChild('modalDeleteIdentifier', {static: false}) modalDeleteIdentifier: ElementRef;
	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;

	constructor(public user: UserService,
				public ref: ChangeDetectorRef,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public router: Router,
				public subHeader: SubheaderService,
				private modalService: NgbModal,
				private _location: Location) {
	}

	ngOnInit() {
		this.getNotificationsIds();
		this.getNotificationsTags();
	}

	async getNotificationsIds(){
		this.identifiers = await this.api.get_notification_identifiers(this.user.getUser().instance_id)
	}
	
	async getNotificationsTags(){
		this.tags = await this.api.get_notification_tags()
	}

	/**
	 * Volta a página de notificações.
	 *
	 * @author Daniel Silva Marcelino
	 */
	public back(): void {
		this._location.back();
	}

	async onSave() {
		this.ktDialogService.show();
		// tslint:disable-next-line:indent
		await this.api.notification_items_save({'channel_id':this.user.getUser().instance_id,'identifiers':this.identifiers,'tags':this.tags}).then(value => {
			if (value.success) {
				this.notify.show(value.message, 'success');
				this.back();
			}
		});
		this.ktDialogService.hide();
	}

	onCancel() {
		this._location.back();
	}

	modalTag(){
		this.modalService.open(this.modalAddTag);
	}
	modalIdentifier(){
		this.modalService.open(this.modalAddIdentifier);
	}

	addTag(){
		this.tags.push(this.newtag)
		this.newtag = {}
		this.modalService.dismissAll();
	}
	addIdentifier(){
		this.identifiers.push(this.newidentifier)
		this.newidentifier = {}
		this.modalService.dismissAll();
	}
	dismissModal(){
		this.modalService.dismissAll();
		this.newidentifier = {}
		this.newtag = {}
		this.selectedItem = {}
	}
	mDeleteTag(item){
		this.modalService.open(this.modalDeleteTag)
		this.selectedItem = item
	}
	mDeleteIdentifier(item){
		this.modalService.open(this.modalDeleteIdentifier)
		this.selectedItem = item
	}
	deleteIdentifier(){
		if(this.selectedItem.id){
			this.selectedItem.deletado = 1
		}else{
			this.identifiers = this.identifiers.filter((value, index, arr)=>{ 
				return value != this.selectedItem;
			});
		}
		this.selectedItem = {}
		this.modalService.dismissAll();
	}
	deleteTag(){
		if(this.selectedItem.id){
			this.selectedItem.deletado = 1
		}else{
			this.tags = this.tags.filter((value, index, arr)=>{ 
				return value != this.selectedItem;
			});
		}
		this.selectedItem = {}
		this.modalService.dismissAll();
	}

	filter(array){
		return array.filter(i => i.deletado != 1)
	}

	hasTray(){
		return this.user.getUser().modulos.find(usuarioModulo => usuarioModulo.modulo.nome === 'Tray')
	}
}
