import {Injectable} from '@angular/core';
import {NotificationService} from './notification.service';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {
	public notificationSettings: any = {sounds: false, desktop: false};

	constructor(public notification: NotificationService) {
		this.loadNotificationSettings();
	}

	private loadNotificationSettings() {
		const settings = localStorage.getItem('notification_settings');
		if (settings) {
			this.notificationSettings = JSON.parse(settings);

			if (this.notificationSettings.desktop) {
				this.notification.requestPermission();
			}
		}
	}

	public saveNotificationSettings() {
		localStorage.setItem('notification_settings', JSON.stringify(this.notificationSettings));
	}

	public saveCloseProtocolMessage(msg) {
		localStorage.setItem('close_protocol_message', msg);
	}

	public getCloseProtocolMessage() {
		return localStorage.getItem('close_protocol_message');
	}

	public saveSignMessage(v) {
		localStorage.setItem('sign_message', v);
	}

	public getSignMessage() {
		return localStorage.getItem('sign_message');
	}
}
