import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'timedescription'
})
export class TimedescriptionPipe implements PipeTransform {

	transform(value: any, ...args: any[]): any {
		const momentDate = moment(value);
		if (momentDate.isSame(new Date(), 'day')) {
			return momentDate.format('HH:mm');
		}

		return momentDate.format('DD/MM/YY');
	}

}
