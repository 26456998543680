import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import insertTextAtCursor from 'insert-text-at-cursor';
import {ToolsService} from '../../../services/tools.service';

@Component({
	selector: 'kt-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.scss'],
	host: {
		'(document:mouseup)': 'onClick($event)'
	}
})
export class SurveyComponent implements OnInit {
	private showEmojiPicker = [];
	test: any = {number: null};
	testingNumber: any = false;
	tags: any =[];

	@ViewChild('modalTags', {static: false}) modalTags: ElementRef;
	@ViewChild('modalIdentifiers', {static: false}) modalIdentifiers: ElementRef;
	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;
	questions: any = [];
	showEmojiPickerAnswer = [];
	whatsappMessages: any = [];

	constructor(public user: UserService,
				public ref: ChangeDetectorRef,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public router: Router,
				public subHeader: SubheaderService,
				private modalService: NgbModal,
				public toolsService: ToolsService,
				private _location: Location) {
		this.load();
	}

	ngOnInit() {
		this.getNotificationsTags();
	}

	updateWhatsappMessages() {
		this.whatsappMessages = [this.user.notificacaoTemplate.conteudo];

		this.questions.map(e => {
			this.whatsappMessages.push(e.conteudo);
			e.opcoes.map(ee => this.whatsappMessages.push(ee.conteudo));
		});
	}

	/**
	 * Volta a página de notificações.
	 *
	 * @author Daniel Silva Marcelino
	 */
	public back(): void {
		this.router.navigateByUrl('/surveys');
	}

	onTest() {
		this.testingNumber = true;

		this.api.test_notification_template(this.test.number, this.user.notificacaoTemplate).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.testingNumber = false;
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
	}

	async onSave() {
		this.ktDialogService.show();
		// tslint:disable-next-line:indent

		let order = 1;
		for (const question of this.questions) {
			question.ordem = order++;

			let optionOrder = 1;
			for (const option of question.opcoes) {
				option.ordem = optionOrder++;
			}
		}

		await this.api.survey_template_save(JSON.parse(JSON.stringify({
			...this.user.notificacaoTemplate,
			...{questions: this.questions}
		}))).then(value => {
			if (value.success) {
				this.notify.show(value.message, 'success');
				this.back();
			}
		});
		this.ktDialogService.hide();
	}

	onCancel() {
		this._location.back();
	}

	toggleEmojiPicker(event = null, elementId) {
		if (!this.showEmojiPicker[elementId]) {
			const that = this;
			setTimeout(() => {
				that.showEmojiPicker[elementId] = !that.showEmojiPicker[elementId];
				that.ref.markForCheck();
			}, 50);

		}

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		return false;
	}

	toggleEmojiPickerAnswer(event = null, object) {
		if (!object.showEmojiPicker) {
			const that = this;
			setTimeout(() => {
				object.showEmojiPicker = !object.showEmojiPicker;
				if (object.showEmojiPicker) {
					this.showEmojiPickerAnswer.push(object);
				}
				that.ref.markForCheck();
			}, 50);

		}

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		return false;
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	onEmoji($event: any, elementId) {
		insertTextAtCursor(document.getElementById(elementId), $event.emoji.native);
		this.toggleEmojiPicker($event, elementId);
	}

	onTags() {
		this.modalService.open(this.modalTags);
	}

	onClick($event: any) {
		// const that = this;
		// setTimeout(() => {
		this.showEmojiPicker = [];
		// }, 50);

		// if (that.showEmojiPicker[$event.target.id]) {
		// 	setTimeout(() => {
		// 		if (that.showEmojiPicker[$event.target.id] && !that.emojiMart.nativeElement.contains($event.target)) {
		// 			that.showEmojiPicker[$event.target.id] = false;
		// 		}
		// 	}, 50);
		// } else {
		// 	this.showEmojiPicker = [];
		// }

		for (const answer of this.showEmojiPickerAnswer) {
			answer.showEmojiPicker = false;
			delete answer.showEmojiPicker;
		}
		this.showEmojiPickerAnswer = [];
	}

	onIdentifier() {
		this.modalService.open(this.modalIdentifiers);
	}

	onDrop($event) {
		// moveItemInArray(this.menus, $event.previousIndex, $event.currentIndex);
	}

	onAddQuestion() {
		this.questions.push({
			conteudo: 'Nova Pergunta',
			tipo: 'Multipla Escolha',
			ordem: 0,
			opcoes: []
		});

		this.updateWhatsappMessages();
	}

	onAddAnswer(question) {
		question.opcoes.push({
			conteudo: 'Nova Resposta'
		});
	}

	onDropAnswer($event) {

	}

	private load() {
		this.ktDialogService.show();

		this.questions = this.user.notificacaoTemplate.perguntas;
		if (!this.questions) {
			this.questions = [];
		}

		this.updateWhatsappMessages();

		this.ktDialogService.hide();
	}

	keypress(){
		return this.toolsService.keypress();
	}

	async getNotificationsTags(){
		this.tags = await this.api.get_notification_tags()
	}

	goToTags(){
		this.modalService.dismissAll();
		this.router.navigateByUrl('/edit-tags');
	}
}
