import { Injectable } from '@angular/core';
import { ProtocolList } from './protocol-list.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ProtocolListState extends EntityState<ProtocolList> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'protocol-list' })
export class ProtocolListStore extends EntityStore<ProtocolListState> {

  constructor() {
    super();
  }

}

