// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';
import {ContactsComponent} from './views/pages/contacts/contacts.component';
import {ContactComponent} from './views/pages/contact/contact.component';
import {ChatBotComponent} from './views/pages/chat-bot/chat-bot.component';
import {ChatbotAttComponent} from './views/pages/chatbot-att/chatbot-att.component';
import {CustomMenuComponent} from './views/pages/custom-menu/custom-menu.component';
import {CustomMenuAttComponent} from './views/pages/customMenu-att/customMenu-att.component';
import {ChannelComponent} from './views/pages/channel/channel.component';
import {NotificacoesComponent} from './views/pages/notificacoes/notificacoes.component';
import {PainelPrincipalComponent} from './views/pages/painel-principal/painel-principal.component';
import {EditarNotificacaoComponent} from './views/pages/editar-notificacao/editar-notificacao.component';
import {EditNotificationsTagsComponent} from './views/pages/editar-notificacao/editNotificationsTags.component';
import {DepartmentsComponent} from './views/pages/departments/departments.component';
import {TabulationsComponent} from './views/pages/tabulations/tabulations.component';
import {OperatorsComponent} from './views/pages/operators/operators.component';
import {AttendancesComponent} from './views/pages/attendances/attendances.component';
import {AttConfigComponent} from './views/pages/attConfig/attConfig.component';
import {NotifReportsComponent} from './views/pages/notifReports/notifReports.component';
import {TabulationReportsComponent} from './views/pages/tabulationReports/tabulationReports.component';
import {ApiReportsComponent} from './views/pages/apiReports/apiReports.component';
import {DashboardMainComponent} from './views/pages/dashboard-main/dashboard-main.component';
import {SurveysComponent} from './views/pages/surveys/surveys.component';
import {SurveyComponent} from './views/pages/survey/survey.component';
import {SurveyReportsComponent} from './views/pages/survey-reports/survey-reports.component';
import {SurveyReportComponent} from './views/pages/survey-report/survey-report.component';
import {GroupsComponent} from './views/pages/groups/groups.component';
import {UsersComponent} from './views/pages/users/users.component';
import {UserComponent} from './views/pages/user/user.component';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				redirectTo: 'apis'
			},
			{
				path: 'apis',
				loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'dashboard-main',
				component: DashboardMainComponent
			},
			{
				path: 'chatbot',
				component: ChatBotComponent
			},
			{
				path: 'chatbot-att',
				component: ChatbotAttComponent
			},
			{
				path: 'channel',
				component: ChannelComponent
			},
			{
				path: 'custom-menu',
				component: CustomMenuComponent
			},
			{
				path: 'customMenu-att',
				component: CustomMenuAttComponent
			},
			{
				path: 'notifications',
				component: NotificacoesComponent
			},
			{
				path: 'surveys',
				component: SurveysComponent
			},
			{
				path: 'groups',
				component: GroupsComponent
			},
			{
				path: 'survey',
				component: SurveyComponent
			},
			{
				path: 'survey-reports',
				component: SurveyReportsComponent
			},
			{
				path: 'survey-report',
				component: SurveyReportComponent
			},
			{
				path: 'main',
				component: PainelPrincipalComponent
			},
			{
				path: 'notification-template',
				component: EditarNotificacaoComponent
			},
			{
				path: 'edit-tags',
				component: EditNotificationsTagsComponent
			},
			{
				path: 'departments',
				component: DepartmentsComponent
			},
			{
				path: 'tabulations',
				component: TabulationsComponent
			},
			{
				path: 'operators',
				component: OperatorsComponent
			},
			{
				path: 'attendances',
				component: AttendancesComponent
			},
			{
				path: 'attConfig',
				component: AttConfigComponent
			},
			{
				path: 'relatorios',
				component: NotifReportsComponent
			},
			{
				path: 'tab_relatorios',
				component: TabulationReportsComponent
			},
			{
				path: 'api_relatorios',
				component: ApiReportsComponent
			},
			{
				path: 'users',
				component: UsersComponent
			}, {
				path: 'user',
				component: UserComponent
			},

			{
				path: 'contacts',
				component: ContactsComponent
			}, {
				path: 'contact',
				component: ContactComponent
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					'type': 'error-v6',
					'code': 403,
					'title': '403... Access forbidden',
					'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'dashboard-main', pathMatch: 'full'},
			{path: '**', redirectTo: 'dashboard-main', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {useHash: true})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
