import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'replace'
})
export class ReplacePipe implements PipeTransform {

	transform(str: string, from, to): any {
		return str.replace(from, to);
	}
}
