// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';
// Object-Path
import * as objectPath from 'object-path';

@Component({
	selector: 'kt-footer',
	templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	fluid: boolean;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayouConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService) {
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')){
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url.name = url
		this.isStandard()
	}

	url = {name:'',site:''}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();

		// footer width fluid
		this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
	}

	isStandard(){
		if(this.url.name=='painel' || window.location.href.includes('localhost')){
			this.url.name='Aida Digital'
			this.url.site='https://www.aida.digital'
			return true
		}else{
			this.url.name = this.url.name.toLocaleUpperCase()
		}
	}
}
