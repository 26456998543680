import {Component, Input, OnInit} from '@angular/core';
import {ToolsService} from '../../../services/tools.service';

@Component({
	selector: 'kt-chat-message',
	templateUrl: './chat-message.component.html',
	styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
	@Input() message: any;
	@Input() darkBg: any = false;
	@Input() author: any;

	constructor(public tools: ToolsService) {
	}

	ngOnInit() {
	}

}
