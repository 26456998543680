import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorService} from './error.service';
import {UserService} from './user.service';

import * as constants from '../constants';

@Injectable({
	providedIn: 'root'
})
export class ApiJsService {

	baseUrl: string = constants.BASE;
	url = ''

	constructor(public http: HttpClient,
				public error: ErrorService,
				public user: UserService) {
		let url = window.location.href
		if(!url.includes('notifiqueai') && !url.includes('gobot')) {
			url = url.replace('https://','').split('.')[1]
		}else{
			url = url.replace('https://','').split('.')[0]
		}
		this.url = url
		// this.baseUrl = "http://localhost"
	}

	auth(login, password, channel = null, token = null) {
		return this.http.post(constants.API_JSURL + 'authenticate', {login, password,channel,token}).toPromise().then().catch(this.error.handleError);
	}

	get_notification_report(page, search) { //service reports
		let params = new HttpParams();
		Object.keys(search).forEach(key => {
			params = params.append(key, search[key]);
		});

		return this.http.get(`${this.baseUrl}:5971/surveyReport/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notifications_data(page,filters={}) { //service reports
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${this.baseUrl}:5971/notificationsReport/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notifications_chart(filters={}) { //service reports
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${this.baseUrl}:5971/notificationsChart`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_api_report(page,filters={}) { //service reports
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${this.baseUrl}:5971/apiReport/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	user_save(data) {
		return this.http.post(`${constants.API_JSURL}userSave`, {data}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_all_notifications(filters) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${this.baseUrl}:5971/notificationsCSV`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}	

	getChannelConfig(channelId) {
		return this.http.get(`${this.baseUrl}:5990/channel/config/${channelId}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	updateChannelConfigMongo(channel) {
		return this.http.put(`${this.baseUrl}:5990/channel/config`, channel, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	updateChannelMongo(channel){
		return this.http.put(`${this.baseUrl}:5990/channel`, channel, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}
}
