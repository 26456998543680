import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {ApiJsService} from '../../../services/apiJS.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifyService} from '../../../services/notify.service';
import {UserService} from '../../../services/user.service';
import * as constants from '../../../constants'

@Component({
	selector: 'kt-survey-report',
	templateUrl: './survey-report.component.html',
	styleUrls: ['./survey-report.component.scss']
})
export class SurveyReportComponent implements OnInit {
	data: any = {
		charts: [],
	};

	constants = constants

	hasSurvey = false

	surveyStatus: any[] = [
		{value: '0', viewValue: 'Concluida'},
		{value: '1', viewValue: 'Em Andamento'},
		{value: '2', viewValue: 'Aguardando'},
		{value: '3', viewValue: 'Descartada'},
	];
	notifStatus: any[] = [
		{value: '0', viewValue: 'Enviada'},
		{value: '1', viewValue: 'Visualizada'},
		// {value: '2', viewValue: 'Respondida'},
	];
	gender: any[] = [
		{value: '0', viewValue: 'Masculino'},
		{value: '1', viewValue: 'Feminino'},
		{value: '2', viewValue: 'Não identificado'},
	];
	surveyQuestions: any[] = [];

	reportResults = {
		page: 1,
		total: 0,
		items: []
	};

	notificationType: string = '';

	search = {
		type: '',
		start_date: '',
		end_date: '',
		date: '',
		notificationId: '',
		check: '',
		surveyStatus: '',
		surveyQuestions: '',
		gender: '',
		keyword: '',
		notificationType: '',
	};
	perPage: 10;
	obsPrintText: '';
	private notificationId: any = null;
	private activeNotification: any = null;
	@ViewChild('modalViewNotification', {static: false}) modalViewNotification: ElementRef;
	contacts: any = [];

	constructor(private route: ActivatedRoute,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public apiJS: ApiJsService,
				public ref: ChangeDetectorRef,
				private modalService: NgbModal,
				public subHeader: SubheaderService,
				public notify: NotifyService,
				public user: UserService,
				) {
		this.route.queryParams.subscribe(params => {
			if (params.notificationId) {
				if(params.type) {
					this.notificationType = params.type
					this.search.notificationType = params.type
				}
				this.load(params.notificationId);
			}
		});
	}

	ngOnInit() {
	}

	doFilter() {
		this.load(this.notificationId);
	}

	onViewReportItem(item) {
		this.activeNotification = item;

		// this.activeNotification = notification;
		this.activeNotification.messages = [...[this.activeNotification], ...this.activeNotification.mensagens_recebidas, ...this.activeNotification.mensagens_enviadas];

		this.activeNotification.messages.sort((a, b) => {
			return a.criado_em < b.criado_em ? -1 : 1;
		});

		this.modalService.open(this.modalViewNotification, {size: 'sm'});
		this.api.mark_as_read(this.activeNotification);
	}

	getReportItems($event: any) {
		this.reportResults.page = $event;
		this.loadReportItems();
	}

	private async loadReportItems() {
		// this.ktDialogService.show();
		this.search.notificationId = this.notificationId;
		const result = await this.apiJS.get_notification_report(this.reportResults.page, this.search); // independent service
		result.charts.map(d =>{
			d.options.labels = d.options.labels.map(label => {
				if(!label) {
					label = 'Não identificado'
				}
				return label
			})
			if(d.title=='Região') this.relevantData(d.options)	
			else d.options.series = d.options.series.map(serie => parseFloat(serie))
		})
		result.surveyCharts.map(d =>{
			d.options.series = d.options.series.map(serie => parseFloat(serie)),
			this.order(d.options)
		});
		
		this.reportResults.items = result.messages
		this.reportResults.total = result.messagesTotal
		this.data.charts = result.charts
		this.data.survey_charts = result.surveyCharts
		// this.contacts = this.reportResults.items.filter((v, i, a) => a.findIndex(t => (t.destinatario === v.destinatario)) === i); // Unique contacts
		// this.ktDialogService.hide();
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}

	// private async loadReportItems() {
	// 	this.ktDialogService.show();
	// 	this.search.notificationId = this.notificationId;
	// 	const result = await this.api.get_notifications(this.reportResults.page, this.search);
	// 	this.reportResults.items = result.notifications;
	// 	this.reportResults.total = result.total;
	// 	// this.contacts = this.reportResults.items.filter((v, i, a) => a.findIndex(t => (t.destinatario === v.destinatario)) === i); // Unique contacts
	// 	this.ktDialogService.hide();
	// 	if (!this.ref['destroyed']) {
	// 		this.ref.detectChanges();
	// 	}
	// }

	onPrint() {
		//window.print();
		let obsPrint = '';
		let stylesHtml = '';

		const printContent = document.getElementById('to-print');
		const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');

		let f = document.querySelectorAll('link[rel="stylesheet"], style');
		//console.log(f[0])
		f.forEach(el => {
			stylesHtml += el.outerHTML;
		});
		WindowPrt.document.write(`<!DOCTYPE html>
			<html>
			<head>
				${stylesHtml}
			</head>
			<body>
				${printContent.innerHTML}
				<p>${obsPrint}</p>
				<style>
				 .noprint{display:none !important}
				 .showPrint{

					display:block !important;
				}
				</style>
			</body>
			</html>`);
		//WindowPrt.document.write(`<style>${JSON.stringify(style)}</style>`);
		WindowPrt.document.close();
		WindowPrt.focus();
		WindowPrt.print();

	}

	togglePrint(e) {
		if (e.target.classList.contains('text-success')) {
			e.target.classList.remove('text-success');
			e.target.classList.add('text-danger');
			e.target.parentElement.parentElement.parentElement.parentElement.classList.remove('noprint');
		} else {
			e.target.classList.add('text-success');
			e.target.classList.remove('text-danger');
			e.target.parentElement.parentElement.parentElement.parentElement.classList.add('noprint');
		}
	}

	addClass(e) {
		e.target.parentElement.parentElement.parentElement.classList.add('noprintBorder');
	}

	removeClass(e) {
		e.target.parentElement.parentElement.parentElement.classList.remove('noprintBorder');
	}

	getTextAreaText(e) {
		this.obsPrintText = e.target.value;
	}


	private async load(notificationId) {
		this.ktDialogService.show();
		this.notificationId = notificationId;
		if(
			this.user.getUser().permissoes && this.user.getUser().permissoes['Relatórios'] == '1' ||
			this.user.getUser().enable_surveys == 1
		)
		{
			this.hasSurvey = true
		}
		// this.surveyQuestions = await this.api.get_survey_questions(notificationId);
		// const data = await this.api.get_notification_report(notificationId, this.search);
		// // const data = await this.apiJS.get_notification_report(notificationId, this.search);
		// data.charts.map(d =>{
		// 	d.options.labels = d.options.labels.map(label => {
		// 		if(!label) {
		// 			label = 'Não identificado'
		// 		}
		// 		return label
		// 	})
		// 	if(d.title=='Região') this.relevantData(d.options)	
		// 	else d.options.series = d.options.series.map(serie => parseFloat(serie))
		// })

		
		// data.survey_charts.map(d =>{
		// 	d.options.series = d.options.series.map(serie => parseFloat(serie)),
		// 	this.order(d.options)
		// });
		
		// // data.charts = undefined
		// this.data = data;
		// console.log('data',this.data)

		// this.subHeader.setTitle('Relatórios - ' + (this.data as any).survey.nome);
		await this.loadReportItems();
		this.ktDialogService.hide();
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}

	order(options) {
		let labels = ['Ótimo','Bom','Regular','Ruim','Péssimo']
		if(!options.labels.includes(labels[0])) return
		let series = [0,0,0,0,0]
		for(var i=0;i<=4;i++) {
			if(options.labels[i]=='Ótimo') {
				labels[0]=options.labels[i]
				series[0]=options.series[i]
			}
			else if(options.labels[i]=='Bom') {
				labels[1]=options.labels[i]
				series[1]=options.series[i]
			}
			else if(options.labels[i]=='Regular') {
				labels[2]=options.labels[i]
				series[2]=options.series[i]
			}
			else if(options.labels[i]=='Ruim') {
				labels[3]=options.labels[i]
				series[3]=options.series[i]
			}
			else if(options.labels[i]=='Péssimo') {
				labels[4]=options.labels[i]
				series[4]=options.series[i]
			}
		}
		options.labels = labels
		options.series = series
	}

	relevantData(options) {
		let labels = []
		let series = []
		for(var i=0;i<options.series.length;i++) {
			if(options.series[i]) { // if>3
				labels.push(options.labels[i])
				series.push(options.series[i])
			}
		}
		options.series = series
		options.labels = labels
	}

	onChangeSearchType() {
		this.search.date = '';
		this.search.start_date = '';
		this.search.end_date = '';
	}

	trackNotificationItem(index: number, item) {
		return item.id;
	}

	async onViewNotification(notification: any) {
		this.ktDialogService.show();
		if(this.user.getUser().enable_surveys) {
		// if(['121','143'].includes(this.user.getUser().instance_id)) {
			this.activeNotification = await this.api.get_notification(notification.id);
			this.activeNotification.messages = [...this.activeNotification.mensagens_recebidas, ...this.activeNotification.mensagens_enviadas];
		} else {
			this.activeNotification = await this.api.get_notification_msgs(notification.id);
			this.activeNotification.messages = [notification, ...this.activeNotification.received_msg]
		}

		this.activeNotification.messages.sort((a, b) => {
			const aTimestamp = Date.parse(a.criado_em);
			const bTimestamp = Date.parse(b.criado_em);

			return aTimestamp < bTimestamp ? -1 : 1;
		});

		this.ktDialogService.hide();

		this.modalService.open(this.modalViewNotification, {size: 'sm'});
		this.api.mark_as_read(this.activeNotification);
		const el = document.querySelector('#scrollMe');
		setTimeout(function() {
			el.scrollTop = el.scrollHeight - el.clientHeight;
		}, 1000);
	}

	convertToCSV(arr) {
		return arr.map(function(d) {
			return JSON.stringify(d);
		})
		.join('\n') 
		.replace(/(^\[)|(\]$)/mg, '')
	}

	convertToCSV2(arr) {
		const array = [Object.keys(arr)].concat([Object.values(arr)])
		return Object.values(array).join('\n')
	}

	convertToCSV3(objArray) {
		const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
		let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';
   
		return array.reduce((str, next) => {
			str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
			return str;
		}, str);
	  }

	async exportCSV() {
		// this.ktDialogService.show();
		this.notify.show('As notificações estão sendo carregadas.\nPor favor, mantenha essa página aberta', 'success');
		if(!this.search.notificationId) {
			this.search.notificationId = this.notificationId
		}
		let notifications = await this.apiJS.get_all_notifications(this.search)
		if(notifications.success) {
			notifications = notifications.arrayCSV
		}
		let model2 = {}

		if(this.search.type || this.search.check) {
			model2 = {
				filtro: this.search.type,
				filtro_tipo: this.search.check,
				data_inicial: this.search.start_date,
				data_final: this.search.end_date,
				dia: this.search.date,
				status: this.search.surveyStatus,
				genero: this.search.gender,
				palavra_chave: this.search.keyword,
			}
		}

		let csv = ''

		function isEmpty(obj) { 
			for (var x in obj) { return false; }
			return true;
		}

		if(!isEmpty(model2)) {
			csv = this.convertToCSV2(model2)+'\n\n'+this.convertToCSV(notifications)
		} else {
			csv = this.convertToCSV(notifications)
		}
	
		var hiddenElement = document.createElement('a');  
		hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);  
		hiddenElement.target = '_blank';  
		let date = new Date();		
		hiddenElement.download = `${this.notificationType == 'Notificação' ? 'Notificacao' : 'pesquisa'}${this.notificationId}-${date.getTime()}.csv`;  
		hiddenElement.click();
		// this.ktDialogService.hide();  
	}

	filterNotif(charts) {
		if(this.notificationType == 'Notificação') {
			return charts.filter(el => ['Gênero','Região'].includes(el.title) )
		}
		return charts.filter(el => el.title != 'Notificação')
	}

	changeChart(id,event) {
		switch(event.value) {
			case 1:
				this.data.survey_charts[id].options = this.data.survey_charts[id].optionsPie
				break
			case 2:
				this.data.survey_charts[id].options = this.data.survey_charts[id].optionsBar
				break
			default:
				break
		}
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}

	generateMessageCSV() {
		let aux = []
		for(let message of this.activeNotification.messages) {
			let obj = { nome_contato: this.activeNotification.contato.nome, telefone_contato: this.activeNotification.contato.whatsapp, mensagem: message.conteudo, enviada_em: message.enviado_em, lida_em: message.lida_em, remetente: message.enviado_em ? 'Canal' : 'Contato' }
			aux.push(obj)
		}
		let csv = this.convertToCSV3(aux)

		var hiddenElement = document.createElement('a');  
		hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);  
		hiddenElement.target = '_blank';  
		let date = new Date();		
		hiddenElement.download = `${this.notificationType == 'Notificação' ? 'Notificacao' : 'Pesquisa'}${this.notificationId}-${date.getTime()}.csv`;  
		hiddenElement.click();
	}
}
