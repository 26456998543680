import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {HttpClient} from '@angular/common/http';
import {ContactListStore} from './contact-list.store';
import {ContactList} from './contact-list.model';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ContactListService {

	constructor(private contactListStore: ContactListStore,
				private http: HttpClient) {
	}

	get() {
		return this.http.get<ContactList[]>('https://api.com').pipe(tap(entities => {
			this.contactListStore.set(entities);
		}));
	}

	add(contactList) {
		if (Array.isArray(contactList)) {
			for (const item of contactList) {
				item.forward_message = false;
			}
		}

		this.contactListStore.add(contactList);
	}

	update(id, contactList: Partial<ContactList>) {
		this.contactListStore.update(id, contactList);
	}

	remove(id: ID) {
		this.contactListStore.remove(id);
	}

	updateContactLastActivity(contato_id: any) {
		this.contactListStore.update(contato_id, list => {
			return {
				...list,
				last_activity: Date.now()
			};
		});
	}

	select(id) {
		this.contactListStore.setActive(id);
	}

	clear() {
		this.contactListStore.destroy();
	}
}
