import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService, SubheaderService} from '../../../core/_base/layout';
import {ApiService} from '../../../services/api.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import {ToolsService} from '../../../services/tools.service';
import { SocketService } from '../../../services/socket.service';

@Component({
	selector: 'kt-notificacoes',
	templateUrl: './notificacoes.component.html',
	styleUrls: ['./notificacoes.component.scss']
})
export class NotificacoesComponent implements OnInit {
	notificacoesTemplates = [];

	@ViewChild('modalDelete', {static: false}) modalDelete: ElementRef;
	@ViewChild('modalSend', {static: false}) modalSend: ElementRef;
	@ViewChild('modalUploadSheet', {static: false}) modalUploadSheet: ElementRef;
	private activeObject: any = null;

	showStatus: any = false;
	showToggle: any = true;
	showEdit: any = true;
	showUploadSheet: any = false;
	showSend: any = false;
	test_number: any = '';
	testingNumber = false;

	readingFile = false;
	arrayBuffer = null;
	sheetData = null;
	uploadingSheet = false;
	departments: any = [];

	constructor(public subHeader: SubheaderService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public user: UserService,
				public toolsService: ToolsService,
				private modalService: NgbModal,
				private socketService: SocketService,
				) {
		this.subHeader.setTitle('Notificações');

		this.load();

		const isPositus = this.user.getUser().modulos.find(e => e.modulo.nome === 'Positus') && this.user.getUser().modulos.find(e => e.modulo.nome === 'Positus').modulo_id || false;
		this.showEdit = !isPositus;
		this.showToggle = !isPositus;
		this.showSend = isPositus || this.user.getUser().enable_manual_notification == 1 || this.user.getUser().permissoes;
		this.showStatus = isPositus;
		this.showUploadSheet = this.user.getUser().enable_batch_notifications == 1;
	}

	ngOnInit() {
		// if(this.user.getUser().enable_operators){
		// 	this.getDepartments()
		// }
		this.socketService.onSendTemplate().subscribe((data:any) => {
			console.log('sendNotifResp',data)
			this.testingNumber = false;
			this.notify.show(data.message, data.success ? 'success' : 'danger');
		})
		// this.socketService.connectionErrorSckt2();
	}

	private async load() {
		this.ktDialogService.show();
		let templates = await this.api.get_notifications_templates()
		let promise = templates.map(el => {
			if(el.buttons)
				el.buttons = JSON.parse(el.buttons)

			if(el.hasImage == '1')
				el.hasImage = true
			else
				el.hasImage = false

			if(el.hasButtons == '1')
				el.hasButtons = true
			else
				el.hasButtons = false

			return el
		})
		await Promise.all(promise)
		this.notificacoesTemplates = templates;
		// console.log('templates',this.notificacoesTemplates)
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
		this.ktDialogService.hide();
	}

	onEdit(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.router.navigateByUrl('/notification-template');
	}

	onUploadSheet(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.modalService.open(this.modalUploadSheet);
	}

	onCreate() {
		this.user.notificacaoTemplate = {
			nome: '',
			icone: '',
			identificador: '',
			conteudo: '',
			modulo_id: this.user.getUser().modulos[0] && this.user.getUser().modulos[0].modulo_id || 0
			// modulo_id: this.user.getUser().modulos.find(e => e.modulo.nome === 'Tray').modulo_id
		};
		this.router.navigateByUrl('/notification-template');
	}

	async onToggleNotificationTemplate(notificacaoTemplate: any) {
		notificacaoTemplate.habilitada = notificacaoTemplate.habilitada == 1 ? 0 : 1;

		this.ktDialogService.show();
		// tslint:disable-next-line:indent
		await this.api.notification_template_save(JSON.parse(JSON.stringify(notificacaoTemplate)));
		this.ktDialogService.hide();
	}

	onDelete(notificacaoTemplate: any) {
		this.activeObject = notificacaoTemplate;
		this.modalService.open(this.modalDelete);
	}

	async doDelete() {
		this.modalService.dismissAll();

		this.ktDialogService.show();
		this.api.notification_template_delete(this.activeObject.id).then(value => {
			if (!value.success) {
				this.notify.show(value.message, 'danger');
			} else {
				this.notify.show(value.message, 'success');
				this.load();
			}
		}).catch(reason => {
			console.log(reason);
			this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
		}).finally(() => {
			this.activeObject = null;
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
		this.ktDialogService.hide();
	}

	doSend() {
		this.testingNumber = true;
		this.user.notificacaoTemplate.send = true
		// if(this.user.getUser().server=='10'){
			this.socketService.sendTemplate(this.test_number,this.user.notificacaoTemplate.id)
		// }else{
			// this.api.test_notification_template(this.test_number, this.user.notificacaoTemplate).then(value => {
			// 	if (!value.success) {
			// 		this.notify.show(value.message, 'danger');
			// 	} else {
			// 		this.notify.show(value.message, 'success');
			// 	}
			// }).catch(reason => {
			// 	console.log(reason);
			// 	this.notify.show('Não foi possível enviar sua requisição, por favor revise as informações e tente novamente.', 'danger');
			// }).finally(() => {
			// 	this.testingNumber = false;
			// 	if (!this.ref['destroyed']){
			// 		this.ref.detectChanges();
			// 	}
			// });
		// }
		this.user.notificacaoTemplate.send = false
	}

	onSend(notificacaoTemplate: any) {
		this.user.notificacaoTemplate = notificacaoTemplate;
		this.modalService.open(this.modalSend);
	}

	incomingfile($event) {
		this.readingFile = true;

		const fileReader = new FileReader();
		fileReader.onload = (e) => {
			this.arrayBuffer = fileReader.result;
			const data = new Uint8Array(this.arrayBuffer);
			const arr = [];
			for (let i = 0; i !== data.length; ++i) {
				arr[i] = String.fromCharCode(data[i]);
			}
			const bstr = arr.join('');
			const workbook = XLSX.read(bstr, {type: 'binary', cellText:false, cellDates:true});
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];

			var jsonData = XLSX.utils.sheet_to_json(worksheet, {raw: false, dateNF:'dd/mm/yyyy', rawNumbers: true});
			jsonData = jsonData.map((el)=>{
				el = (<any>Object).fromEntries(
					Object.entries(el).map(([key, value]) => 
						[key.trim(), value]
					)
				)
				return el
			})
			console.log('sheetData: ',jsonData);

			this.sheetData = jsonData;

			this.readingFile = false;
			this.ref.markForCheck();
		};
		fileReader.readAsArrayBuffer($event.target.files[0]);
	}

	async doSendSheet() {
		this.uploadingSheet = true;
		this.user.notificacaoTemplate.send = true
		let count = {success: 0, failed: 0};
		this.notify.show(`Processo de envio iniciado!`, 'success');
		this.modalService.dismissAll();

		// **PHP

		// let result = await this.api.send_sheet(this.user.notificacaoTemplate,this.sheetData)
		// if(!result.success && !result.failed){
		// 	this.notify.show(result.message, 'danger');
		// }else{
		// 	count = {success: result.success, failed: result.failed};
		// 	this.notify.show(`Processo concluído! Mensagens adicionadas na fila: ${count.success} / Mensagens com falha: ${count.failed}.`, 'success');
		// }
		
		// **NodeJS

		let result = await this.api.send_sheet2(this.user.notificacaoTemplate.id,this.sheetData)
		// console.log('result',result)
		this.notify.show(result.message, result.success ? 'success' :'danger');
		
		
		this.uploadingSheet = false;
		this.user.notificacaoTemplate.send = false;
		this.sheetData = null;
	}

	private parseSheetContent(conteudo, data) {
		let res = conteudo;

		for (const key in data) {
			res = res.replaceAll(`[${key}]`, data[key]);
		}

		return res;
	}

	editTags(){
		this.router.navigateByUrl('/editNotificationsTags');
	}

	keypress(){
		return this.toolsService.keypress();
	}

	getDepartments(){
		this.socketService.getDepartments(this.user.getUser().instance_id);
	}

	getDepName(id){
		if(id){
			let dep = this.departments.find(el=>el._id==id)
			return dep.name
		}
	}

	ngOnDestroy() {
		this.socketService.off()
	}
}
