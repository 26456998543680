import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';

@Component({
  selector: 'kt-tabulations',
  templateUrl: './tabulations.component.html',
  styleUrls: ['./tabulations.component.scss']
})
export class TabulationsComponent implements OnInit {

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    private socketService: SocketService,
    public subHeader: SubheaderService,
    public ref: ChangeDetectorRef,
  ) { 
    this.subHeader.setTitle('Gerenciar - Tabulações');
  }

  @ViewChild('modalNew', {static: false}) modalNew: ElementRef;
  @ViewChild('modalEdit', {static: false}) modalEdit: ElementRef;
  @ViewChild('modalFilter', {static: false}) modalFilter: ElementRef;
  @ViewChild('modalDelete', {static: false}) modalDelete: ElementRef;
  @ViewChild('modalDeleteArray', {static: false}) modalDeleteArray: ElementRef;

  tabulations:  any = []
  tabArray: any = []
  tab: any = {}
  old: any = {}
  selectBox: any = ''
  search: any = {text:''}
  checked: any = []

  ngOnInit() {
    this.getTabulations()
    this.socketService.onSetTabulations().subscribe(data => {
			this.tabulations = data
		});
    this.socketService.onNewTabulation().subscribe(data => {
      console.log('new',data)
      this.tabulations.push(data)
      if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
  }

  selectTab(item){
    this.tab = item;
  }

  openModalNew(item = null){
    if(item){
      this.tab = item
    }
    this.modalService.open(this.modalNew, {size: 'sm'});
  }
  openModalFilter(){
    this.modalService.open(this.modalFilter, {size: 'sm'});
  }
  openModalEdit(item = null){
    if(item){
      this.tab = item
      this.old = item
    }
    this.modalService.open(this.modalEdit, {size: 'sm'});
  }
  openModalDelete(item = null){
    if(item){
      this.tab = item
    }
    this.modalService.open(this.modalDelete, {size: 'sm'});
  }
  openModalDeleteArray(){
    if (this.tabulations.some(val => val.checked == true)){
      this.modalService.open(this.modalDeleteArray, {size: 'sm'});
    }else{
      this.notify.show(`Nenhuma Tabulação selecionada!`, 'danger');
    }
  }

  getTabulations(){
    this.socketService.getTabulations(this.user.getUser().instance_id);
  }

  saveTabulation(edit=false){
    if(!this.tab){
      return this.notify.show(`Campo Texto Vazio!`, 'danger');
    }
    if(edit){
      let index = this.tabulations.indexOf(this.old)
      this.tabulations[index] = this.tab
    }
    this.socketService.updateTabulation(this.tab,this.user.getUser().instance_id);
    
    this.dismissModal()
    this.notify.show(`Tabulação salva com sucesso!`, 'success');
  }

  deleteTabulation(tab = null){
    let show = false
    if(!tab){
      tab = this.tab
      show = true
    }
    let index = this.tabulations.indexOf(tab)
    this.socketService.deleteTabulation(tab,this.user.getUser().instance_id);
    this.tabulations.splice(index, 1)
    // this.tabulations = this.tabulations.filter(el=>el!=this.tab)
    if(show){
      this.dismissModal()
      this.notify.show(`Tabulação removida com sucesso!`, 'success');
    }
  }

  deleteSelected(){
    let tabs = this.tabulations.filter(el => el.checked == true)
    tabs.forEach(el=> {
      this.deleteTabulation(el)
    });
    this.checked = []
    this.notify.show(`${tabs.length} tabulações removidas com sucesso!`, 'success');
  }

  dismissModal(){
		this.modalService.dismissAll();
    this.tab = {}
    this.old = {}
	}

  checkAll() {
		if(this.tabulations.length){
			if (this.tabulations.every(val => val.checked == true))
				this.tabulations.forEach(val => { val.checked = false });
			else
				this.tabulations.forEach(val => { val.checked = true });
		}
	}

  boxSelected(){
    switch(this.selectBox){
      case 'select':
        this.checkAll()
        break
      case 'delete':
        this.deleteSelected()
        break
    }
    this.selectBox = ''
  }

  searchTab(array){
    if(this.search){
      return array.filter(el=>el.text.toLowerCase().includes(this.search.text.toLowerCase()))
    }
    return array
  }

  controlChecked(e,item){
    if(e.checked){
      this.checked.push(item)
    }else{
      this.checked = this.checked.filter(el=>el!=item)
    }
    this.ref.detectChanges()
  }
}
