// Angular
import {ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
// Lodash
// Services
// Widgets model
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {GlobalService} from '../../../services/global.service';
import {SocketOldService} from '../../../services/socket-old.service';
import {Observable} from 'rxjs';
import {ProtocolList} from '../../../state/protocol-list.model';
import {ProtocolListService} from '../../../state/protocol-list.service';
import {ProtocolListQuery} from '../../../state/protocol-list.query';
import {ToolsService} from '../../../services/tools.service';
import {NgbDropdownMenu, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactList} from '../../../state/contact-list.model';
import {UserService} from '../../../services/user.service';
import {take} from 'rxjs/operators';
import {AudioRecorderService, RecordingState} from '../../../services/audio-recorder.service';
import {ContactListQuery} from '../../../state/contact-list.query';
import {BASEURL} from '../../../constants';
import {MapsAPILoader} from '@agm/core';
import {NotificationService} from '../../../services/notification.service';
import {SettingsService} from '../../../services/settings.service';
import {UzapService} from '../../../services/uzap.service';
import {Lightbox} from 'ngx-lightbox';
import {NotifyService} from '../../../services/notify.service';
import {ContactListStore} from '../../../state/contact-list.store';
import {PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {ApiService} from '../../../services/api.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'kt-groups',
	templateUrl: './groups.component.html',
	styleUrls: ['./groups.component.scss'],
	host: {
		'(window:paste)': 'handlePaste( $event )',
		'(document:mousedown)': 'onClick($event)'
	}
})
export class GroupsComponent implements OnInit {
	private updatingScroll = false;
	private groups = [];
	private allGroups = [];
	private messages = [];

	private autoRefreshEvent = null;
	private groupsAutoRefreshEvent = null;
	private isLoading: boolean = false;
	viewingUsersList: boolean = false;
	private mentioningContacts: any = [];

	constructor(private layoutConfigService: LayoutConfigService,
				public global: GlobalService,
				public socket: SocketOldService,
				public protocolListService: ProtocolListService,
				public contactListStore: ContactListStore,
				public protocolListQuery: ProtocolListQuery,
				public contactListQuery: ContactListQuery,
				public tools: ToolsService,
				private modalService: NgbModal,
				public user: UserService,
				public audioRecorder: AudioRecorderService,
				public ngZone: NgZone,
				public ref: ChangeDetectorRef,
				private mapsAPILoader: MapsAPILoader,
				public notification: NotificationService,
				public settings: SettingsService,
				public uzap: UzapService,
				public lightbox: Lightbox,
				public notify: NotifyService,
				public subHeader: SubheaderService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public sanitizer: DomSanitizer) {
		document.body.classList.add('kt-aside--minimize');
		this.baseUrl = BASEURL;

		this.subHeader.setTitle('Grupos de Contatos');

		this.socket.connect();
	}

	data: any = {
		typing_message: '',
		search: '',
		groupSearch: '',
		searchForwardMessage: '',
		closeActiveProtocol: {
			message: 'O seu atendimento foi finalizado.',
			send: true
		},
		contact_name: '',
		automaticAnwer: {
			title: ''
		}
	};

	protocols$: Observable<ProtocolList[]>;
	attendanceProtocols$: Observable<ProtocolList[]>;
	waitingProtocols$: Observable<ProtocolList[]>;
	activeProtocols$: Observable<ProtocolList[]>;
	activeProtocol$: Observable<ProtocolList>;
	activeContact$: Observable<ContactList>;
	activeSelfServiceProtocols$: Observable<ProtocolList[]>;
	resultProtocols: any = [];

	contacts$: Observable<ContactList[]>;
	contactsCount: any = 0;

	resultProtocolContacts: any = [];
	resultProtocolLocations: any = [];
	resultProtocolFiles: any = [];
	resultProtocolAudios: any = [];
	resultProtocolVideos: any = [];
	resultProtocolImages: any = [];

	resultProtocolObservations: any = {current: []};

	automaticAnswers$: any = null;

	waitingAttendanceCount: any;
	finishedAttendanceCount: any;
	abandonAttendanceCount: any;
	openAttendanceCount: any;
	dataImage: any = {active: null, images: []};
	viewingSelfService: any = false;
	viewingAttendanceSearch: any = false;
	selfServiceCount: any = {
		waiting: 0,
		finished: 0,
		abandon: 0,
		attending: 0
	};

	advancedSearch: any = {protocolFilter: null, message: '', contact: null, protocols: []};
	receivedErrands: any = {errandsFilter: null, errandsFilterOption: null};
	callsRegister: any = {filter: null};
	newScheduling: any = {category: null, message: null};
	showEmojiPicker: any = false;
	replyingMessage: any = null;
	baseUrl: any;

	showSender: any = false;
	senderType: any = null;
	senderTypeAccept: any = '*/*';
	dataMap: any = {zoom: 15, latitude: null, longitude: null};

	@ViewChild('locationSearch', {static: false}) searchElementRef: ElementRef;
	private geoCoder: google.maps.Geocoder;

	private chatPage: any = 1;

	activeAttendanceWidget: any = null;

	private lastObjectUrl: string;
	public showActiveProtocolContact: any = false;
	public dataActiveCommunication: any = {name: null, whatsapp: null, message: null};
	public dataAutomaticAnswers: any = {message: null};
	private forwardMessageContactList: any = [];
	contactSearchLoading: any = false;

	contacts: any = [];
	searchResultProtocols: any = [];

	forwardProtocol: any = {department: null, operator: null, observation: null};
	departments: any = [];
	operators: any = [];
	automaticAnswers: any = [];
	activeAutomaticAnswer: any;

	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;
	@ViewChild('searchInput', {read: ElementRef, static: false}) searchInput: ElementRef;
	@ViewChild('typeMessageInput', {read: ElementRef, static: false}) typeMessageInput: ElementRef;
	@ViewChild('searchInputForwardMessage', {read: ElementRef, static: false}) searchInputForwardMessage: ElementRef;
	@ViewChild('chatMessagesScroll', {static: false}) chatMessagesScroll: ElementRef;
	@ViewChild('modalNotificationSettings', {static: false}) modalNotificationSettings: ElementRef;
	@ViewChild('modalEditAutomaticAnswer', {static: false}) modalEditAutomaticAnswer: ElementRef;
	@ViewChild('modalRemoveAutomaticAnswer', {static: false}) modalRemoveAutomaticAnswer: ElementRef;
	@ViewChild('tabId1Link', {static: false}) tabAttendance: ElementRef;
	// @ViewChild('scrollMe', {read: PerfectScrollbarComponent, static: false}) chatScroll: ElementRef;
	// @ViewChild(PerfectScrollbarComponent, {static: false}) chatScroll?: PerfectScrollbarComponent;
	@ViewChild('scrollMe', {read: PerfectScrollbarDirective, static: false}) chatScroll?: PerfectScrollbarDirective;
	@ViewChild('automaticAnswersDropdown', {read: NgbDropdownMenu, static: false}) automaticAnswersDropdown?: NgbDropdownMenu;

	// openContactSender() {
	//     this.showSender = true;
	//     this.senderType = 'contact';
	// }

	// sendContact(contact) {
	//     this.showSender = false;
	//     this.socket.sendContact(contact.whatsapp, this.protocolListQuery.getActiveId());
	// }
	automaticAnswersCountSelf: any = 0;
	automaticAnswersCountAll: any = 0;
	signMessages: any = false;
	filterOperator: any = '';
	totalNotifications: any = 0;

	ngOnDestroy() {
		this.cleanAutoRefresh();
	}

	ngOnInit(): void {
		this.global.activeGroup = null;
		this.loadData().then(_ => {
			this.setupAutoRefresh();
			this.setupGroupsAutoRefresh();
		});

		this.activeProtocols$ = this.protocolListQuery.selectAll({
			filterBy: entity => entity.ativo,
			sortBy: (a, b) => {
				let aLastActivity = a.createdAt || 0;
				if (typeof (aLastActivity) === 'string') {
					aLastActivity = Date.parse(aLastActivity);
				}

				let bLastActivity = b.createdAt || 0;
				if (typeof (bLastActivity) === 'string') {
					bLastActivity = Date.parse(bLastActivity);
				}

				return aLastActivity - bLastActivity;
			}
		});

		this.protocolListQuery.selectAll({
			filterBy: entity => entity.ativo
		}).subscribe(value => {
			if (this.chatScroll) {
				this.updatingScroll = true;
				this.chatScroll.update();
				// this.chatScroll.scrollToBottom();
				setTimeout((that) => {
					that.updatingScroll = false;
				}, 150, this);
			}
		});

		this.protocols$ = this.protocolListQuery.selectAll();
		this.contacts$ = this.contactListQuery.selectAll({
			sortBy: (a, b) => {
				let aLastActivity = a.last_activity || 0;
				if (typeof (aLastActivity) === 'string') {
					aLastActivity = Date.parse(aLastActivity);
				}

				let bLastActivity = b.last_activity || 0;
				if (typeof (bLastActivity) === 'string') {
					bLastActivity = Date.parse(bLastActivity);
				}

				return bLastActivity - aLastActivity;

				// if ((!a.last_activity && b.last_activity) || a.last_activity < b.last_activity) {
				//     return 1;
				// }
				//
				// if ((a.last_activity && !b.last_activity) || a.last_activity > b.last_activity) {
				//     return -1;
				// }
				//
				// return 0;
			}
		});
		this.attendanceProtocols$ = this.protocolListQuery.selectAll({
			// filterBy: entity => (entity.usuario_id === this.user.getUser().id && entity.status === 'Atendendo'),
			filterBy: entity => (entity.status === 'Atendendo'),
			sortBy: (a, b) => {
				if ((!a.last_message && b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp < b.last_message.timestamp)) {
					return 1;
				}

				if ((a.last_message && !b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp > b.last_message.timestamp)) {
					return -1;
				}

				return 0;
			}
		});

		this.waitingProtocols$ = this.protocolListQuery.selectAll({
			filterBy: entity => (entity.status === 'Em Espera')
		});
		this.activeProtocol$ = this.protocolListQuery.selectActive() as any;
		this.activeContact$ = this.contactListQuery.selectActive() as any;
		this.contactsCount = this.contactListQuery.selectCount();
		this.waitingAttendanceCount = this.protocolListQuery.selectCount(entity => entity.status === 'Em Espera');
		this.finishedAttendanceCount = this.protocolListQuery.selectCount(entity => entity.status === 'Concluido');
		this.abandonAttendanceCount = this.protocolListQuery.selectCount(entity => entity.status === 'Abandono');
		this.openAttendanceCount = this.protocolListQuery.selectCount(entity => entity.status === 'Aberto');
	}

	async sendMessage($event?) {
		console.log($event);

		if (!$event) {
			// if (this.replyingMessage) {
			// 	this.socket.replyMessage(this.replyingMessage, this.data.typing_message, this.protocolListQuery.getActiveId());
			// 	this.replyingMessage = null;
			// } else {
			// this.ktDialogService.show();
			this.api.send_message(this.global.activeGroup.api_id, this.data.typing_message, null, this.replyingMessage ? this.replyingMessage.api_id : null, this.mentioningContacts);
			// this.activeProtocol$.pipe(take(1)).subscribe(value => {
			// 	this.socket.sendMessage(this.getMessageAffix() + this.data.typing_message, value.id, value.contato_id);
			// });
			// }

			this.data.typing_message = '';
			this.showEmojiPicker = false;
			this.replyingMessage = null;
			this.mentioningContacts = [];
		} else if ($event.keyCode === 13) {
			if (this.data.typing_message) {
				if ($event.shiftKey) {
					this.data.typing_message += '\n';
				} else {
					this.api.send_message(this.global.activeGroup.api_id, this.data.typing_message, null, this.replyingMessage ? this.replyingMessage.api_id : null, this.mentioningContacts);

					this.data.typing_message = '';
					this.replyingMessage = null;
					$event.stopImmediatePropagation();
					this.mentioningContacts = [];
					return false;
				}
			}

			this.showEmojiPicker = false;
		} else if ($event.key === '@') {
			this.viewingUsersList = true;
		}
	}

	async sendImage() {
		this.ktDialogService.show();

		for (const image of this.dataImage.images) {
			// this.socket.sendImage(image.image, image.caption, this.protocolListQuery.getActiveId());
			this.api.send_file(this.global.activeGroup.api_id, image.image, image.caption, image.caption);
		}

		this.showSender = false;
		this.dataImage = {active: null, images: []};

	}

	async sendFile() {
		for (const image of this.dataImage.images) {
			// this.socket.sendFile(image.image, image.caption, this.protocolListQuery.getActiveId());
			await this.api.send_file(this.global.activeGroup.api_id, image.image, image.caption, image.caption);
		}

		this.showSender = false;
		this.dataImage = {active: null, images: []};

	}

	async openProtocol(protocol: any) {
		this.hideImageSender();
		this.data.forward_message = null;
		this.chatPage = 1;

		await this.loadData(protocol.id);
		// this.socket.openProtocol(protocol);
		// this.tabAttendance.nativeElement.dispatchEvent(new MouseEvent('click'));
	}

	track(_, item) {
		return item.id;
	}

	openImageSender() {
		this.dataImage = {active: null, images: []};
		this.senderType = 'image';
		this.senderTypeAccept = 'image/*';
		this.showSender = true;
	}

	openFileSender() {
		this.dataImage = {active: null, images: []};
		this.senderType = 'file';
		this.senderTypeAccept = '*/*';
		this.showSender = true;
	}

	hideImageSender() {
		this.showSender = false;
	}

	onSelectImage(event) {
		const that = this;

		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();

			reader.onload = (readerEvent) => { // called once readAsDataURL is completed
				if (readerEvent.total >= 16000000) {
					this.notify.show('O tamanho máximo permitido para envios é de 16 MB.');
					return;
				}

				const file = {
					image: (readerEvent as any).target.result,
					caption: that.tools.extractFilename(event.target.value),
					preview: null,
					icon: null
				};

				switch (this.senderType) {
					case 'image': {
						file.preview = file.image;
						break;
					}
					case 'video': {
						file.icon = 'la la-file-video-o fa-3x';
						break;
					}
					case 'file': {
						file.icon = 'la la-file-o fa-3x';
						break;
					}
				}

				that.dataImage.images.push(file);

				if (!that.dataImage.active) {
					that.dataImage.active = that.dataImage.images[0];
				}

				that.ref.markForCheck();
			};

			reader.readAsDataURL(event.target.files[0]); // read file as data url
		}
	}

	showModal(content, size?: 'sm' | 'lg' | '', options: any = {}) {
		options.size = (size as any);
		options.backdrop = 'static';
		// options.keyboard = false;

		this.modalService.open(content, options);
	}

	openSelfService(type: string) {
		this.activeSelfServiceProtocols$ = this.protocolListQuery.selectAll({
			filterBy: entity => entity.status === type
		});

		this.viewingSelfService = true;
	}

	closeSelfService() {
		this.activeSelfServiceProtocols$ = this.protocolListQuery.selectAll({
			filterBy: entity => entity.status === 'Atendendo'
		});

		this.viewingSelfService = false;
	}

	searchAttendanceContact(view?) {
		this.contactSearchLoading = true;

		if (view === 'Attendance') {
			if (this.data.search) {
				this.attendanceProtocols$ = this.protocolListQuery.selectAll({
					filterBy: entity => (entity.status === 'Atendendo') && entity.contato.nome.toLowerCase().includes(this.data.search.toLowerCase()),
					sortBy: (a, b) => {
						if ((!a.last_message && b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp < b.last_message.timestamp)) {
							return 1;
						}

						if ((a.last_message && !b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp > b.last_message.timestamp)) {
							return -1;
						}

						return 0;
					}
				});
			} else {
				this.attendanceProtocols$ = this.protocolListQuery.selectAll({
					filterBy: entity => (entity.status === 'Atendendo'),
					sortBy: (a, b) => {
						if ((!a.last_message && b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp < b.last_message.timestamp)) {
							return 1;
						}

						if ((a.last_message && !b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp > b.last_message.timestamp)) {
							return -1;
						}

						return 0;
					}
				});
			}
		} else {
			if (this.data.search) {
				// if (view == 'Contacts') {
				if (this.data.search.toLowerCase()) {
					this.contacts$ = this.contactListQuery.selectAll({
						filterBy: entity => entity.nome.toLowerCase().includes(this.data.search.toLowerCase())
					});
				} else {
					this.contacts$ = this.contactListQuery.selectAll();
				}
				// } else {
				//     this.uzap.contactSearch(this.data.search).then(result => {
				//         if (result) {
				//             result.contatos.sort((a, b) => {
				//                 return a.nome.localeCompare(b.nome);
				//             });
				//
				//             this.contacts = result.contatos;
				//             this.searchResultProtocols = result.protocolos;
				//         }
				//
				//         this.contactSearchLoading = false;
				//     }).catch(err => {
				//         this.contactSearchLoading = false;
				//
				//         console.log(err);
				//     });
				// }

				// this.contacts$ = this.contactListQuery.selectAll({
				//     filterBy: entity => (entity.nome.toLowerCase().indexOf(this.data.search) !== -1)
				// });
			} else {
				this.contactListQuery.selectAll().pipe(take(1)).subscribe(value => {
					this.contacts = value;
				});
				// this.contacts$ = this.contactListQuery.selectAll();
			}
		}

		this.viewingAttendanceSearch = true;

		if (this.searchInput) {
			const that = this;
			setTimeout(() => { // this will make the execution after the above boolean has changed
				that.searchInput.nativeElement.focus();
			}, 100);
		}
	}

	searchForwardMessageContact() {
		if (this.data.searchForwardMessage) {
			this.contacts$ = this.contactListQuery.selectAll({
				filterBy: entity => (entity.nome.toLowerCase().indexOf(this.data.searchForwardMessage) !== -1)
			});
		} else {
			this.contacts$ = this.contactListQuery.selectAll();
		}

		const that = this;
		setTimeout(() => { // this will make the execution after the above boolean has changed
			that.searchInputForwardMessage.nativeElement.focus();
		}, 0);
	}

	hideSearchAttendanceContact() {
		this.viewingAttendanceSearch = false;
		this.data.search = null;
	}

	closeActiveProtocol() {
		this.activeProtocol$.pipe(take(1)).subscribe(value => {
			this.socket.closeProtocol(value, this.tools.parseProtocolTags(value, this.data.closeActiveProtocol.message), this.data.closeActiveProtocol.send);

			if (this.data.closeActiveProtocol.save) {
				this.settings.saveCloseProtocolMessage(this.data.closeActiveProtocol.message);
			} else {
				this.data.closeActiveProtocol.message = 'O seu atendimento foi finalizado.';
			}

			// this.socket.openContact(value.contato);
		});
	}

	exitActiveProtocol() {
		this.protocolListService.select(null);
	}

	onSelectEmoji($event) {
		this.data.typing_message += $event.emoji.native;
	}

	toggleEmojiPicker(event) {
		if (!this.showEmojiPicker) {
			const that = this;
			setTimeout(() => {
				that.showEmojiPicker = !that.showEmojiPicker;
				that.ref.markForCheck();
			}, 50);

		}

		event.stopPropagation();
		event.preventDefault();
		return false;
	}

	toggleMicRecording() {
		if (this.audioRecorder.state === RecordingState.RECORDING) {
			this.audioRecorder.onStop();
		} else {
			this.audioRecorder.onStart();
		}
	}

	replyMessage(message: any) {
		this.replyingMessage = message;

		const that = this;
		setTimeout(() => { // this will make the execution after the above boolean has changed
			that.typeMessageInput.nativeElement.focus();
		}, 0);
	}

	onCloseReplyMessage($event: boolean) {
		this.replyingMessage = null;
	}

	forwardMessage(message: any, content) {
		this.data.forward_message = message;
		this.forwardMessageContactList = [];

		this.data.searchForwardMessage = '';
		this.contacts$ = this.contactListQuery.selectAll();

		this.modalService.open(content, {windowClass: 'modal-no-padding'});
	}

	setActiveImage(image) {
		this.dataImage.active = image;
	}

	openVideoSender() {
		this.dataImage = {active: null, images: []};
		this.senderType = 'video';
		this.senderTypeAccept = 'video/*';
		this.showSender = true;
	}

	async openLocationSender() {
		this.mapsAPILoader.load().then(async () => {
			await this.setCurrentLocation();
			this.geoCoder = new google.maps.Geocoder;

			const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
				types: ['address']
			});
			autocomplete.addListener('place_changed', () => {
				this.ngZone.run(() => {
					const place: google.maps.places.PlaceResult = autocomplete.getPlace();
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}

					this.dataMap.latitude = place.geometry.location.lat();
					this.dataMap.longitude = place.geometry.location.lng();
					this.dataMap.zoom = 12;
				});
			});
		});

		this.showSender = true;
		this.senderType = 'location';
	}

	searchLocation() {

	}

	setCurrentLocation() {
		return new Promise((resolve, reject) => {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition((position) => {
					this.dataMap.latitude = position.coords.latitude;
					this.dataMap.longitude = position.coords.longitude;
					this.dataMap.zoom = 15;
					resolve(true);
				}, positionError => {
					reject(positionError);
				});
			} else {
				reject(false);
			}
		});
	}

	markerDragEnd($event) {
		this.dataMap.latitude = $event.coords.lat;
		this.dataMap.longitude = $event.coords.lng;
		this.getAddress(this.dataMap.latitude, this.dataMap.longitude);
	}

	getAddress(latitude, longitude) {
		this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					this.dataMap.zoom = 12;
					this.dataMap.address = results[0].formatted_address;
				} else {
					console.log('No results found');
				}
			} else {
				console.log('Geocoder failed due to: ' + status);
			}

		});
	}

	sendLocation() {
		this.showSender = false;
		this.socket.sendLocation(this.dataMap, this.protocolListQuery.getActiveId());
	}

	onChatScroll() {
		console.log('onChatScroll', {page: this.chatPage, updatingScroll: this.updatingScroll});

		if (!this.updatingScroll && !this.isLoading) {
			this.updatingScroll = true;

			this.chatPage++;
			this.loadData().then(_ => setTimeout((that) => that.updatingScroll = false, 250, this));
		}
	}

	showAdvancedSearchModal(contact, modal) {
		// todo reset values
		this.advancedSearch.dateFilter = Date.now();
		this.advancedSearch.contact = contact;
		// this.socket.getContactProtocols(contact.id);
		this.uzap.getContactProtocols(contact.id).then(result => {
			if (result) {
				this.advancedSearch.protocols = result.map((item) => {
						return {
							...item,
							text: item.numero
						};
					}
				);
			}

			// this.contactSearchLoading = false;
		}).catch(err => {
			// this.contactSearchLoading = false;

			console.log(err);
		});

		this.showModal(modal, 'lg');
	}

	toggleAttendanceWidget(widget) {
		if (this.activeAttendanceWidget === widget) {
			this.activeAttendanceWidget = null;
		} else {
			switch (widget) {
				case 'Historic': {
					this.searchContactProtocols();
					break;
				}

				case 'Received Midia': {
					this.resultProtocolLocations = [];
					this.resultProtocolImages = [];
					this.resultProtocolAudios = [];
					this.resultProtocolVideos = [];
					this.resultProtocolFiles = [];
					this.resultProtocolContacts = [];

					this.activeProtocols$.pipe(take(1)).subscribe(value => {
						for (const protocol of value) {
							this.resultProtocolLocations = [
								...this.resultProtocolLocations,
								...protocol.mensagens.filter(e => e.tipo === 'location')
							];

							this.resultProtocolImages = [
								...this.resultProtocolImages,
								...protocol.mensagens.filter(e => e.tipo === 'image')
							];

							this.resultProtocolAudios = [
								...this.resultProtocolAudios,
								...protocol.mensagens.filter(e => e.tipo === 'audio' || e.tipo === 'ptt')
							];

							this.resultProtocolVideos = [
								...this.resultProtocolVideos,
								...protocol.mensagens.filter(e => e.tipo === 'video')
							];

							this.resultProtocolFiles = [
								...this.resultProtocolFiles,
								...protocol.mensagens.filter(e => e.tipo === 'document')
							];

							this.resultProtocolContacts = [
								...this.resultProtocolContacts,
								...protocol.mensagens.filter(e => e.tipo === 'contact' || e.tipo === 'vcard')
							];
						}
					});

					break;
				}

				default: {
					break;
				}
			}

			this.activeAttendanceWidget = widget;
		}
	}

	closeActiveAttendanceWidget() {
		this.activeAttendanceWidget = null;
	}

	recoverContactCommunication(contact) {
		console.log('todo recoverContactCommunication');
	}

	doContactAdvancedSearch() {
		// this.socket.doContactAdvancedSearch(this.advancedSearch.contact.id, this.advancedSearch.protocolFilter, this.advancedSearch.message);

		this.uzap.contactAdvancedSearch(this.advancedSearch.contact.id, this.advancedSearch.protocolFilter, this.advancedSearch.message).then(result => {
			if (result) {
				this.advancedSearch.resultProtocols = result.map((item) => {
						return {
							...item,
							messages: [...item.mensagens_enviadas, ...item.mensagens_recebidas]
						};
					}
				);
			}

			// this.contactSearchLoading = false;
		}).catch(err => {
			// this.contactSearchLoading = false;

			console.log(err);
		});
	}

	public handlePaste(event: ClipboardEvent): void {
		if (!this.activeProtocol$) {
			return;
		}

		const pastedImage = this.tools.getPastedImage(event);
		if (!pastedImage) {
			return;
		}

		// When we create Object URLs, the browser will keep them in memory until the
		// document is unloaded or until the URL is explicitly released. Since we are
		// going to create a new URL every time the user pastes an image into the app (in
		// this particular demo), we need to be sure to release the previous Object URL
		// before we create the new one.
		// --
		// NOTE: One the Image is rendered in the DOM, releasing the Object URL will not
		// affect the rendering.
		if (this.lastObjectUrl) {
			URL.revokeObjectURL(this.lastObjectUrl);
		}

		// At this point, the "pastedImage" is a File object, which is a specialized type
		// of "Blob". We can now generate a "blob:" URL using the given File.
		this.lastObjectUrl = URL.createObjectURL(pastedImage);

		// By default, Angular WILL NOT TRUST this "blob:" style URLs. However, since we
		// know these are going to be expected, we can use the DOM Sanitizer to bypass
		// the security checks on these images.
		// --
		// NOTE: The sanitizer doesn't return Strings - it returns SafeUrls.
		// this.imageUrls.unshift(
		//     this.sanitizer.bypassSecurityTrustUrl(this.lastObjectUrl)
		// );

		this.openImageSender();

		const that = this;
		const reader = new FileReader();

		reader.onload = (readerEvent) => { // called once readAsDataURL is completed
			const file = {image: (readerEvent as any).target.result, caption: '', preview: null, icon: null};

			switch (this.senderType) {
				case 'image': {
					file.preview = file.image;
					break;
				}
				case 'video': {
					file.icon = 'la la-file-video-o fa-3x';
					break;
				}
				case 'file': {
					file.icon = 'la la-file-o fa-3x';
					break;
				}
			}

			that.dataImage.images.push(file);

			if (!that.dataImage.active) {
				that.dataImage.active = that.dataImage.images[0];
			}

			that.ref.markForCheck();
		};

		reader.readAsDataURL(pastedImage); // read file as data url
	}

	toggleActiveProtocolContact() {
		// this.showActiveProtocolContact = !this.showActiveProtocolContact;
	}

	onClick($event: any) {
		const that = this;
		if (that.showEmojiPicker) {
			setTimeout(() => {
				if (that.showEmojiPicker && !that.emojiMart.nativeElement.contains($event.target)) {
					that.showEmojiPicker = false;
				}
			}, 50);
		}
	}

	showNotifications() {
		this.showModal(this.modalNotificationSettings, 'sm');
	}

	sendActiveCommunication() {
		this.dataActiveCommunication.whatsapp = '55' + this.dataActiveCommunication.whatsapp;

		if (this.tools.validateNumber(this.dataActiveCommunication.whatsapp)) {
			this.socket.addContact(this.dataActiveCommunication.name, this.dataActiveCommunication.whatsapp);
			this.socket.sendActiveCommunication(this.dataActiveCommunication);
			this.dataActiveCommunication.whatsapp.substr(0, 2);
			this.tabAttendance.nativeElement.dispatchEvent(new MouseEvent('click'));

			this.dataActiveCommunication = {name: null, whatsapp: null, message: null};
		} else {
			this.notify.show('Número inválido! Por favor, verifique.', 'danger');
		}
	}

	sendAutomaticAnswer(automaticAnswer: any) {
		alert('todo'); // todo
	}

	doForwardMessage() {
		for (const item of this.forwardMessageContactList) {
			this.socket.forwardMessage(this.data.forward_message, (item.whatsapp + '@c.us'), item.id);
		}
	}

	doSendContacts() {
		for (const item of this.forwardMessageContactList) {
			this.socket.sendContact(item.whatsapp, this.protocolListQuery.getActiveId());
		}

		this.forwardMessageContactList = [];
	}

	onForwardMessageCheckboxChange($event, contact) {
		if ($event.srcElement.checked) {
			this.forwardMessageContactList.push(contact);
		} else {
			this.forwardMessageContactList = this.forwardMessageContactList.filter(item => item !== contact);
		}
	}

	onAdvancedSearchProtocolChanged($event) {
		this.advancedSearch.protocolFilter = $event.value;
	}

	loadEarlyProtocols() {
		this.activeProtocol$.pipe(take(1)).subscribe(value => this.socket.getContactProtocols(value.contato_id));
	}

	searchContactProtocols() {
		this.activeProtocol$.pipe(take(1)).subscribe(value => {
			this.uzap.getContactProtocols(value.contato_id).then(result => {
				this.resultProtocols = result;

				if (this.data.contactProtocolSearch) {
					this.resultProtocols = this.resultProtocols.filter(entity => entity.numero.toLowerCase().indexOf(this.data.contactProtocolSearch) !== -1);
				}
			}).catch(err => {
				console.log(err);
			});
		});
	}

	doSendContactObservation() {
		alert('todo'); // todo
	}

	addContactFromMessage(message: any) {
		this.socket.addContact(this.tools.parseVCard(message).fn[0].value, this.tools.parseVCard(message).tel[0].value);
		this.notify.show('Contato adicionado com sucesso!');
	}

	openContact(contact) {
		this.socket.openContact(contact);
	}

	openImageLightbox(source, caption) {
		this.lightbox.open([{
			src: source,
			caption,
			thumb: source
		}], 0);
	}

	openNewProtocol() {
		this.activeProtocol$.pipe(take(1)).subscribe(value => this.socket.openNewProtocol(value.contato_id));
	}

	saveContactName() {
		this.activeProtocol$.pipe(take(1)).subscribe(value => {
			this.socket.saveContactName(value.contato_id, this.data.contact_name);

			const clone = JSON.parse(JSON.stringify(value));
			clone.contato.nome = this.data.contact_name;
			this.protocolListService.update(value.id, clone);
			this.contactListStore.update(clone.contato.id, list => {
				return {
					...list,
					nome: clone.contato.nome
				};
			});

			this.data.contact_name = '';
			this.notify.show('Contato atualizado com sucesso!');
		});
	}

	getAutomaticAnswers() {
		this.uzap.getAutomaticAnswers(this.user.getUser().canal_id).then(result => {
			if (result) {
				this.automaticAnswers = result.items;
				this.updateAutomaticAnswersCount();
			}
		}).catch(err => {
			console.log(err);
		});
	}

	saveAutomaticAnwer() {
		this.uzap.addAutomaticAnswers(this.user.getUser().canal_id, this.data.automaticAnwer.title, this.data.automaticAnwer.body, this.user.getUser().id, this.data.automaticAnwer.privacy).then(result => {
			if (result) {
				this.getAutomaticAnswers();
				this.data.automaticAnwer = {
					title: ''
				};
				this.notify.show('Mensagem adicionada com sucesso!');
			}
		}).catch(err => {
			console.log(err);
		});
	}

	editAutomaticAnwer() {
		this.uzap.editAutomaticAnswers(this.user.getUser().canal_id, this.activeAutomaticAnswer.id, this.activeAutomaticAnswer.title, this.activeAutomaticAnswer.body, this.user.getUser().id).then(result => {
			if (result) {
				this.getAutomaticAnswers();
				this.notify.show('Mensagem atualizada com sucesso!');
			}
		}).catch(err => {
			console.log(err);
		});
	}

	removeAutomaticAnwer() {
		this.uzap.removeAutomaticAnswers(this.user.getUser().canal_id, this.activeAutomaticAnswer.id).then(result => {
			if (result) {
				this.getAutomaticAnswers();
				this.notify.show('Mensagem removida com sucesso!');
			}
		}).catch(err => {
			console.log(err);
		});
	}

	private updateAutomaticAnswersCount() {
		this.automaticAnswersCountAll = this.automaticAnswers.length;
		this.automaticAnswersCountSelf = this.automaticAnswers.filter(e => e.usuario_id == this.user.getUser().id).length;
	}

	onClickAutomaticAnswer(automaticAnswer: any) {
		this.data.typing_message = automaticAnswer.conteudo;
		this.automaticAnswersDropdown.dropdown.close();
		// this.modalService.dismissAll();

		const that = this;
		setTimeout(() => { // this will make the execution after the above boolean has changed
			that.typeMessageInput.nativeElement.focus();
			that.ref.detectChanges();
		}, 0);
	}

	insertProtocolTag() {
		this.data.closeActiveProtocol.message += ' {protocolo} ';
	}

	doForwardProtocol() {
		this.activeProtocol$.pipe(take(1)).subscribe(value => {
			this.uzap.forwardProtocol(value.id, this.forwardProtocol.department, this.forwardProtocol.operator, this.forwardProtocol.observation).then(result => {
				if (result) {
					// this.protocolListService.remove(value.id);
					this.notify.show('Protocolo encaminhado com sucesso!');
					this.openProtocol(value);
				}
			}).catch(err => {
				console.log(err);
			});
		});
	}

	private async loadGroups() {
		const res = await this.api.get_groups();
		for (const item of res) {
			if (this.user.getUser().permissoes_grupos && !this.user.getUser().permissoes_grupos.includes(item.id)) {
				continue;
			}

			const original = this.groups.find(e => e.id === item.id);
			if (original) {
				original.notificacoes_nao_lidas = item.notificacoes_nao_lidas;
			} else {
				this.groups.push(item);
			}
		}

		this.totalNotifications = this.groups.reduce((acc, value) => acc + parseInt(value.notificacoes_nao_lidas, 10), 0);
	}

	private async loadData(groupId?, showLoader = true, useTimestamp = false) {
		if (this.isLoading || (groupId && this.global.activeGroup.id === groupId)) {
			return;
		}

		this.isLoading = true;

		if (showLoader) {
			this.ktDialogService.show();
		}

		if (groupId) {
			this.global.activeGroup = (await this.api.get_group(groupId, this.chatPage));
		} else if (this.global.activeGroup) {
			const tmp = (await this.api.get_group(this.global.activeGroup.id, this.chatPage, useTimestamp ? this.global.activeGroup.sync_timestamp : null));

			tmp.mensagens_enviadas.filter(e => e.tipo === 'Midia').map(e => e.tipo = this.tools.getMediaMessageType(e.conteudo));

			const sizeBefore = this.messages.length;
			this.messages = [...this.messages, ...tmp.mensagens_enviadas, ...tmp.mensagens].sort((a, b) => {
				const res = (a.timestamp && b.timestamp) ? (a.timestamp < b.timestamp) : (Date.parse(a.criado_em) < Date.parse(b.criado_em));
				return res ? -1 : 1;
			});

			this.global.activeGroup.sync_timestamp = tmp.sync_timestamp;
			this.ktDialogService.hide();
			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}

			if (sizeBefore !== this.messages.length) {
				setTimeout(that => {
					try {
						that.chatScroll.update();
						that.chatPage <= 1 && that.chatScroll.scrollToBottom();
					} catch (e) {

					}
				}, 50, this);
			}

			this.isLoading = false;
			return;
		} else {
			await this.loadGroups();
			this.allGroups = this.groups;
			this.global.activeGroup = this.groups.length ? (await this.api.get_group(this.groups[0].id, this.chatPage)) : null;
		}

		if (this.global.activeGroup) {
			this.global.activeGroup.mensagens_enviadas.filter(e => e.tipo === 'Midia').map(e => e.tipo = this.tools.getMediaMessageType(e.conteudo));

			this.messages = [...this.global.activeGroup.mensagens_enviadas, ...this.global.activeGroup.mensagens].sort((a, b) => {
				const res = (a.timestamp && b.timestamp) ? (a.timestamp < b.timestamp) : (Date.parse(a.criado_em) < Date.parse(b.criado_em));
				return res ? -1 : 1;
			});

			this.global.activeGroup.notificacoes_nao_lidas = 0;
			this.groups[this.groups.findIndex(e => e.id === this.global.activeGroup.id)] = this.global.activeGroup;
		}

		this.totalNotifications = this.groups.reduce((acc, value) => acc + parseInt(value.notificacoes_nao_lidas, 10), 0);

		setTimeout(that => {
			try {
				that.chatScroll.update();
				that.chatPage <= 1 && that.chatScroll.scrollToBottom();
			} catch (e) {

			}
		}, 50, this);

		this.ktDialogService.hide();
		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
		this.isLoading = false;
	}

	onRemoveAutomaticAnswer(automaticAnswer: any) {
		this.activeAutomaticAnswer = automaticAnswer;

		this.showModal(this.modalRemoveAutomaticAnswer);
	}

	onEditAutomaticAnswer(automaticAnswer: any) {
		this.activeAutomaticAnswer = automaticAnswer;

		this.activeAutomaticAnswer.privacy = (automaticAnswer.usuario_id == this.user.getUser().id ? '1' : '0');
		this.activeAutomaticAnswer.title = automaticAnswer.titulo;
		this.activeAutomaticAnswer.body = automaticAnswer.conteudo;

		this.showModal(this.modalEditAutomaticAnswer);
	}

	attendProtocol() {
		this.hideImageSender();
		this.data.forward_message = null;
		this.chatPage = 1;

		this.activeProtocol$.pipe(take(1)).subscribe(value => {
			this.socket.attendProtocol(value);
		});
		this.tabAttendance.nativeElement.dispatchEvent(new MouseEvent('click'));
	}

	private getMessageAffix() {
		if (this.signMessages) {
			return `*${this.user.getUser().nome}:*
`;
		}

		return '';
	}

	onSignMessagesChange($event) {
		this.signMessages = $event.target.checked;
		this.settings.saveSignMessage(this.signMessages);
	}

	onFilterOperator() {
		if (this.filterOperator) {
			this.attendanceProtocols$ = this.protocolListQuery.selectAll({
				filterBy: entity => (entity.status === 'Atendendo' && entity.usuario_id == this.filterOperator),
				sortBy: (a, b) => {
					if ((!a.last_message && b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp < b.last_message.timestamp)) {
						return 1;
					}

					if ((a.last_message && !b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp > b.last_message.timestamp)) {
						return -1;
					}

					return 0;
				}
			});
		} else {
			this.attendanceProtocols$ = this.protocolListQuery.selectAll({
				filterBy: entity => (entity.status === 'Atendendo'),
				sortBy: (a, b) => {
					if ((!a.last_message && b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp < b.last_message.timestamp)) {
						return 1;
					}

					if ((a.last_message && !b.last_message) || (a.last_message && b.last_message && a.last_message.timestamp > b.last_message.timestamp)) {
						return -1;
					}

					return 0;
				}
			});
		}
	}

	onGroupSearch() {
		if (this.data.search) {
			this.messages = [...this.global.activeGroup.mensagens_enviadas, ...this.global.activeGroup.mensagens].filter(e => e.conteudo.toLowerCase().includes(this.data.search.toLowerCase())).sort((a, b) => {
				const res = (a.timestamp && b.timestamp) ? (a.timestamp < b.timestamp) : (Date.parse(a.criado_em) < Date.parse(b.criado_em));
				return res ? -1 : 1;
			});
		} else {
			this.messages = [...this.global.activeGroup.mensagens_enviadas, ...this.global.activeGroup.mensagens].sort((a, b) => {
				const res = (a.timestamp && b.timestamp) ? (a.timestamp < b.timestamp) : (Date.parse(a.criado_em) < Date.parse(b.criado_em));
				return res ? -1 : 1;
			});
		}

		this.chatScroll.update();
		setTimeout((that) => {
			that.updatingScroll = false;
			that.chatScroll.scrollToBottom();
		}, 150, this);
	}

	onGroupsSearch() {
		if (this.data.groupSearch) {
			this.groups = this.allGroups.filter(e => e.nome.toLowerCase().includes(this.data.groupSearch.toLowerCase()));
		} else {
			this.groups = this.allGroups;
		}

		if (!this.ref['destroyed']){
			this.ref.detectChanges();
		}
	}

	private cleanAutoRefresh() {
		if (this.autoRefreshEvent) {
			clearTimeout(this.autoRefreshEvent);
			this.autoRefreshEvent = null;
		}
	}

	private cleanGroupsAutoRefresh() {
		if (this.groupsAutoRefreshEvent) {
			clearTimeout(this.groupsAutoRefreshEvent);
			this.groupsAutoRefreshEvent = null;
		}
	}

	private setupAutoRefresh() {
		if (this.autoRefreshEvent) {
			this.cleanAutoRefresh();
		}

		let work = null;

		const scheduleWork = () => {
			this.autoRefreshEvent = setTimeout(work, 2 * 1000);
		};

		work = async () => {
			this.cleanAutoRefresh();

			try {
				if (this.global.activeGroup) {
					await this.loadData(undefined, false, true);
				}
			} catch (e) {
				console.error(e);
			}

			scheduleWork();
		};

		scheduleWork();
	}

	private setupGroupsAutoRefresh() {
		if (this.groupsAutoRefreshEvent) {
			this.cleanGroupsAutoRefresh();
		}

		let work = null;

		const scheduleWork = () => {
			this.groupsAutoRefreshEvent = setTimeout(work, 10 * 1000);
		};

		work = async () => {
			this.cleanGroupsAutoRefresh();

			try {
				await this.loadGroups();
			} catch (e) {
				console.error(e);
			}

			scheduleWork();
		};

		scheduleWork();
	}

	onMentionContact(groupContact: any) {
		console.log(groupContact);
		this.viewingUsersList = false;
		this.data.typing_message += groupContact.contato.whatsapp;
		this.mentioningContacts.push(groupContact.contato.whatsapp);
	}
}
