import {Injectable} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';
import {TextMaskConfig} from 'angular2-text-mask/src/angular2TextMask';
import {cpf} from 'cpf-cnpj-validator';

@Injectable({
	providedIn: 'root'
})
export class GenericValidatorService {

	constructor() {
	}

	phoneMask: TextMaskConfig = {mask: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]};
	creditCardMask: TextMaskConfig = {mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]};
	monthYearMask: TextMaskConfig = {mask: [/\d/, /\d/, '/', /\d/, /\d/]};
	cvvMask: TextMaskConfig = {mask: [/\d/, /\d/, /\d/]};
	rgMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
	cpfMask: TextMaskConfig = {mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]};
	cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
	cepMask: TextMaskConfig = {mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]};

	static isValidCpf() {
		return (control): Validators => {
			return {cpfNotValid: control.value ? !cpf.isValid(control.value.replace(/\D+/g, '')) : null};
		};
	}
}
