import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'split'
})
export class SplitPipe implements PipeTransform {

	transform(str: any, ...args: any[]): any {
		let [first, ...second] = str.split(' ');
		second = second.join(' ');

		return `${first}<span>${second}</span>`;
	}

}
