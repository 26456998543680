import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';
// import {BASEURL} from '../constants';
import * as vCard from 'vcard-parser';
import {DOCUMENT} from '@angular/common';
import {parsePhoneNumber} from 'libphonenumber-js';
import IMask from 'imask';
import * as constants from '../constants';

@Injectable({
	providedIn: 'root'
})
export class ToolsService {
	baseUrl: any;
	elem;
	isFullscreen: any = false;
	mediaUrl: any;

	constructor(public http: HttpClient,
				@Inject(DOCUMENT) private document: any) {
		this.baseUrl = `${constants.BASE}:9999/`;
		// this.baseUrl = `https://200.150.198.252:9999/`;
		this.elem = document.documentElement;
		this.mediaUrl = constants.storageURL
	}


	scrollToBottom() {
		const chatScroll = document.getElementById('scrollMe');
		chatScroll.scrollTop = chatScroll.scrollHeight;
	}

	isBase64(str) {
		try {
			return btoa(atob(str)) === str;
		} catch (err) {
			return false;
		}
	}

	getTimeDiffDescription(date) {
		const diff = Date.now() - Date.parse(date);
		const days = Math.floor(diff / (60 * 60 * 24 * 1000));
		const hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
		const minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
		// var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
		return hours + 'h' + minutes + 'mn';
	}

	downloadFromUrl(url) {
		return this.http.get(url, {responseType: 'blob'});
	}

	extractFilename(fullPath: any) {
		return fullPath.replace(/^.*[\\\/]/, '');
	}

	getProtocolStatuses() {
		return [
			{value: 'Em Espera', name: 'Em Espera'},
			{value: 'Concluido', name: 'Concluído'},
			{value: 'Abandono', name: 'Abandono'},
			{value: 'Atendendo', name: 'Atendendo'},
		];
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	getMessageMediaSource(message: any) {
		if(message.midia_url && (message.midia_url.startsWith('http') || message.midia_url.startsWith('data:'))) return message.midia_url
		return message.midia_url ? (this.mediaUrl + message.midia_url) : message.conteudo
	}

	getLocationLink(message: any) {
		const data = this.parseMessageExtraData(message);
		return 'https://maps.google.com/?q=' + data.latitude + ',' + data.longitude;
	}

	parseMessageExtraData(message) {
		return {caption: '', mime: '', size: 0, latitude: '', longitude: ''};
	}

	// downloadMessageMedia(message: any) {
	// 	this.downloadFromUrl(this.baseUrl + message.midia_url).subscribe(data => saveAs(data, this.parseMessageExtraData(message).caption || this.extractFilename(message.midia_url)));
	// }

	// parseVCard(message: any) {
	// 	return vCard.parse(message.conteudo);
	// }

	getErrandFilters() {
		return [
			{value: 'Todos', name: 'Todos'},
			{value: 'Arquivados', name: 'Arquivados'},
			{value: 'Encaminhados', name: 'Encaminhados'},
			{value: 'Nao Encaminhados', name: 'Não Encaminhados'},
		];
	}

	getErrandFilterOptions() {
		return [
			{value: 'Todos', name: 'Todos'},
			{value: 'Data', name: 'Data'},
			{value: 'Periodo', name: 'Período'},
			{value: 'Contato', name: 'Contato'},
			{value: 'Etiquetas', name: 'Etiquetas'}
		];
	}

	openFullscreen() {
		if (this.elem.requestFullscreen) {
			this.elem.requestFullscreen();
		} else if (this.elem.mozRequestFullScreen) {
			/* Firefox */
			this.elem.mozRequestFullScreen();
		} else if (this.elem.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			this.elem.webkitRequestFullscreen();
		} else if (this.elem.msRequestFullscreen) {
			/* IE/Edge */
			this.elem.msRequestFullscreen();
		}

		this.isFullscreen = true;
	}

	/* Close fullscreen */
	closeFullscreen() {
		if (this.document.exitFullscreen) {
			this.document.exitFullscreen();
		} else if (this.document.mozCancelFullScreen) {
			/* Firefox */
			this.document.mozCancelFullScreen();
		} else if (this.document.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			this.document.webkitExitFullscreen();
		} else if (this.document.msExitFullscreen) {
			/* IE/Edge */
			this.document.msExitFullscreen();
		}

		this.isFullscreen = false;
	}

	parseMessageTags(message) {
		return message.replace(/\*(.*?)\*/, '<b>$1</b>').replace(/_(.*?)_/, '<i>$1</i>');
	}

	public getPastedImage(event: ClipboardEvent): File | null {
		if (
			event.clipboardData &&
			event.clipboardData.files &&
			event.clipboardData.files.length &&
			this.isImageFile(event.clipboardData.files[0])
		) {
			return (event.clipboardData.files[0]);
		}

		return (null);
	}

	public isImageFile(file: File): boolean {
		return (file.type.search(/^image\//i) === 0);

	}

	getMessageHtmlContent(message) {
		switch (message.tipo) {
			case 'Midia': {
				return '📷 Mídia';
			}

			case 'Imagem':
			case 'image': {
				return '📷 ' + message.caption || 'Imagem';
			}

			case 'Aúdio':
			case 'Audio':
			case 'audio':
			case 'ptt': {
				return '🔊 Aúdio';
			}

			case 'location': {
				return '🗺 ' + message.address || 'Localização';
			}

			case 'Video':
			case 'Vídeo':
			case 'video': {
				return '🎥 ' + message.caption || 'Vídeo';
			}

			case 'vcard':
			case 'contact': {
				return '📱 Contato';
			}

			case 'Arquivo':
			case 'File':
			case 'document':
			case 'application':	 {
				return '📄 ' + message.caption || 'Documento';
			}

			case 'chat':
			case 'Texto':
			default: {
				return this.parseMessageTags(message.conteudo);
			}
		}

		return '';
	}

	insertAtCaret(areaId, text) {
		const txtarea = document.getElementById(areaId) as any;
		const scrollPos = txtarea.scrollTop;
		let strPos = 0;
		const br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ? 'ff' : ((document as any).selection ? 'ie' : false));
		if (br == 'ie') {
			txtarea.focus();
			const range = (document as any).selection.createRange();
			range.moveStart('character', -txtarea.value.length);
			strPos = range.text.length;
		} else if (br == 'ff') {
			strPos = txtarea.selectionStart;
		}

		const front = (txtarea.value).substring(0, strPos);
		const back = (txtarea.value).substring(strPos, txtarea.value.length);
		txtarea.value = front + text + back;
		strPos = strPos + text.length;
		if (br == 'ie') {
			txtarea.focus();
			const range = (document as any).selection.createRange();
			range.moveStart('character', -txtarea.value.length);
			range.moveStart('character', strPos);
			range.moveEnd('character', 0);
			range.select();
		} else if (br == 'ff') {
			txtarea.selectionStart = strPos;
			txtarea.selectionEnd = strPos;
			txtarea.focus();
		}
		txtarea.scrollTop = scrollPos;
	}

	parseVCard(message: any) {
		return vCard.parse(message.conteudo);
	}

	downloadMessageMedia(message: any) {
		if(message.midia_url.includes('http')){
			this.downloadFromUrl(message.midia_url).subscribe(data => saveAs(data, this.extractFilename(message.midia_url)));
		}else{
			this.downloadFromUrl(this.mediaUrl + message.midia_url).subscribe(data => saveAs(data, this.extractFilename(message.midia_url)));
		}
	}

	parseProtocolTags(protocol, message) {
		return message.replace('{protocolo}', protocol.numero);
	}

	validateNumber(n) {
		return !!parsePhoneNumber('+' + n);
	}

	getMediaMessageType(b64) {
		const type = b64.split(';')[0].split('/')[1];

		switch (type) {
			case 'ogg':
			case 'wav':
			case 'mp3': {
				return 'audio';
			}
			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'bmp': {
				return 'image';
			}
			case 'amr':
			case 'mpeg':
			case '3gpp':
			case 'mp4': {
				return 'video';
			}
		}

		return 'document';
	}

	keypress(){
		let elem = document.getElementById('inputPhone') as HTMLInputElement;
		let maskOptions = {
			mask: '+{55}(00)00000-0000',
		};
		let mask = IMask(elem, maskOptions);
		if(elem.value.length>16){
			elem.value.slice(0, -1);
		}
	}
}
