import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ErrorService} from './error.service';
import {UserService} from './user.service';

import * as constants from '../constants';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(public http: HttpClient,
				public error: ErrorService,
				public user: UserService) {
	}

	auth(login, password, channel = null, token = null) {
		return this.http.post(constants.API_BASEURL + 'authenticate', {login, password,channel,token}).toPromise().then().catch(this.error.handleError);
	}

	generate_token() {
		return this.http.post(constants.API_BASEURL + 'generate_token', {}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	update_webhook_url(webhook_url) {
		return this.http.post(constants.API_BASEURL + 'update_webhook_url', {webhook_url}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	update_endpoint(id, active) {
		return this.http.post(constants.API_BASEURL + 'update_endpoint', {id, active}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	update_event(id, active) {
		return this.http.post(constants.API_BASEURL + 'update_event', {id, active}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_menus() {
		return this.http.get(constants.API_BASEURL + 'menu', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_add(data = {}) {
		return this.http.post(constants.API_BASEURL + 'menu', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_delete(id) {
		return this.http.delete(`${constants.API_BASEURL}menu/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_save(data, menus) {
		return this.http.post(constants.API_BASEURL + 'menu_save', {data, menus}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_add_media(data) {
		return this.http.post(constants.API_BASEURL + 'menu_add_media', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_media_delete(id: any) {
		return this.http.delete(`${constants.API_BASEURL}menu_media/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_add_api_response(data) {
		return this.http.post(constants.API_BASEURL + 'menu_add_api_response', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_delete_api_response(id: any) {
		return this.http.delete(`${constants.API_BASEURL}menu_api_response/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	channel_save(data) {
		return this.http.post(constants.API_BASEURL + 'channel_save', {data}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	send_message(number, message, reply_message_id = null, mentioned_message_id = null, mentioned_users = null) {
		// if (mentioned_message_id) {
		// 	mentioned_message_id = mentioned_message_id.toString().replace('false_', '');
		// 	mentioned_message_id = mentioned_message_id.toString().replace('true_', '');
		// }

		if (mentioned_users) {
			mentioned_users = mentioned_users.join();
		}

		return this.http.post(constants.API_BASEURL + 'send_message', {number, message, reply_message_id, mentioned_message_id, mentioned_users}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	send_file(number, body, filename, caption) {
		return this.http.post(constants.API_BASEURL + 'send_file', {number, body, filename, caption}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_add_answer_field(data) {
		return this.http.post(constants.API_BASEURL + 'menu_add_answer_field', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	menu_delete_answer_field(id: any) {
		return this.http.delete(`${constants.API_BASEURL}menu_answer_field/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notifications_templates() {
		return this.http.get(constants.API_BASEURL + 'notifications_templates', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	notification_template_save(data) {
		return this.http.post(constants.API_BASEURL + 'notification_template', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	test_notification_template(number, notificationTemplate, data = null) {
		return this.http.post(constants.API_BASEURL + 'test_notification_template', {number, notificationTemplate, data}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	notification_template_delete(id) {
		return this.http.delete(`${constants.API_BASEURL}notification_template/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notifications(page, search) {
		let params = new HttpParams();
		Object.keys(search).forEach(key => {
			params = params.append(key, search[key]);
		});
		//console.log(this.user.getToken())
		return this.http.get(`${constants.API_BASEURL}notifications/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_total_notifications() {
		return this.http.get(`${constants.API_BASEURL}total_notifications`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	send_contact(number, phone) {
		return this.http.post(constants.API_BASEURL + 'send_contact', {number, phone}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_plan_extract(page = 0,filters) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(`${constants.API_BASEURL}plan_extract/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	mark_as_read(notification: any) {
		return this.http.post(`${constants.API_BASEURL}mark_as_read`, {notification_id: notification.id}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notification(id: any) {
		return this.http.get(`${constants.API_BASEURL}notification/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notification_msgs(id: any) {
		return this.http.get(`${constants.API_BASEURL}notification_msgs/${id}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	request_password(email: any) {
		return this.http.post(`${constants.API_BASEURL}request_password`, {email}, {}).toPromise().then().catch(this.error.handleError);
	}

	update_password(data) {
		return this.http.post(`${constants.API_BASEURL}update_password`, data, {}).toPromise().then().catch(this.error.handleError);
	}

	get_surveys_templates() {
		return this.http.get(constants.API_BASEURL + 'surveys_templates', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_users(page) {
		return this.http.get(`${constants.API_BASEURL}users/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	survey_template_save(data) {
		return this.http.post(constants.API_BASEURL + 'survey_template', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_survey_reports() {
		return this.http.get(constants.API_BASEURL + 'survey_reports', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_survey_report_results(page, search) {
		let params = new HttpParams();
		Object.keys(search).forEach(key => {
			params = params.append(key, search[key]);
		});

		return this.http.get(constants.API_BASEURL + 'survey_report_results/' + page, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notification_report(notification_id, search) {
		let params = new HttpParams();
		Object.keys(search).forEach(key => {
			params = params.append(key, search[key]);
		});

		return this.http.get(constants.API_BASEURL + 'notification_report/' + notification_id, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_groups() {
		return this.http.get(constants.API_BASEURL + 'groups', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_group(id, page = 1, sync_timestamp = null) {
		let params = new HttpParams();
		params = params.append('page', page.toString());

		return this.http.get(`${constants.API_BASEURL}group/${id}` + (sync_timestamp ? `/${sync_timestamp}` : ''), {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	user_save(data) {
		return this.http.post(constants.API_BASEURL + 'user_save', {data}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	user_remove(id: any) {
		return this.http.post(constants.API_BASEURL + 'user_remove', {id}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notification_identifiers(id: any) {
		return this.http.post(constants.API_BASEURL + 'get_notification_identifiers', {id}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notification_tags() {
		return this.http.get(constants.API_BASEURL + 'notification_tags', {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	notification_items_save(items){
		return this.http.post(constants.API_BASEURL + 'notification_items_save', {items}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_queue(page){
		return this.http.get(`${constants.API_BASEURL}queue/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	delete_in_queue(item){
		return this.http.post(constants.API_BASEURL + 'delete_in_queue', {item}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_contacts(page,search){
		let params = new HttpParams();
		Object.keys(search).forEach(key => {
			params = params.append(key, search[key]);
		});
		return this.http.get(`${constants.API_BASEURL}contacts/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	create_contact(item){
		return this.http.post(constants.API_BASEURL + 'create_contact', {item}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	edit_contact(item){
		return this.http.post(constants.API_BASEURL + 'edit_contact', {item}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	delete_contact(item){
		return this.http.post(constants.API_BASEURL + 'delete_contact', {item}, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_survey_questions(notification_id) {
		return this.http.get(constants.API_BASEURL + 'survey_questions/' + notification_id, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
		}).toPromise().then().catch(this.error.handleError);
	}

	get_all_notifications(total,search){
		let params = new HttpParams();
		Object.keys(search).forEach(key => {
			params = params.append(key, search[key]);
		});
		return this.http.get(constants.API_BASEURL + 'all_notifications/' + total, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}	

	send_sheet(notificationTemplate, data){
		return this.http.post(constants.API_BASEURL + 'send_sheet', { notificationTemplate, data, fromPanel: true }, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	send_sheet2(notificationId, data){
		return this.http.post(`${constants.BASE}:5980/send_sheet`, { notificationId, data, fromPanel: true }, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_chart(notification_id) {
		return this.http.get(constants.API_BASEURL + 'chart/' + notification_id, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
		}).toPromise().then().catch(this.error.handleError);
	}

	update_channel_config(data){
		return this.http.post(constants.API_BASEURL + 'update_channel_config', data, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken())
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notifications_data(page,filters={}) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(constants.API_BASEURL + `notifications_data/${page}`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}

	get_notifications_chart(filters={}) {
		let params = new HttpParams();
		Object.keys(filters).forEach(key => {
			params = params.append(key, filters[key]);
		});
		return this.http.get(constants.API_BASEURL + `notifications_chart`, {
			headers: new HttpHeaders().set('authorization-token', this.user.getToken()),
			params
		}).toPromise().then().catch(this.error.handleError);
	}
}
