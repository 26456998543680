import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NotifyService} from '../../../services/notify.service';
import {ApiService} from '../../../services/api.service';
import {ApiJsService} from '../../../services/apiJS.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SocketService } from '../../../services/socket.service';
import * as cep from 'cep-promise';

@Component({
  selector: 'kt-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent implements OnInit {

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		public api: ApiService,
		public apiJS: ApiJsService,
		public user: UserService,
		public notify: NotifyService,
		private modalService: NgbModal,
    private socketService: SocketService,
    public subHeader: SubheaderService,
    public ref: ChangeDetectorRef,
    public ktDialogService: KtDialogService,
  ) { 
    this.subHeader.setTitle('Gerenciar - Operadores');
  }

  @ViewChild('modalNewOperator', {static: false}) modalNewOperator: ElementRef;
  @ViewChild('modalEditOperator', {static: false}) modalEditOperator: ElementRef;
  @ViewChild('modalFilterOperator', {static: false}) modalFilterOperator: ElementRef;
  @ViewChild('modalDeleteOperator', {static: false}) modalDeleteOperator: ElementRef;
  @ViewChild('modalDeleteOperatorArray', {static: false}) modalDeleteOperatorArray: ElementRef;

  operators:  any = [
    // {name:'operador1',department:['dep1'],online:false,active:true},
    // {name:'operador2',department:['dep1','dep2'],online:true,active:true},
  ] 
  operator: any = {weekDays:['1','2','3','4','5'],department:[]}
  departments: any = []
  objAux: any = {weekDays:['1','2','3','4','5'],department:[]}
  selectBox: any = ''
  weekDays: any = [{name:'Seg',id:'1'},{name:'Ter',id:'2'},{name:'Qua',id:'3'},{name:'Qui',id:'4'},{name:'Sex',id:'5'},{name:'Sab',id:'6'},{name:'Dom',id:'0'}]
  search: any = {type:''}

  ngOnInit() {
    this.getDepartments()
    this.socketService.onSetDepartments().subscribe((data:any) => {
			this.departments = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		});
    this.getOperators()
    this.socketService.onSetOperators().subscribe((data:any) => {
      this.operators = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		});
    this.socketService.onNewOperator().subscribe((data:any) => {
      this.operators.push(data)
      if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		});
  }

  openModalNew(item = null){
    if(item){
      this.objAux = Object.assign({}, item);
      this.operator = item
    }else{
      this.objAux = {weekDays:[],department:[]}
      this.operator = {weekDays:[],department:[]}
    }
    this.modalService.open(this.modalNewOperator, {size: 'lg'});
  }
  openModalFilter(){
    this.modalService.open(this.modalFilterOperator, {size: 'sm'});
  }
  openModalDelete(item = null){
    if(item){
      this.operator = item
    }
    this.modalService.open(this.modalDeleteOperator, {size: 'sm'});
  }
  openModalDeleteArray(){
    if (this.operators.some(val => val.checked == true)){
      this.modalService.open(this.modalDeleteOperatorArray, {size: 'sm'});
    }else{
      this.notify.show(`Nenhum Departamento selecionado!`, 'danger');
    }
  }
  getDepartments(){
    this.socketService.getDepartments(this.user.getUser().instance_id);
  }
  getOperators(){
    this.socketService.getOperators(this.user.getUser().instance_id);
  }
  async saveOperator(){
    let newOperator = false
    if(!this.objAux.name){
      return this.notify.show(`Campo Nome vazio!`, 'danger');
    } 
    if(!this.objAux.bot && !this.objAux.admin && (!this.objAux.department || (this.objAux.department && !this.objAux.department.length))){
      return this.notify.show(`Nenhum Departamento selecionado!`, 'danger');
    } 
    if(!this.objAux.login){
      return this.notify.show(`Campo Email vazio!`, 'danger');
    } 
    if(!this.objAux.channelId){
      if(!this.objAux.password){
        return this.notify.show(`Campo Senha vazio!`, 'danger');
      }
      newOperator = true
      this.objAux.channelId = this.user.getUser().instance_id
    }
    this.ktDialogService.show();
    Object.keys(this.objAux).forEach((key) => {
      this.operator[key] = this.objAux[key]
    });
    if(this.operator.bot){
      this.operator.isOperator = 2
      this.operator.channelNumber = this.user.getUser().whatsapp_id
    }
    else this.operator.isOperator = 1
    if(this.operator.operatorId){
      this.operator.id = this.operator.operatorId
    }
    this.operator.senha = this.operator.password
    this.operator.email = this.operator.login
    this.operator.nome = this.operator.name
    await this.api.user_save(this.operator).then(value => {
			if (value.success) {
        this.operator.operatorId = value.user.id
				this.socketService.saveOperator(this.operator);
        this.dismissModal()
        if(newOperator){
          this.notify.show(`Operador criado com sucesso!`, 'success');
        }else{
          this.notify.show(`Operador editado com sucesso!`, 'success');
        }
			}else{
        this.notify.show(`${value.message}`, 'danger');
      }
		}).catch(err=>{
      console.log(err)
      this.notify.show(`Ocorreu um erro!`, 'danger');
    });

    // await this.apiJS.user_save(this.operator).then(value => {
		// 	if (value.success) {
    //     this.operator.operatorId = value.user.id
		// 		this.socketService.saveOperator(this.operator);
    //     this.dismissModal()
    //     if(newOperator){
    //       this.notify.show(`Operador criado com sucesso!`, 'success');
    //     }else{
    //       this.notify.show(`Operador editado com sucesso!`, 'success');
    //     }
		// 	}else{
    //     this.notify.show(`${value.message}`, 'danger');
    //   }
		// }).catch(err=>{
    //   console.log(err)
    //   this.notify.show(`Ocorreu um erro!`, 'danger');
    // });
		this.ktDialogService.hide();
    
  }
  async deleteOperator(op=null){
    if(op){
      return await this.api.user_remove(op.operatorId).then(value => {
        if (value.success) {
          this.socketService.deleteOperator(op._id);
          this.operators = this.operators.filter(el=>el!=op)
          if (!this.ref['destroyed']){
            this.ref.detectChanges();
          }
        }else{
          this.notify.show(`${value.message}`, 'danger');
        }
      });
    }
    this.ktDialogService.show();
    await this.api.user_remove(this.operator.operatorId).then(value => {
      if (value.success) {
        this.socketService.deleteOperator(this.operator._id);
        this.operators = this.operators.filter(el=>el!=this.operator)
        this.dismissModal()
        this.notify.show(`Operador removido com sucesso!`, 'success');
      }else{
        this.notify.show(`${value.message}`, 'danger');
      }
    });
    
    this.ktDialogService.hide();
  }
  deleteSelected(){
    let ops = this.operators.filter(el => el.checked == true)
    ops.forEach(el=> {
      this.deleteOperator(el)
    });
    this.notify.show(`${ops.length} operadores removidos com sucesso!`, 'success');
  }

  dismissModal(){
		this.modalService.dismissAll();
    this.operator = {}
    this.objAux = {};
	}

  checkAll() {
		if(this.operators.length){
			if (this.operators.every(val => val.checked == true))
				this.operators.forEach(val => { val.checked = false });
			else
				this.operators.forEach(val => { val.checked = true });
		}
	}

  boxSelected(){
    switch(this.selectBox){
      case 'select':
        this.checkAll()
        break
      case 'delete':
        this.deleteSelected()
        break
    }
    this.selectBox = ''
  }

  findDepName(operatorDep){
    let dep = this.departments.find(el=>el._id==operatorDep)
    if(dep) return dep.name
  }

  controlAttAccess(e,wd){
    if(e.checked){
      this.objAux.weekDays.push(wd)
    }else{
      this.objAux.weekDays = this.objAux.weekDays.filter(el=>el!=wd)
    }
    this.ref.detectChanges()
  }

  doSearch(){
    if(this.search.type=='keyword' && this.search.keyword){
      return this.operators.filter(el=>
        el.name.toLowerCase().includes(this.search.keyword.toLowerCase()) ||
        el.login.toLowerCase().includes(this.search.keyword.toLowerCase()) ||
        el.nickname.toLowerCase().includes(this.search.keyword.toLowerCase())
      )
    }else if(this.search.type=='status' && this.search.status){
      return this.operators.filter(el=>''+el.status+''==this.search.status)
    }else if(this.search.type=='dep'  && this.search.dep){
      return this.operators.filter(el=>el.department.find(d=>d==this.search.dep))
    }else{
      return this.operators
    }
  }

  includes(array,element){
    return array.includes(element)
  }

  setAddressByCEP(){
		cep(this.objAux.cep).then(value => {
			if (value.city) {
				this.objAux.city = value.city;
			}

			if (value.neighborhood) {
				this.objAux.neighborhood = value.neighborhood;
			}

			if (value.state) {
				this.objAux.state = value.state;
			}

			if (value.street) {
				this.objAux.street = value.street;
			}

			this.objAux.country = 'BR';

			if (!this.ref['destroyed']){
				this.ref.detectChanges();
			}
		}).catch(error => {
			console.log(error);
		})
	}

  ngOnDestroy() {
    this.socketService.off()
  }
}
