import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KtDialogService, LayoutConfigService, SubheaderService} from '../../../core/_base/layout';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../services/user.service';
import {ApiService} from '../../../services/api.service';
import {ApiJsService} from '../../../services/apiJS.service';
import {NotifyService} from '../../../services/notify.service';
import {Router} from '@angular/router';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {GenericValidatorService} from '../../../services/generic-validator.service';
import * as cep from 'cep-promise';
import {MapsAPILoader} from '@agm/core';
import insertTextAtCursor from 'insert-text-at-cursor';
import { SocketService } from '../../../services/socket.service';

@Component({
	selector: 'kt-channel',
	templateUrl: './channel.component.html',
	styleUrls: ['./channel.component.scss'],
	host: {
		'(document:mouseup)': 'onClick($event)'
	}
})
export class ChannelComponent implements OnInit {
	private showEmojiPicker = [];
	showEmojiPickerAnswer = [];
	userData: any = {};
	auto_message_enable: boolean;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	showCropper: any = false;
	channelConfig: any = {};

	dataMap: any = {zoom: 15, latitude: 0, longitude: 0};
	private geoCoder: google.maps.Geocoder;
	@ViewChild('emojiMart', {read: ElementRef, static: false}) emojiMart: ElementRef;
	@ViewChild('modalEditData', {static: false}) modalEditData: ElementRef;
	days: any = [
		{value: 0, text: 'Domingo'},
		{value: 1, text: 'Segunda-Feira'},
		{value: 2, text: 'Terça-Feira'},
		{value: 3, text: 'Quarta-Feira'},
		{value: 4, text: 'Quinta-Feira'},
		{value: 5, text: 'Sexta-Feira'},
		{value: 6, text: 'Sábado'},
	];

	operationalChanged = false;

	departments: any = []
	operators: any = []

	subChannels: any = []
	channelSaved: any = {}

	constructor(private layoutConfigService: LayoutConfigService,
				private modalService: NgbModal,
				public user: UserService,
				public ktDialogService: KtDialogService,
				public api: ApiService,
				public apiJS: ApiJsService,
				public notify: NotifyService,
				public ref: ChangeDetectorRef,
				public router: Router,
				public genericValidatorService: GenericValidatorService,
				private mapsAPILoader: MapsAPILoader,
				public subHeader: SubheaderService,
				private socketService: SocketService,) {
		this.subHeader.setTitle('Canal');

		this.getDepartments()
    	this.socketService.onSetDepartments().subscribe(data => {
			this.departments = data
		});

		this.getOperators()
    	this.socketService.onSetOperators().subscribe(data => {
			this.operators = data
		});

		this.userData = JSON.parse(JSON.stringify(user.getUser()));

		this.dataMap.latitude = parseFloat(this.userData.latitude);
		this.dataMap.longitude = parseFloat(this.userData.longitude);
		this.getConfig()
		this.socketService.onSecConfig().subscribe(data => {
			console.log('secConfig',data)
			this.subChannels = data
		});
		this.getSecConfig()
		// this.initMap();
	}

	ngOnInit() {
		this.channelSaved = Object.assign({}, this.userData);
	}

	onAddDay() {
		this.userData.horarios.push({
			day: 0,
			from: '',
			to: ''
		});
	}
	parseIntt(str) {
		return parseInt(str)
	}
	toggleAutoMessage(e) {
		let test = e.target.checked==true?1:0;
		this.userData.auto_message_enable = e.target.checked==true?1:0;
		console.log(e,this.userData.auto_message_enable,test)
		console.log(e.target.checked,e.target.value)
	}
	toggleEmojiPicker(event = null, elementId) {
		if (!this.showEmojiPicker[elementId]) {
			const that = this;
			setTimeout(() => {
				that.showEmojiPicker[elementId] = !that.showEmojiPicker[elementId];
				that.ref.markForCheck();
			}, 50);

		}

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		return false;
	}

	toggleEmojiPickerAnswer(event = null, object) {
		if (!object.showEmojiPicker) {
			const that = this;
			setTimeout(() => {
				object.showEmojiPicker = !object.showEmojiPicker;
				if (object.showEmojiPicker) {
					this.showEmojiPickerAnswer.push(object);
				}
				that.ref.markForCheck();
			}, 50);

		}

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		return false;
	}

	getEmojiI18n() {
		return {
			search: 'Busca',
			emojilist: 'Lista de emoji',
			notfound: 'Nenhum Emoji Encontrado',
			clear: 'Limpar',
			categories: {
				search: 'Resultados da Busca',
				recent: 'Utilizado Frequentemente',
				people: 'Rostos & Pessoas',
				nature: 'Animais & Natureza',
				foods: 'Comida & Bebida',
				activity: 'Atividade',
				places: 'Viajem & Lugares',
				objects: 'Objetos',
				symbols: 'Simbolos',
				flags: 'Bandeiras',
				custom: 'Personalizado'
			},
			skintones: {
				1: 'Tom de Pele Padrão',
				2: 'Tom de Pele Claro',
				3: 'Tom de Pele Claro-Médio',
				4: 'Tom de Pele Médio',
				5: 'Tom de Pele Médio-Escuro',
				6: 'Tom de Pele Escuro'
			}
		};
	}

	onEmoji($event: any, elementId) {
		insertTextAtCursor(document.getElementById(elementId), $event.emoji.native);
		this.toggleEmojiPicker($event, elementId);
	}

	onClick($event: any) {
		
		this.showEmojiPicker = [];
		

		for (const answer of this.showEmojiPickerAnswer) {
			answer.showEmojiPicker = false;
			delete answer.showEmojiPicker;
		}
		this.showEmojiPickerAnswer = [];
	}

	onRemoveDay(item) {
		this.userData.horarios = this.userData.horarios.filter(e => e !== item);
	}

	async onSave() {
		this.ktDialogService.show();
		if(this.user.getUser().instance_id==486) {
			if(this.channelConfig.waitTime>4320 || this.channelConfig.inactiveTime>4320) {
				this.ktDialogService.hide();
				return this.notify.show('O valor máximo do temporizador foi excedido! (4320 minutos)', 'danger');
			}
		} else {
			if(this.channelConfig.waitTime>1440 || this.channelConfig.inactiveTime>1440) {
				this.ktDialogService.hide();
				return this.notify.show('O valor máximo do temporizador foi excedido! (1440 minutos)', 'danger');
			}
		}
		if(this.channelConfig.operationalChannelEnabled && this.channelConfig.operationalChannelEnabled!=0) {
			this.userData.operationalChannelEnabled = 1
		} else {
			this.userData.operationalChannelEnabled = 0
		}

		await this.api.channel_save(this.userData).then(async (value) => {
			if (value.success) {
				let resp = await this.apiJS.updateChannelConfigMongo(this.channelConfig)
				let mongoChannel = await this.apiJS.updateChannelMongo(this.userData)
				this.api.update_channel_config(this.channelConfig)
				if(this.userData.whatsapp_name != this.channelSaved.whatsapp_name) {
					this.socketService.updateNameChannel({ 
						channelId: this.user.getUser().instance_id, 
						displayName: this.userData.whatsapp_name
					});
				}
				if(this.userData.whatsapp_status != this.channelSaved.whatsapp_status) {
					this.socketService.updateStatusChannel({
						channelId: this.user.getUser().instance_id, 
						status: this.userData.whatsapp_status
					});
				}
				this.channelSaved = Object.assign({}, this.userData);
				this.subChannels.forEach(async (el)=>{
					let resp = await this.apiJS.updateChannelConfigMongo(el)
					let mongoChannel = await this.apiJS.updateChannelMongo(this.userData)
					this.api.update_channel_config(el)
				})
				if(this.operationalChanged) {
					if(this.userData.operationalChannelEnabled==0) {
						this.disableOperational()
					}
				}
				this.notify.show(value.message, 'success');
				// this.user.setUser(value.channel);
				// this.userData = JSON.parse(JSON.stringify(this.user.getUser()));

				this.modalService.dismissAll();
				this.showCropper = false;
			} else {
				this.notify.show(value.message, 'danger');
			}
		});
		this.ktDialogService.hide();
	}

	onCancel() {
		this.userData = JSON.parse(JSON.stringify(this.user.getUser()));
	}

	onZipcodeUnknown() {
		window.open('http://www.buscacep.correios.com.br/sistemas/buscacep/', '_blank');
	}

	onEditData() {
		this.modalService.open(this.modalEditData);
	}

	onEditTime() {
		// todo
	}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		// show cropper
		this.showCropper = true;
	}

	cropperReady() {
		// cropper ready
	}

	loadImageFailed() {
		// show message
	}

	saveAvatarCrop() {
		this.userData.whatsapp_avatar = this.croppedImage;
		this.userData.whatsapp_avatar_updated = true;

		this.showCropper = false;
	}

	cancelAvatarCrop() {
		this.userData.whatsapp_avatar = JSON.parse(JSON.stringify(this.user.getUser())).whatsapp_avatar;
		this.userData.whatsapp_avatar_updated = null;

		this.showCropper = false;
	}

	async onCep() {
		if (this.userData.zipcode) {
			await this.ktDialogService.show();
			cep(this.userData.zipcode).then(value => {
				if (value.city) {
					this.userData.city = value.city;
				}

				if (value.neighborhood) {
					this.userData.neighborhood = value.neighborhood;
				}

				if (value.state) {
					this.userData.state = value.state;
				}

				if (value.street) {
					this.userData.street = value.street;
				}

				this.userData.country = 'BR';

				if (!this.ref['destroyed']) {
					this.ref.detectChanges();
				}
			}).catch(error => {
				console.log(error);
			}).finally(() => {
				this.ktDialogService.hide();
			});
		}
	}

		initMap() {
		this.mapsAPILoader.load().then(async () => {
			// this.geoCoder = new google.maps.Geocoder();
			// this.setMapCurrentLocation();

			// let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
			// 	types: ['address']
			// });
			// autocomplete.addListener('place_changed', () => {
			// 	this.ngZone.run(() => {
			// 		let place: google.maps.places.PlaceResult = autocomplete.getPlace();
			// 		if (place.geometry === undefined || place.geometry === null) {
			// 			return;
			// 		}
			//
			// 		this.dataMap.latitude = place.geometry.location.lat();
			// 		this.dataMap.longitude = place.geometry.location.lng();
			// 		this.dataMap.zoom = 12;
			// 	});
			// });
		});
	}

	setMapCurrentLocation() {
		return new Promise((resolve, reject) => {
			if (this.refreshMapLocation()) {
				return resolve(true);
			}

			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition((position) => {
					this.dataMap.latitude = position.coords.latitude;
					this.dataMap.longitude = position.coords.longitude;
					this.dataMap.zoom = 15;
					resolve(true);
				}, positionError => {
					reject(positionError);
				});
			} else {
				reject(false);
			}
		});
	}

	refreshMapLocation() {
		if (this.userData.street && this.userData.number && this.userData.city && this.userData.zipcode) {
			// this.geoCoder.geocode({
			// 	address: `${this.userData.street}, ${this.userData.number}, ${this.userData.city}, ${cep}, ${this.userData.neighborhood}`
			// }, (results, status) => {
			// 	console.log(results,status)
			// 	if (status === 'OK') {
			// 		this.dataMap.latitude = results[0].geometry.location.lat();
			// 		this.dataMap.longitude = results[0].geometry.location.lng();

			// 		this.userData.latitude = this.dataMap.latitude;
			// 		this.userData.longitude = this.dataMap.longitude;

			// 		this.dataMap.zoom = 15;

			// 		this.ref.detectChanges();

			// 		// window.gmap.setCenter(results[0].geometry.location);
			// 		// var marker = new google.maps.Marker({position: results[0].geometry.location, map: map});
			// 	}
			// });
			return true;
		}

		return false;
	}

	async getConfig() {
		let resp = await this.apiJS.getChannelConfig(this.user.getUser().instance_id);
		this.channelConfig = resp.channel
		if(!this.channelConfig.operationalChannel) {
			this.channelConfig.operationalChannel = {
				action: 'redirectDepartment'
			}
		}
		if(!this.channelConfig.forwardAttInactivityEnabled) {
			this.channelConfig.forwardAttInactivityEnabled = {
				action: ''
			}
		}
	}

	getDepartments() {
		this.socketService.getDepartments(this.user.getUser().instance_id);
	}  

	getOperators() {
		this.socketService.getOperators(this.user.getUser().instance_id);
	}  

	getSecConfig() {
		this.socketService.getSecConfig(this.user.getUser().instance_id);
	}  

	disableOperational(channel=null) {
		if(!channel) {
			channel= this.userData
		}
		this.socketService.disableOperational(channel.id)
	}

	changeOperational() {
		this.operationalChanged = true
	}

	ngOnDestroy() {
		this.socketService.off()
	}
}
