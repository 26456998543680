// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Partials
import {PartialsModule} from '../partials/partials.module';
// Pages
import {CoreModule} from '../../core/core.module';
import {MailModule} from './apps/mail/mail.module';
import {ECommerceModule} from './apps/e-commerce/e-commerce.module';
import {UserManagementModule} from './user-management/user-management.module';
import {MyPageComponent} from './my-page/my-page.component';
import {DepartmentsComponent} from './departments/departments.component';
import {TabulationsComponent} from './tabulations/tabulations.component';
import {OperatorsComponent} from './operators/operators.component';
import {AttendancesComponent} from './attendances/attendances.component';
import {AttConfigComponent} from './attConfig/attConfig.component';
import {NotifReportsComponent} from './notifReports/notifReports.component';
import {TabulationReportsComponent} from './tabulationReports/tabulationReports.component';
import {ApiReportsComponent} from './apiReports/apiReports.component';
import {ContactsComponent} from './contacts/contacts.component';
import {ContactComponent} from './contact/contact.component';
import {
    MatAutocompleteModule,
    MatButtonModule, MatCardModule,
    MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatIconModule,
    MatInputModule,
    MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
    MatSelectModule, MatSnackBarModule, MatSortModule,
    MatTableModule, MatTabsModule, MatTooltipModule,MatTreeModule,
} from '@angular/material';
import {MatChipsModule} from '@angular/material/chips';
import {TooltipModule} from 'ng2-tooltip-directive';
import {ChatBotComponent} from './chat-bot/chat-bot.component';
import {ChatbotAttComponent} from './chatbot-att/chatbot-att.component';
import {RouterModule} from '@angular/router';
import {CustomMenuComponent} from './custom-menu/custom-menu.component';
import {CustomMenuAttComponent} from './customMenu-att/customMenu-att.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ChannelComponent} from './channel/channel.component';
import {NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../shared/shared.module';
import { NotificacoesComponent } from './notificacoes/notificacoes.component';
import {PainelPrincipalComponent} from './painel-principal/painel-principal.component';
import {PaginationModule} from './pagination/pagination.module';
import {EditarNotificacaoComponent} from './editar-notificacao/editar-notificacao.component';
import {EditNotificationsTagsComponent} from './editar-notificacao/editNotificationsTags.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import {DataTablesModule} from 'angular-datatables';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { SurveysComponent } from './surveys/surveys.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyReportsComponent } from './survey-reports/survey-reports.component';
import { SurveyReportComponent } from './survey-report/survey-report.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import { GroupsComponent } from './groups/groups.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {UsersComponent} from './users/users.component';
import {UserComponent} from './user/user.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

@NgModule({
	declarations: [MyPageComponent,DepartmentsComponent,TabulationsComponent,OperatorsComponent,AttendancesComponent,AttConfigComponent, NotifReportsComponent, TabulationReportsComponent, ApiReportsComponent, ContactsComponent, ContactComponent, ChatBotComponent,ChatbotAttComponent, ChannelComponent, CustomMenuComponent, CustomMenuAttComponent, NotificacoesComponent, PainelPrincipalComponent, EditarNotificacaoComponent, EditNotificationsTagsComponent, DashboardMainComponent, SurveysComponent, SurveyComponent, SurveyReportsComponent, SurveyReportComponent, GroupsComponent, UsersComponent, UserComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		MatCheckboxModule,
		TooltipModule,
		RouterModule,
		PickerModule,
		DragDropModule,
		NgbDropdownModule,
		SharedModule,
		MatTreeModule,
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatChipsModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatFormFieldModule,
		PaginationModule,
		DataTablesModule,
		NgbTooltipModule,
		PerfectScrollbarModule,
		MatExpansionModule,
		NgApexchartsModule,
		ScrollingModule,
		NgxMaskModule.forChild(),
	],
	providers: []
})
export class PagesModule {
}
